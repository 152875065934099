import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreditlyServicesService } from './creditly-services.service';
import { AuthService } from './shared/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'creditly';
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  @ViewChild('childModal', { static: false }) childModal: ModalDirective;
  randomNumber = Math.floor(Math.random() * 20000000 + 1);
  sessionResp: any;
  public modalRef: BsModalRef;
  req = {
    uUID: this.randomNumber.toString(),
    platformType: 1,
    systemInfo: "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.101 Safari/537.36"
  }
  constructor(private creditlyServices: CreditlyServicesService,private authService: AuthService, private spinner: NgxSpinnerService, private translate: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private idle: Idle, private keepalive: Keepalive,
      private router: Router) {
    this.creditlyServices.generateSessionToken(this.req).subscribe((resp) => {
      this.sessionResp = resp;

      if (this.sessionResp != undefined && this.sessionResp != null) {

        localStorage.setItem("token", this.sessionResp?.token);
        this.creditlyServices.master(this.sessionResp?.token).subscribe(val => {
          localStorage.setItem("masterData", JSON.stringify(val));
        })
      }
    })
    translate.onLangChange.subscribe((res)=>{
         console.log(res)
         if(res.lang =='ar'){
          this.renderer.setAttribute(this.document.body, 'dir','rtl');
          // this.renderer.setStyle(this.document.body, 'direction','rtl');

          //  document.getElementsByTagName('body').style.direction =
         }else{
          this.renderer.setAttribute(this.document.body, 'dir','ltr');

         }
    })

    // new approach
   
      idle.setIdle(240);
      idle.setTimeout(60);
      idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

      idle.onIdleEnd.subscribe(() => {
        this.idleState = 'No longer idle.'
        console.log(this.idleState);
        this.reset();
      });

      idle.onTimeout.subscribe(() => {
        this.childModal.hide();
        this.idleState = 'Timed out!';
        this.timedOut = true;
        console.log(this.idleState);
        // this.router.navigate(['/']);
        this.logout();
      });

      idle.onIdleStart.subscribe(() => {
          this.idleState = 'You\'ve gone idle!'
          console.log(this.idleState);
          this.childModal.show();
      });

      idle.onTimeoutWarning.subscribe((countdown) => {
        // this.idleState = 'You will time out in ' + countdown + ' seconds!'
        this.idleState = 'You will time out in ' + (countdown < 60 ? (countdown + " seconds!"): ((Math.trunc(countdown/60)) + ' minutes ' + (countdown%60 == 0 ? " " : (countdown%60 + " seconds!") )))
        console.log(this.idleState);
      });

      keepalive.interval(0.01);

keepalive.onPing.subscribe(() => this.lastPing = new Date());
     
      if(localStorage.getItem('sessionToken') && localStorage.getItem('loginResponse')){
       
      idle.watch();
        this.timedOut = false;
      }else{
       
        idle.stop();
      }
  }

  reset() {
    this.idle.watch();
    this.timedOut = false;
  }

  hideChildModal(): void {   
    this.childModal.hide();
  }

  stay() {
    this.childModal.hide();
    this.reset();
  }

  logout() {
    this.authService.logOut().subscribe((res)=>{
      localStorage.removeItem('sessionToken');
      localStorage.removeItem('loginResponse');
      this.router.navigate(['/creditly/LandingPage']);
      window.location.reload();
    });
  }
  
  @HostListener('window:unload', ['$event'])
  unloadHandler(event) {
     console.log("window unload event")
  }
  
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander(event) {
    console.log("window beforeunload event")
  }
  
 ngOnInit(): void {
 
  
 }
}
