import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { take } from 'rxjs/operators';
import { CreditlyServicesService } from 'src/app/creditly-services.service';

@Component({
  selector: 'app-auto-lease-card-shared',
  templateUrl: './auto-lease-card-shared.component.html',
  styleUrls: ['./auto-lease-card-shared.component.scss','../../../features/products/auto-lease/auto-lease/auto-lease-finance/auto-lease-finance.component.scss']
})
export class AutoLeaseCardSharedComponent implements OnInit {

  @Input() autoLeaseData :{
    item,
    type,
    comparedList?,
    catId,
    catName,
    formData?
  }


  item
  autoLoansCompareList = [];
  userData = JSON.parse(localStorage.getItem('loginResponse'));
  showCardDetailsFlag = false;
  term: boolean = false;
  product
  productUrl
  financeProduct
  autoLoanItem
  noDataFlag = false;
  selectedVehicleID
  catId: any;
  catName: any;
  formData;
  curretLang;
  banksList =JSON.parse(localStorage.getItem('masterData')).banks
  VichleForm: any;
  constructor(
    private creditlyServeices: CreditlyServicesService,
    private router: Router, private ngNavigatorShareService: NgNavigatorShareService,
    private translate: TranslateService
    ) {

   }
   enableShare =false
  ngOnInit(): void {
    console.log(this.autoLeaseData);

    this.curretLang = localStorage.getItem("currentLang")
   this.catId = this.autoLeaseData.catId;
   this.catName = this.autoLeaseData.catName;
   this.formData = this.autoLeaseData.formData
// console.log(this.autoLeaseData.catId);

if (this.productUrl == null || this.productUrl == undefined) {
  this.product = sessionStorage.getItem("CategoryID") == "1" ? [JSON.parse(sessionStorage.getItem("selectedCard"))] : [];
  this.financeProduct = sessionStorage.getItem("CategoryID") == "2" ? [JSON.parse(sessionStorage.getItem("selectedCard"))] : [];
  this.autoLoanItem = sessionStorage.getItem("CategoryID") == "3" ? [JSON.parse(sessionStorage.getItem("selectedCard"))] : [];
  this.selectedVehicleID = localStorage.getItem('selectedVehicleId');
} else {
  this.noDataFlag = false;
  this.getProductDetails();
}


        // return an empty string if no matches are found


    if(this.autoLeaseData){
      // console.log(this.autoLeaseData.item );
      this.item = this.autoLeaseData.item;

      this.autoLoansCompareList = this.autoLeaseData.comparedList
    } else {
      if (localStorage.getItem("compareBy") == this.catName ) {
        this.autoLoansCompareList = localStorage.getItem("compairCards") ? JSON.parse(localStorage.getItem("compairCards")) : [];
      }
    }
    this.curretLang = localStorage.getItem('currentLang');

    this.translate.onLangChange.subscribe(lang=>{
      this.curretLang = lang.lang;
    })
  }
    public getProductDetails(): void {
    let req = {
      URL: this.productUrl
    }
  }
  checkError() {
    let message = [];
    for (const [key, value] of Object.entries(this.creditlyServeices.validObject)) {
      let val: any = value;
      console.log(`${key}: ${JSON.stringify(value)}`);
      if (val.valid == false) {
        message.push(key);
      }
    }
    if (message.length == 0)
      return true;
    else {
      this.creditlyServeices.notify("please fill requried fields in " + message.join(",") + " details", "error")
      return true;
    }
  }
  public applyForAutoLoan(autoLoanItem): void {
    if(!autoLoanItem?.isEligible){
      return
    }
    this.creditlyServeices.emiForm.emit()
    this.creditlyServeices.getForm.subscribe(res=>{
      console.log(res);
      this.VichleForm = res

    })
    console.log("autoLoanItem", autoLoanItem);
  console.log(this.autoLeaseData.type)
    if (this.userData) {
      if(this.autoLeaseData.type == 'cards list'){
        sessionStorage.setItem("CategoryID", this.catId );
        sessionStorage.setItem("selectedCard", JSON.stringify(autoLoanItem));
        // localStorage.removeItem('selectedVehicleId');
        // sessionStorage.setItem("selectedCard", JSON.stringify(data));
        // sessionStorage.setItem("CategoryID", "2");
        if(this.catId == "2"){

          localStorage.setItem("applyLoanData", JSON.stringify({ duration: this.formData.Period * 12, mangementFee: this.FilterValue(1, autoLoanItem?.fees) }));
        }
        // this.router.navigateByUrl('creditly/applyProduct/'+autoLoanItem.id+'?For='+this.catName);

      this.router.navigate(['creditly/applyProduct/'+autoLoanItem.id],{queryParams:{...this.autoLeaseData.formData,...this.VichleForm,For:this.catName,...{title:autoLoanItem.seo.url,prodType:this.catId}}});

      } else {

          this.creditlyServeices.change(true);
          if (this.checkError()) {
            this.term = false;
            for (const [key, value] of Object.entries(this.creditlyServeices.validObject)) {
              let val: any = value;
              console.log(`${key}: ${JSON.stringify(value)}`);
              if (key == "bank" && val.change === true) {
                this.creditlyServeices.bank.next(true);
              }
              if (key == "contact" && val.change === true) {
                this.creditlyServeices.contact.next(true);
              }
              if (key == "employment" && val.change === true) {
                this.creditlyServeices.employement.next(true);
              }
              if (key == "financial" && val.change === true) {
                this.creditlyServeices.financial.next(true);
              }
            }
            if (this.product[0]?.isSendOTP == true || this.financeProduct[0]?.isSendOTP == true || this.autoLoanItem[0]?.isSendOTP == true) {
              let type;
              if (sessionStorage.getItem("CategoryID") == this.catId) {
                type = this.autoLoanItem[0].name;
                // type = sessionStorage.getItem("CategoryID") == "2" ? this.financeProduct[0].name : this.product[0].name
              }
              this.creditlyServeices.getVerificationOtp(type).subscribe(res => {
                this.term = true;
                this.router.navigateByUrl('/creditly/cc-otp');
              }, err => {
                this.term = true;
              })
            } else {
              if (sessionStorage.getItem("CategoryID") == "3") {

                this.creditlyServeices.applyAutoLoan().subscribe((applyAutoLoanResp) => {
                  this.term = true
                  console.log("applyAutoLoan resp", applyAutoLoanResp);
                  localStorage.setItem('requestedCardData', JSON.stringify(applyAutoLoanResp));
                  localStorage.removeItem('selectedCard');
                  this.router.navigateByUrl('/creditly/cc-success');

                })
              } else {
                this.creditlyServeices.applyCard().subscribe(val => {
                  this.term = true;
                  localStorage.setItem('requestedCardData', JSON.stringify(val));
                  localStorage.removeItem('selectedCard');
                  this.router.navigateByUrl('/creditly/cc-success');
                }, err => {
                  this.term = true;
                })
              }
            }

          }


      }
    } else {
      this.router.navigateByUrl('/login');
    }
  }

  public showCardDetails() {
    this.showCardDetailsFlag = !this.showCardDetailsFlag;
    // this.product[i]['details']=true;
  }
  FilterValue(id, array) {
    return array?.fees?.filter((val) => val.name == "Management Fees")[0]?.value
      ? array?.fees?.filter((val) => val.name == "Management Fees")[0]?.value
      : 0;
  }

  filterTitle(array,id){
   let nameBank = array.find(res=>res.id == id);
   return {name:nameBank.name,name_ar:nameBank.name_ar}
  }
  // public isThereProduct(product): void {
  //   let isThereProductFlag;
  //   if (this.autoLoansCompareList.filter((item) => item.id == product.id).length != 0) {
  //     isThereProductFlag = true;
  //   } else {
  //     isThereProductFlag = false;
  //   }
  //   return isThereProductFlag;
  // }

  shareURL(item) {
    /*  // let baseUrl = this.creditlyServices.shareProductUrl;
     let baseUrl = "http://beta.creditly.sa/#/"
     // let baseUrl = "http://localhost:2040/#/"
     let componentUrl = "creditly/shareProductView"
     let totUrl = `${baseUrl}${componentUrl}/?title=${item.seo.url}&prodType=1`
     console.log("totUrl", totUrl);
     const sss = this.router.createUrlTree([], { queryParams: { title: item.seo.url, prodType: '1' } });
     console.log("sss", this.serializer.serialize(sss));
      */
     this.enableShare = !this.enableShare
     return

    sessionStorage.setItem("CategoryID", '3');
    if (!this.ngNavigatorShareService.canShare()) {
      this.creditlyServeices.notify('This service/api is not supported in your Browser', 'error');
      return
    }

    let baseUrl = this.creditlyServeices.shareProductUrl;
    let componentUrl = "creditly/applycard"
    let totUrl = `${baseUrl}${componentUrl}/?title=${item.seo.url}&prodType=3`
    console.log("totUrl", totUrl);

    this.ngNavigatorShareService.share({
      title: 'My Awesome app',
      text: 'hey check out my Share button',
      url: totUrl
    }).then((response) => {
      console.log('share response..', response);
      // this.creditlyServices.notify(`${response}`, 'info');
    }).catch((error) => {
      console.log('share error response..', error);
      // this.creditlyServices.notify(`${error}`, 'error');
    })
  }
  public isThereProduct(product) {
    let isThereProductFlag;
    if (this.autoLoansCompareList && this.autoLoansCompareList?.filter((item) => item.id == product.id).length != 0) {
      isThereProductFlag = true;
    } else {
      isThereProductFlag = false;
    }
    return isThereProductFlag;
  }

  public addToCompare(product): void {
    // if (this.autoLoansCompareList.length != 5) {

    if (this.autoLoansCompareList?.filter((item) => item.id == product.id).length == 0 ) {
      if (this.autoLoansCompareList.length != 5) {
        localStorage.setItem("compareBy", "AutoLoan");
        this.autoLoansCompareList.push(product);
      } else {
        alert("maximum limit is 5 to compare product...!")
      }
    } else {
      const index = this.autoLoansCompareList?.indexOf(product);
      if (index > -1) {
        this.autoLoansCompareList.splice(index, 1);
      }
      console.log(this.autoLoansCompareList);
    }
    // } else {
    //   alert("maximum limit is 5 to compare product...!")
    // }
  }

  public removeProduct(product): void {
    const index = this.autoLoansCompareList.indexOf(product);
    if (index > -1) {
      this.autoLoansCompareList.splice(index, 1);
    }
    console.log(this.autoLoansCompareList);
  }

  public resetList(): void {
    this.autoLoansCompareList = [];
  }

  public navigateToComparePage(): void {
    if (this.autoLoansCompareList.length > 1) {
      localStorage.setItem("compairCards", JSON.stringify(this.autoLoansCompareList))
      sessionStorage.setItem("CategoryID", "3");
      this.router.navigateByUrl('creditly/cardsCompare');
    } else {
      alert("Add two or more products to Compare...!")
    }
  }

}

export const CARD_OPTIONS = [
  {
    background: '#DCFFF5',
    img:'Icon (Stroke).png',
    title:'Cash back'
  },
  {
    background: '#FFECCF',
    img:'Vector.png',
    title:'lounge access'
  },  {
    background: '#DCFFF5',
    img:'credit-cards/Icon (Stroke).png'
  },
  {
    background: '#DCFFF5',
    img:'credit-cards/Icon (Stroke).png'
  }
]
