import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CreditlyServicesService } from 'src/app/creditly-services.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  showEnglishLogo: boolean = true;
  applyStickyStyles: boolean = false;
  whiteStyle: boolean = true;
  formGroup: FormGroup;
  profileDate = JSON.parse(localStorage.getItem('loginResponse'));
  @Input() public loggedId: boolean = false
  @Input() public isTransformed: boolean = true

  currentLang
  sessionResp: any;
  loginFormValidationFlag: boolean = false;
  submit: boolean = false;
  showForgotPwdFlag: boolean = false;
  innerForgotPwdFlag: boolean = false;
  forgotIqmaId: any;
  validIqamaForgotFlag: boolean;
  updatePasswordForm: FormGroup;
  updatePwdFormFlag: boolean = false;
  sendOTPFlag: boolean = false;
  updatePwdSubmitFlag: boolean = false;
  OTPExpireTime: number = 120;
  interval;
  otpForm: FormGroup;

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {


    const navbar = document.getElementById("myHeader");
    if (!navbar) {
      return
    }

    const sticky = navbar.offsetTop;
    if (window.pageYOffset > 10) {
      this.applyStickyStyles = true;
      this.whiteStyle = false;
    } else {
      this.applyStickyStyles = false;
      this.whiteStyle = true;
    }
  }

  constructor(public service: CreditlyServicesService, private router: Router,
    private fb: FormBuilder,private translate: TranslateService,private auth: AuthService) {
      this.currentLang = localStorage.getItem('currentLang') ? localStorage.getItem('currentLang'):'en'
      translate.setDefaultLang(this.currentLang);
      translate.use(this.currentLang)
      this.changeLanguge(this.currentLang)
      // localStorage.setItem('currentLang',translate.currentLang)
      // console.log( this.currentLang)
      // console.log( translate.instant('My Calendar'))
      // translate.get('My Calendar').subscribe(res=>{
      //   console.log(res)
      // })

    }
    toggleDropdown = false
    togllemenu(){
    this.toggleDropdown = !this.toggleDropdown

    }
  ngOnInit(): void {
    console.log("profile data", this.profileDate)
    this.formGroup = new FormGroup({
      Mobile: new FormControl('', [Validators.required, Validators.pattern(/^\d{10}$/)]),
      password: new FormControl('', [Validators.required])
    });
    this.otpForm =  new FormGroup({
      OTP: new FormControl('', [Validators.required]),

    });
  }

  public selectLanguage(type): void {

    if (type == "AR") {
      this.showEnglishLogo = true;
    } else {
      this.showEnglishLogo = false;
    }
  }
  public changeLanguge(lang){
      // this.translate.use('en')
      console.log(lang)
      this.translate.use(lang);
      localStorage.setItem('currentLang',lang);
      this.currentLang = lang;

      if (lang == "ar") {
        this.showEnglishLogo = false;
      } else {
        this.showEnglishLogo = true;
      }

  }
  navigate(section) {
    this.router.navigate(['/creditly','userProfile'],{ queryParams: { section :section } });
    
  }
  logout() {
     localStorage.removeItem('sessionToken');
     localStorage.removeItem('loginResponse');
    this.auth.logOut().subscribe((res)=>{
      this.router.navigate(['/creditly/LandingPage']);
    window.location.reload();
    });
  }

  loginProcess() {
    this.loginFormValidationFlag = this.formGroup.invalid ? true : false;
    if (this.formGroup.controls["Mobile"].invalid)
      this.service.notify("Please enter valid mobile number", "error");
    if (!this.loginFormValidationFlag) {
      this.submit = true;
      // var randomNumber = Math.floor(Math.random() * 20000000 + 1);
      // let req = {
      //   uUID: randomNumber.toString(),
      //   platformType: 1,
      //   systemInfo: "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.101 Safari/537.36"
      // }
      this.auth.loginGetOtp(this.formGroup.value).subscribe(res=>{
        if(res.status == true){
          this.sendOTPFlag = true;
          this.submit = false;
          this.service.notify(res.message, "success");
          this.startTimer()

        }else {
                this.submit = false;
                this.service.notify(res.message, "error");
              }
      }, err => {
          // this.submit = false;
          // this.sendOTPFlag = false;
          this.service.notify("Oops! something went wrong...", "error");

        })
      // this.service.login(this.formGroup.value, localStorage.getItem("token")).subscribe(result => {
      //   if (result != null && result != undefined) {
      //     this.sendOTPFlag = true;
      //     localStorage.setItem("loginResponse", JSON.stringify(result));
      //     if (result?.userSessions?.length) {
      //       localStorage.setItem("sessionToken", result?.userSessions[0]?.token);
      //       localStorage.setItem("token", result?.userSessions[0]?.token);
      //       this.service.to = "Profile";
      //       this.router.navigateByUrl('/userProfile');
      //     } else {
      //       this.submit = false;
      //       this.service.notify(result.message, "error");
      //     }
      //   }
      // }, err => {
      //   this.submit = false;
      //   this.sendOTPFlag = false;
      //   this.service.notify("Please enter Phone Number", 'error');
      // })
    }
  }

loginVerifytOtp(){
  if(this.otpForm.invalid){
    return this.service.notify('Please enter otp','error')

  }
  this.submit = true;

  this.auth.loginVerifytOtp(this.otpForm.value).subscribe(res=>{




      this.sendOTPFlag = true;
      this.submit = false;
     this.sendOTPFlag = true;
     this.service.notify(res.message, "success");
     this.toggleDropdown = false;

          localStorage.setItem("loginResponse", JSON.stringify(res));
          if (res?.userSessions?.length) {
            localStorage.setItem("sessionToken", res?.userSessions[0]?.token);
            localStorage.setItem("token", res?.userSessions[0]?.token);
            this.service.to = "Profile";
            if(res.stepsComplete !== 'EmploymentConfirmationCompleted'){
              this.service.registerCompleteStep.emit()

              this.router.navigateByUrl('/register');
              this
              return;

            }
            this.router.navigateByUrl('/creditly/userProfile');
          }

  }, err => {
      // this.submit = false;
      // this.sendOTPFlag = false;
      this.service.notify("Oops! something went wrong...", "error");

    })
}
timeLeft = 60
startTimer() {
  this.interval = setInterval(() => {
    if (this.timeLeft > 0) {
      this.timeLeft--;
    } else {
      this.pauseTimer();
    }
  }, 1000);
}

pauseTimer() {
  clearInterval(this.interval);
}
}


// sessionToken:c5fc5b05d6a2991daa564423e2fc3560
// token:e7ee01555ae4898a1f60179508720f3e
