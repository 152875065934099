import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'app-approved-requests',
    templateUrl: './approved-requests.component.html',
    styleUrls: ['./approved-requests.component.scss'],
  })
  export class ApprovedRequestsComponent implements OnInit {
      ngOnInit(): void {
          
      }
  }