<!-- <form *ngIf="formGroup?.controls" [formGroup]="formGroup"> -->
<div class="container">
    <div class="row contact-row">
        <div class="pro-main-label">
            <p>
                National Address and Contact Information
            </p>
        </div>
    </div>
    <ng-container *ngFor='let ro of nationalData'>

        <div class="row">
            <div class='col-md-3' *ngFor='let nam of ro let i=index;'>
                <div class="person-details">
                    <p class="title-label">
                        {{nam.name}}
                    </p>
                    <p class="title-value">
                        {{nam.value && nam.value !==''?nam.value:"NA"}}
                    </p>
                </div>
            </div>
        </div>
        <hr class="profile-line">
    </ng-container>
    <!-- <app-profile-form-shared *ngIf='formDatacontactData' [loginFormValidationFlag]='loginFormValidationFlag' (newItemEvent)='update()' [formComingData]='formDatacontactData'></app-profile-form-shared> -->
    <div class="row align-items-center">
        <!-- <app-profile-form-shared [loginFormValidationFlag]='loginFormValidationFlag' *ngIf='formDataPersonal' (newItemEvent)='update()' [formComingData]='formDataPersonal'></app-profile-form-shared> -->
        <div class="edit-sec">

            <form [formGroup]='formGroup'>

                <div class="from-group">
                    <label class="form-label-n">
                        Residential Status
                </label>
                    <div class="radio-contain d-flex align-items-center">

                        <div class="radio-group d-flex align-items-center" *ngFor="let re of resType">
                            <div class="radio-field" (click)='selectResType(re)' [ngClass]="{'checked':re.checked}">
                                <input formControlName='residenceTypeID' [id]='re.name' name="residenceTypeID" type="radio" [checked]='re.checked' (change)='selectResType(re)'>
                            </div>
                            <label class="m-0" (click)='selectResType(re)' [for]="re.name">{{re.name}}</label>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-6">

                        <div class="form-group-n w-100">
                            <label class="form-label-n d-block">
                                  Telephone Number
                           </label>
                            <input class="text-form-input w-100" formControlName='LandLine'>

                        </div>

                        <div class="form-group-n w-100">
                            <label class="form-label-n d-block">
                                Email
                         </label>
                            <input class="text-form-input w-100" formControlName='email'>

                        </div>
                    </div>

                </div>
                <hr class="profile-line">
                <div class="row contact-row">
                    <div class="pro-main-label">
                        <p>
                            National Address and Contact Information
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class='col-md-3'>
                        <div class="person-details">
                            <p class="title-label">
                                Country Name
                            </p>
                            <p class="title-value">
                                <!-- {{nam.value && nam.value !==''?nam.value:"NA"}} -->
                                {{homeCountryAddress?.cityName?homeCountryAddress?.cityName: 'NA'}}
                            </p>
                        </div>
                    </div>
                </div>


                <div class="row" *ngIf="!isSaudiUser">
                    <div class="col-md-6" *ngFor='let fiel of homeContry'>

                        <div class="form-group-n w-100">
                            <label class="form-label-n d-block">
              {{fiel.labelName}}
       </label>
                            <input [formControlName]='fiel.formControl' class="text-form-input w-100">

                        </div>

                    </div>

                </div>

                <hr class="profile-line">
                <div class="row contact-row">
                    <div class="pro-main-label">
                        <p>
                            Relative Contact Information
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6" *ngFor='let fiel of relativeContactsentData'>

                        <div class="form-group-n w-100">
                            <label class="form-label-n d-block">
            {{fiel.labelName}}
     </label>
                            <input *ngIf='!fiel.selectMenu' [formControlName]='fiel.formControl' class="text-form-input w-100">
                            <select *ngIf='fiel.selectMenu' class="select-form-input" [formControlName]='fiel.formControl'>
                                  <option value="">Select the type</option>

                                  <option *ngFor='let item of fiel.menuData' [value]="item">{{item}}</option>
                                </select>

                        </div>

                    </div>

                </div>

            </form>
        </div>







    </div>


</div>
<!-- </form> -->