import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bank-list-shared',
  templateUrl: './bank-list-shared.component.html',
  styleUrls: ['./bank-list-shared.component.scss']
})
export class BankListSharedComponent implements OnInit {
  banks: any;
  banksList: any;

  constructor() { }

  ngOnInit(): void {
    let local_data = JSON.parse(localStorage.getItem("masterData"));
    this.banks = local_data?.banks;
    if (this.banks) {
      this.banksList = this.banks.filter(item => item.isProductAvail);
    }
    console.log( this.banksList)
  }

}
