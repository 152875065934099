import { ChangeDetectorRef, Component, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CreditlyServicesService } from 'src/app/creditly-services.service';
import { ProfileService } from 'src/app/shared/services/profile.service';

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss'],
})
export class PersonalDetailsComponent implements OnInit {
  profileDate = JSON.parse(localStorage.getItem('loginResponse'));
  unsubscribeAll = new Subject()
  personalForm: FormGroup;
  loginFormValidationFlag = false
  formDataPersonal: { formData: ({ formControl: string; isEditable: boolean; visible: boolean; labelName: string; selectMenu: boolean; menuData?: undefined; } | { formControl: string; isEditable: boolean; visible: boolean; labelName: string; selectMenu: boolean; menuData: any; })[]; formGroup: FormGroup; };
  statusDetails: { name: string; value: any; }[];
  birthDetails: { name: string; value: any; }[];
  expireDetails: { name: string; value: any; }[];
  constructor(private renderer: Renderer2,private cdr:ChangeDetectorRef,private creditlyServices: CreditlyServicesService,private profile:ProfileService) {}
  enableEdit
  // titleName = new FormControl(this.profileDate?.title?this.profileDate?.title:'Mr');
  educationsDegrees =JSON.parse(localStorage.getItem('masterData')).educationalLevels;
  titles = [
    {title:"Mr",checked:false},
    {title:"Ms",checked:false},
    {title:"Mrs",checked:false},
    {title:"Dr",checked:false},
    {title:"HRH",checked:false}
  ]
  nameDetails
  // educationLevel = new FormControl(this.profileDate?.education?this.profileDate.education:'');
  ngOnInit(): void {

    this.nameDetails = [
      {name:"First Name",value:this.profileDate?.firstName?this.profileDate.firstName:''},
      {name:"Middle Name",value:this.profileDate?.middleName?this.profileDate.middleName:''},
      {name:"Third Name",value:this.profileDate?.thirdName?this.profileDate.thirdName:''},
      {name:"Last Name",value:this.profileDate?.thirdName?this.profileDate.lastName:''}
    ];
    this.statusDetails = [
      {name:"Marital Status",value: this.profileDate?.maritalStatus?this.profileDate.maritalStatus:"NA"},
      {name:"Gender",value: this.profileDate?.gender?this.profileDate.gender == 1?"Male":"Female":"NA"},
      {name:"Nationality",value: this.profileDate?.nationality?this.profileDate?.nationality:"NA"}

    ];
    this.birthDetails = [
      {name:"Place of Birth",value: this.profileDate?.placeOfBirth?this.profileDate.placeOfBirth:"NA"},
      {name:"Date of Birth in Hijri",value: this.profileDate?.dateOfBirth?this.profileDate.dobHijri:"NA"},
      {name:"Date of Birth in Gregorian",value: this.profileDate?.dateOfBirth?this.profileDate.dateOfBirth:"NA"},

    ];
    this.expireDetails = [
      {name:"ID Number",value: this.profileDate?.iqamaID?this.profileDate.iqamaID:'NA'},
      {name:"ID Expiration Date Hijri",value:this.profileDate?.idExpiryDateHijri?this.profileDate.idExpiryDateHijri:'NA'},
      {name:"ID Expiration Date Gregorian",value:this.profileDate?.idExpiryDateGregorian?this.profileDate.idExpiryDateGregorian:'NA'},

    ];
    this.titles.forEach(res=>{
      if(res.title == this.profileDate?.title ){
            res.checked = true;
      }
    })
    this.personalForm = new FormGroup({
      Title : new FormControl(this.profileDate?.title,[Validators.required]),

      // id:  new FormControl(,[Validators.required]),
      // idExpiryDateHijri:  new FormControl(this.profileDate?.idExpiryDateHijri?this.profileDate.idExpiryDateHijri:'',[Validators.required]),
      // idExpiryDateGregorian:  new FormControl(this.profileDate?.idExpiryDateGregorian?this.profileDate.idExpiryDateGregorian:'',[Validators.required]),



  educationLevel:  new FormControl(this.profileDate?.userPersonalProfile?.educationalLevelID == 0?null:this.profileDate?.userPersonalProfile?.educationalLevelID,[Validators.required]),




    })

    // localStorage.setItem('loginResponse',JSON.stringify(ob))
    console.log('profileDate :>> ', this.profileDate);
    this.profile.emitChanges.pipe(takeUntil(this.unsubscribeAll)).subscribe(res=>{
      console.log(res);

      if(res == 'personal'){
        this.update()
      }
    })
    // if(this.enableEdit == 1){

    //   this.renderer.listen('window', 'click',(e:Event)=>{
    // this.enableEdit = false;

    //   })
    // }
    // this.titleName.statusChanges.subscribe(
      //   res=>{
        //     console.log(res)
    //   })
        this.educationsDegrees = JSON.parse(localStorage.getItem('masterData')).educationalLevels
    let personalData =[
      {
          formControl:'titleName',
          isEditable:true,
          visible:true,
          labelName:'Title',
          selectMenu:true,
          menuData:['Mr','Ms']
        },
      {
     formControl:'firstName',
     isEditable:false,
     visible:true,
     labelName:'First Name',
     selectMenu:false
   },
   {
     formControl:'middleName',
     isEditable:false,
     visible:true,
     labelName:'Middle Name',
     selectMenu:false
   },
   {
     formControl:'thirdName',
     isEditable:false,
     visible:true,
     labelName:'Family Name',
     selectMenu:false
   },
   {
     formControl:'iqamaID',
     isEditable:false,
     visible:true,
     labelName:'ID Number',
     selectMenu:false
   },
   {
     formControl:'idExpiryDateHijri',
     isEditable:false,
     visible:true,
     labelName:'ID Expiration Date Hijri',
     selectMenu:false
   },
   {
     formControl:'idExpiryDateGregorian',
     isEditable:false,
     visible:true,
     labelName:'ID Expiration Date Gregorian',
     selectMenu:false
   },
   {
     formControl:'nationality',
     isEditable:false,
     visible:true,
     labelName:'Nationality',
     selectMenu:false
   },
   {
     formControl:'dateOfBirth',
     isEditable:false,
     visible:true,
     labelName:'Date of Birth in Hirji',
     selectMenu:false
   },
   {
     formControl:'dateOfBirth',
     isEditable:false,
     visible:true,
     labelName:'Date of Birth in Gregorian',
     selectMenu:false
   },
   {
     formControl:'placeOfBirth',
     isEditable:false,
     visible:true,
     labelName:'Place of Birth',
     selectMenu:false
   },
   {
     formControl:'maritalStatus',
     isEditable:false,
     visible:true,
     labelName:'Marital Status',
     selectMenu:false
   },
   {
     formControl:'gender',
     isEditable:false,
     visible:true,
     labelName:'Gender',
     selectMenu:false,
     menuData:[{id:1,name:'Male'},{id:2,name:'Female'}]

   },
   {
     formControl:'educationLevel',
     isEditable:true,
     visible:true,
     labelName:'Education Level',
     selectMenu:true,
     menuData:this.educationsDegrees
    }
  ]
    this.formDataPersonal= {
      formData:personalData,
      formGroup: this.personalForm,
      // formLabels:Object.keys(formObjecObligations)
    }



  }

  selectTitle(tit){
    // tit.checked = true;
    // this.titles = this.titles.map(res=>res.title == tit.title)
    for(let ti of this.titles){
       if(tit.title == ti.title){
         ti.checked = true;
         this.personalForm.get('Title').setValue(tit.title)
       } else {
        ti.checked = false

       }
    }
  }
  update(){
    console.log(this.personalForm);

    if(this.personalForm.invalid){
      this.loginFormValidationFlag = true;
      return
    }
    let personalData = {
      "userID":this.profileDate.id,
      "Title":this.personalForm.value.Title,
      "EducationalLevelID":this.personalForm.value.educationLevel,
  "isSave": true
    }
    this.profile.updateProfile(personalData,'updatepersonaldetails').subscribe(res=>{
      console.log(res)
      if(res.status){

        this.creditlyServices.notify("Successfully updated", "success");
      }

    },err=>{
      this.creditlyServices.notify(err?.error.title?err.error.title:err?.message, "error");

    })
  }

  filter(data: any, id: number | string) {
    if (data?.length && data?.filter((val) => val.id == id)[0]?.name)
      return data.filter((val) => val.id == id)[0].name;
    else return 'NA';
  }
// ngAfterViewChecked() {
//   if(this.titleName.touched){
//     this.titleName.markAsUntouched();
//     this.enableEdit = false;
//     this.titleName.updateValueAndValidity()
//     this.cdr.detectChanges();
//   }
//   if(this.educationLevel.touched){
//     this.educationLevel.markAsUntouched();
//     this.enableEdit = false;
//     this.educationLevel.updateValueAndValidity()
//     this.cdr.detectChanges();
//   }

// }

}
// export const PROFIL_DATA = {
//   title:
// }
