<!-- <app-header></app-header> -->
<div class="profile-section">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="main-content-section">
                    <div class="tab-section">
                        <div class="row">
                            <!-- <div class="greetings">
                                <p id="greetings-title">Welcome back,</p>
                                <a class="person-name highlighted" id="pill1" role="tab" aria-selected="false">
                  {{profileDate?.firstName}} {{profileDate?.lastName}},
                </a>

                            </div> -->
                            <div class="barBox mobile m-a">
                                <div class="progress">
                                    <span class="progress-bar" [ngStyle]="{'width':percent+'%'}"></span>
                                </div>
                                <div class="complete-info">
                                    <span class="highlighted">{{percent}}%</span>
                                    <span class="p-tag">
                      Completed
                  </span>
                                    <div class="learn-more">
                                        <a href="">Learn More</a>
                                    </div>
                                </div>
                            </div>
                            <app-profile-form [userProfile]="true" class="mobile m-a w-100"></app-profile-form>


                            <div class="profile-tabs desktop-flex">
                                <div class="side-contain">

                                    <accordion>
                                        <accordion-group [isOpen]="currentTab == 'personal'? true : false">
                                            <button class="btn btn-link btn-block justify-content-between align-items-center d-flex w-100  shadow-none" accordion-heading type="button">
                                  <div class=" d-flex align-items-center">
                                    <img src="../../../assets/images/icons/profile-icon.svg" alt="profile" class="mr-2">
                                   <span class='acco-text-head'>
                                     Profile
                                   </span>
                                  </div>
                                   <img class='arrow-acco'   src="../../../assets/images/icons/arrow-right-pr.svg" alt="">

                                </button>
                                            <ul class="nav nav-pills flex-column" id="pills-tab" role="tablist" aria-orientation="vertical">
                                                <li class="nav-item" (click)="currentTab='personal';headName ='Personal Information'">
                                                    <a class="nav-link active" [ngClass]="{'active':currentTab=='personal'}" id="pills-personal-tab" data-toggle="pill" href="#pills-personal" role="tab" aria-controls="pills-personal" aria-selected="false">
                                                        <span class="">Personal Information</span>
                                                    </a>
                                                </li>
                                                <li class="nav-item" (click)="currentTab='contact';headName ='Address and Contacts'">
                                                    <a class="nav-link" [ngClass]="{'active':currentTab=='contact'}" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">

                                                        <span class="">Address and Contacts</span></a>
                                                </li>
                                                <li class="nav-item" (click)="currentTab='employment';headName ='Income and Employment'">
                                                    <a class="nav-link" [ngClass]="{'active':currentTab=='employment'}" id="pills-employment-tab" data-toggle="pill" href="#pills-employment" role="tab" aria-controls="pills-employment" aria-selected="false">

                                                        <span class="">Income and Employment</span></a>
                                                </li>
                                                <li class="nav-item" (click)="currentTab='financial';headName ='Financial Details'">
                                                    <a class="nav-link" [ngClass]="{'active':currentTab=='financial'}" id="pills-financial-tab" data-toggle="pill" href="#pills-financial" role="tab" aria-controls="pills-financial" aria-selected="false">

                                                        <span class="">Financial Details</span></a>
                                                </li>
                                                <li class="nav-item" (click)="currentTab='bank';headName ='Bank Account'">
                                                    <a class="nav-link" [ngClass]="{'active':currentTab=='bank'}" id="pills-bankaccount-tab" data-toggle="pill" href="#pills-bankaccount" role="tab" aria-controls="pills-bankaccount" aria-selected="false">
                                                        <span class="">Bank Account</span> </a>
                                                </li>

                                                <li class="nav-item desktop">
                                                    <!-- <div class="barBox">
                                                    <div class="progress">
                                                        <span class="progress-bar" [ngStyle]="{'width':percent+'%'}"></span>
                                                    </div>
                                                    <div class="complete-info">
                                                        <span class="highlighted">{{percent}}%</span>
                                                        <span class="p-tag">
                          Completed
                      </span>
                                                        <div class="learn-more">
                                                            <a (click)="captureScreen('r')">Learn More</a>
                                                        </div>
                                                    </div>
                                                </div> -->
                                                </li>
                                            </ul>
                                        </accordion-group>
                                        <accordion-group [isOpen]="currentTab == 'pending'? true : false">
                                            <button class="btn btn-link btn-block justify-content-between align-items-center d-flex w-100  shadow-none" accordion-heading type="button">
                                        <div class=" d-flex align-items-center">
                                          <img src="../../../assets/images/icons/req-ic.svg" alt="profile" class="mr-2">
                                         <span class='acco-text-head'>
                                          Requests
                                         </span>
                                        </div>
                                         <img class='arrow-acco' src="../../../assets/images/icons/arrow-right-pr.svg" alt="">

                                      </button>
                                            <ul class="nav nav-pills flex-column" id="pills-tab" role="tablist" aria-orientation="vertical">
                                                <li class="nav-item" (click)="currentTab='pending';headName ='Pending Requests';">
                                                    <a class="nav-link " [ngClass]="{'active':currentTab=='pending'}">
                                                        <span class="">Pending Requests</span>
                                                    </a>
                                                </li>
                                                <li class="nav-item" (click)="currentTab='approved';headName ='Approved Requests';">
                                                    <a class="nav-link" [ngClass]="{'active':currentTab=='approved'}" id="pills-requests-approved-tap" data-toggle="pill" href="#pills-requests-approved" role="tab" aria-controls="pills-contact" aria-selected="false">

                                                        <span class="">Approved Requests</span></a>
                                                </li>
                                                <li class="nav-item" (click)="currentTab='rejected';headName ='Rejected Requests';">
                                                    <a class="nav-link" [ngClass]="{'active':currentTab=='rejected'}" id="pills-requests-rejected-tap" data-toggle="pill" href="#pills-requests-rejected" role="tab" aria-controls="pills-employment" aria-selected="false">

                                                        <span class="">Rejected Requests</span></a>
                                                </li>
                                                <li class="nav-item" (click)="currentTab='history';headName ='History';">
                                                    <a class="nav-link" [ngClass]="{'active':currentTab=='history'}" id="pills-requests-history-tap" data-toggle="pill" href="#pills-requests-history" role="tab" aria-controls="pills-financial" aria-selected="false">

                                                        <span class="">History</span></a>
                                                </li>
                                            </ul>
                                            <!-- <li class="nav-item" (click)="currentTab='request'">
                                            <a class="nav-link" id="pills-requests-tab" data-toggle="pill" href="#pills-requests" role="tab" aria-controls="pills-requests" aria-selected="false">
                                                <span class="">Requests</span>
                                            </a>
                                        </li>    -->
                                        </accordion-group>
                                    </accordion>
                                    <ul class="nav nav-pills flex-column main-menu" id="pills-tab" role="tablist" aria-orientation="vertical">
                                        <li class="nav-item" (click)="currentTab='document';headName ='Documents'">
                                            <a class="nav-link" aria-selected="true" [ngClass]="{'active':currentTab=='document'}">
                                                <span class=""><img src="../../../assets/images/icons/doc-icon.svg" alt=""> Documents</span>
                                            </a>
                                        </li>
                                        <li class="nav-item" (click)="currentTab='support';headName ='Support'">
                                            <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-support" role="tab" aria-controls="pills-contact" aria-selected="false">

                                                <span class=""><img src="../../../assets/images/icons/sup-ic.svg" alt="">Support</span></a>
                                        </li>

                                        <li class="nav-item desktop">

                                        </li>
                                    </ul>
                                    <hr class='profile-line'>
                                    <div class="change-pass d-flex">
                                        <img src="../../../assets/images/icons/lock-pass.svg" alt="">
                                        <a>
                                         change Password
                                       </a>
                                    </div>
                                </div>




                                <div class="profile-content">
                                    <div class="content-header d-flex align-items-center justify-content-between">
                                        <div class="content-title">
                                            <h3>
                                                {{headName}}
                                            </h3>
                                        </div>
                                        <button class="save-butn" (click)='update()'>
                                          <span class="material-icons-outlined rot-log mt-1 mr-2">
                                            </span>
                                    Save Changes

                                  </button>
                                    </div>
                                    <div class="card profile-card" [ngClass]="{'history-card':currentTab=='history'}">
                                        <div class="card-body" [ngClass]="{'history-card':currentTab=='history'}">
                                            <div class="tab-content profile-content" id="pills-tabContent py-2 px-4" [ngClass]="{'p-0':['pending','approved','rejected','history'].includes(currentTab)}">
                                                <!-- <div class="tab-pane fade active show" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                          <app-profile-info></app-profile-info>

                                      </div> -->
                                                <div class="tab-pane fade" [ngClass]="{'active show':currentTab =='personal'}" id="pills-personal">
                                                    <app-personal-details></app-personal-details>
                                                </div>
                                                <div class="tab-pane fade " id="pills-contact" [ngClass]="{'active show':currentTab =='contact'}">
                                                    <app-contact-info></app-contact-info>
                                                </div>
                                                <div class="tab-pane fade" id="pills-employment" [ngClass]="{'active show':currentTab =='employment'}">
                                                    <app-employment-details></app-employment-details>
                                                </div>
                                                <div class="tab-pane fade" id="pills-financial" [ngClass]="{'active show':currentTab =='financial'}">
                                                    <app-financial-details></app-financial-details>
                                                </div>
                                                <div class="tab-pane fade" id="pills-bankaccount" [ngClass]="{'active show':currentTab =='bank'}">
                                                    <app-bank-account-details></app-bank-account-details>
                                                </div>
                                                <div class="tab-pane fade" [id]="'pills-requests-'+currentTab" [ngClass]="{'active show':['pending','approved','rejected','history'].includes(currentTab)}"> 
                                                    <app-history-details [reqFrom]="routeparams" [reqType]="currentTab"></app-history-details>
                                                </div>
                                                <div class="tab-pane fade" [id]="'pills-requests-'+currentTab" [ngClass]="{'active show':currentTab == 'document'}">
                                                    <app-douments-profile [reqType]="currentTab"></app-douments-profile>
                                                </div>
                                                <div class="tab-pane fade" [id]="'pills-requests-'+currentTab" [ngClass]="{'active show':currentTab == 'support'}">
                                                    <app-support [reqType]="currentTab"></app-support>
                                                </div>
                                                <div class="tab-pane fade" [id]="'pills-requests-'+currentTab" [ngClass]="{'active show':currentTab == 'rejected'}">
                                                   <p>rejected</p>
                                                    <app-rejected-requests></app-rejected-requests>
                                                </div>
                                                <div class="tab-pane fade" [id]="'pills-requests-'+currentTab" [ngClass]="{'active show':currentTab == 'approved'}">
                                                    <p>approved</p>
                                                    <app-approved-requests ></app-approved-requests>
                                                </div>
                                                <!-- app-douments-profile -->
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                    <!-- <div class="recommended-section" *ngIf="currentTab==='profile'">
            <div class="recommended-section-body">
              <p style="color: #777D90;">Recommended products for you:</p>
              <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"
                (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
                (beforeChange)="beforeChange($event)">
                <div ngxSlickItem class="slide">
                  <div class="custom-card1 card">
                    <div class="card-body f-12 px-3 py-3">
                      <div class="cc-img">
                        <img src="../../../../assets/images/main_images/visa_dummy_image.png" width="80px">
                      </div>
                      <div class="cc-title">
                        <p class="mb-1">Credit card</p>
                        <p class="f-18 c-txt">Visa Platinum Credit Card</p>
                      </div>
                      <div class="mb-2">
                        <img src="../../../../assets/images/main_images/bank_almad.png" width="80px" />
                      </div>
                      <div class="text-center d-flex justify-content-center">
                        <span><u><strong>View more</strong></u></span>
                        &nbsp; &nbsp; &nbsp;
                        <span><u><strong>Apply now</strong></u></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div ngxSlickItem class="slide">
                  <div class="custom-card1 card">
                    <div class="card-body f-12 px-3 py-3">
                      <div class="cc-img">
                        <img src="../../../../assets/images/main_images/visa_dummy_image.png" width="80px">
                      </div>
                      <div class="cc-title">
                        <p class="mb-1">Credit card</p>
                        <p class="f-18 c-txt">Visa Platinum Credit Card</p>
                      </div>
                      <div class="mb-2">
                        <img src="../../../../assets/images/main_images/bank_almad.png" width="80px" />
                      </div>
                      <div class="text-center d-flex justify-content-center">
                        <span><u><strong>View more</strong></u></span>
                        &nbsp; &nbsp; &nbsp;
                        <span><u><strong>Apply now</strong></u></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div ngxSlickItem class="slide">
                  <div class="custom-card1 card">
                    <div class="card-body f-12 px-3 py-3">
                      <div class="cc-img">
                        <img src="../../../../assets/images/main_images/visa_dummy_image.png" width="80px">
                      </div>
                      <div class="cc-title">
                        <p class="mb-1">Credit card</p>
                        <p class="f-18 c-txt">Visa Platinum Credit Card</p>
                      </div>
                      <div class="mb-2">
                        <img src="../../../../assets/images/main_images/bank_almad.png" width="80px" />
                      </div>
                      <div class="text-center d-flex justify-content-center">
                        <span><u><strong>View more</strong></u></span>
                        &nbsp; &nbsp; &nbsp;
                        <span><u><strong>Apply now</strong></u></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div ngxSlickItem class="slide">
                  <div class="custom-card1 card">
                    <div class="card-body f-12 px-3 py-3">
                      <div class="cc-img">
                        <img src="../../../../assets/images/main_images/visa_dummy_image.png" width="80px">
                      </div>
                      <div class="cc-title">
                        <p class="mb-1">Credit card</p>
                        <p class="f-18 c-txt">Visa Platinum Credit Card</p>
                      </div>
                      <div class="mb-2">
                        <img src="../../../../assets/images/main_images/bank_almad.png" width="80px" />
                      </div>
                      <div class="text-center d-flex justify-content-center">
                        <span><u><strong>View more</strong></u></span>
                        &nbsp; &nbsp; &nbsp;
                        <span><u><strong>Apply now</strong></u></span>
                      </div>
                    </div>
                  </div>
                </div>

              </ngx-slick-carousel>
            </div>
          </div> -->
                </div>
            </div>
        </div>
    </div>


    <!-- Main content section -->


    <!-- <app-footer></app-footer> -->
</div>


<!-- <div id='contentToConvert1'>
    <div class="container-fluid pt-5 pb-5 pl-5 pr-5">

        <div class="row justify-content-between">
            <div class="col-3">
                <div class="logo_pdf d-flex align-items-center ">
                    <img src="../../../assets/images/header-images/header-main-logo.png" alt="">
                    <p>
                        Creditly
                    </p>
                </div>
                <div class="log-title">
                    <p>
                        Musa Ibn Nusair Street
                    </p>
                </div>
                <div class="logo-subtitle mt-3">
                    Musa Ibn Nusair Street - Al Olaya,<br> Riyadh, 12244, Saudi Arabia<br> CR Number: 1010455189<br> wecare@creditly.sa
                    <br>
                </div>

            </div>
            <div class="col-9 mt-4 app-form-title">
                <div class=" d-flex w-100 justify-content-between">
                    <div class="en-title">
                        <p class="m-0">
                            Credit Card Appication Form

                        </p>

                    </div>
                    <div class="ar-title">
                        <p class="m-0">
                            تفاصيل المنتج
                        </p>

                    </div>
                </div>
                <hr class='m-0 p-0'>

                <div class="row" *ngFor='let item of pdfDownload?.productDetails'>
                    <div class="col-6 d-flex">
                        <p class='title'>{{item.title}}</p>
                        <p class='sub-title'>{{item.value}}</p>

                    </div>
                    <div class="col-6 d-flex" dir='rtl'>
                        <p class='title'>{{item.title_ar}}</p>
                        <p class='sub-title'>{{item.value_ar}}</p>
                    </div>
                </div>

            </div>

        </div>

        <div class="row justify-content-between mt-5 mb-5 hero-data">
            <div class="col-6 pr-4">
                <div class=" d-flex w-100 justify-content-between ">
                    <div class="en-title">
                        <p class="m-0">
                            Credit Card Appication Form

                        </p>

                    </div>
                    <div class="ar-title">
                        <p class="m-0">
                            تفاصيل المنتج
                        </p>

                    </div>
                </div>
                <hr>

                <div class="row" *ngFor='let item of pdfDownload?.personalInfo'>
                    <div class="col-6">
                        <p class='title'>{{item.title}}</p>
                        <p class='sub-title'>{{item.value}}</p>

                    </div>
                    <div class="col-6 " dir='rtl'>
                        <p class='title'>{{item.title_ar}}</p>
                        <p class='sub-title'>{{item.value_ar}}</p>
                    </div>
                </div>

                <div class=" d-flex w-100 justify-content-between ">
                    <div class="en-title">
                        <p class="m-0">
                            Credit Card Appication Form

                        </p>

                    </div>
                    <div class="ar-title">
                        <p class="m-0">
                            تفاصيل المنتج
                        </p>

                    </div>
                </div>
                <hr>

                <div class="row" *ngFor='let item of pdfDownload?.relativeContactInfo'>
                    <div class="col-6">
                        <p class='title'>{{item.title}}</p>
                        <p class='sub-title'>{{item.value}}</p>

                    </div>
                    <div class="col-6 " dir='rtl'>
                        <p class='title'>{{item.title_ar}}</p>
                        <p class='sub-title'>{{item.value_ar}}</p>
                    </div>
                </div>
            </div>
            <div class="col-6 pl-4 hero-data">
                <div class=" d-flex w-100 justify-content-between ">
                    <div class="en-title">
                        <p class="m-0">
                            Credit Card Appication Form

                        </p>

                    </div>
                    <div class="ar-title">
                        <p class="m-0">
                            تفاصيل المنتج
                        </p>

                    </div>
                </div>
                <hr>
                <div class="row" *ngFor='let item of pdfDownload?.contactAddress'>
                    <div class="col-6">
                        <p class='title'>{{item.title}}</p>
                        <p class='sub-title'>{{item.value}}</p>

                    </div>
                    <div class="col-6 " dir='rtl'>
                        <p class='title'>{{item.title_ar}}</p>
                        <p class='sub-title'>{{item.value_ar}}</p>
                    </div>
                </div>
                <div class=" d-flex w-100 justify-content-between ">
                    <div class="title">
                        <p class="m-0">
                            Credit Card Appication Form

                        </p>

                    </div>
                    <div class="ar-title">
                        <p class="m-0">
                            تفاصيل المنتج
                        </p>

                    </div>
                </div>
                <hr>


                <div class="row" *ngFor='let item of pdfDownload?.homeCountryAdd'>
                    <div class="col-6">
                        <p class='title'>{{item.title}}</p>
                        <p class='sub-title'>{{item.value}}</p>

                    </div>
                    <div class="col-6 " dir='rtl'>
                        <p class='title'>{{item.title_ar}}</p>
                        <p class='sub-title'>{{item.value_ar}}</p>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>



<div id='contentToConvert2'>
    <div class="container-fluid pt-5 pb-5 pl-5 pr-5">

        <div class="row justify-content-between">
            <div class="col-3">
                <div class="logo_pdf d-flex align-items-center mb-2 ">
                    <img src="../../../assets/images/header-images/header-main-logo.png" alt="">
                    <p>
                        Creditly
                    </p>
                </div>


            </div>


        </div>

        <div class="row justify-content-between mt-3 mb-5 hero-data">
            <div class="col-6 pr-4">
                <div class=" d-flex w-100 justify-content-between ">
                    <div class="en-title">
                        <p class="m-0">
                            Credit Card Appication Form

                        </p>

                    </div>
                    <div class="ar-title">
                        <p class="m-0">
                            تفاصيل المنتج
                        </p>

                    </div>
                </div>
                <hr>

                <div class="row" *ngFor='let item of pdfDownload?.personalInfo'>
                    <div class="col-6">
                        <p class='title'>{{item.title}}</p>
                        <p class='sub-title'>{{item.value}}</p>

                    </div>
                    <div class="col-6 " dir='rtl'>
                        <p class='title'>{{item.title_ar}}</p>
                        <p class='sub-title'>{{item.value_ar}}</p>
                    </div>
                </div>

                <div class=" d-flex w-100 justify-content-between ">
                    <div class="en-title">
                        <p class="m-0">
                            Credit Card Appication Form

                        </p>

                    </div>
                    <div class="ar-title">
                        <p class="m-0">
                            تفاصيل المنتج
                        </p>

                    </div>
                </div>
                <hr>

                <div class="row" *ngFor='let item of pdfDownload?.relativeContactInfo'>
                    <div class="col-6">
                        <p class='title'>{{item.title}}</p>
                        <p class='sub-title'>{{item.value}}</p>

                    </div>
                    <div class="col-6 " dir='rtl'>
                        <p class='title'>{{item.title_ar}}</p>
                        <p class='sub-title'>{{item.value_ar}}</p>
                    </div>
                </div>
            </div>
            <div class="col-6 pl-4 hero-data">
                <div class=" d-flex w-100 justify-content-between ">
                    <div class="en-title">
                        <p class="m-0">
                            Credit Card Appication Form

                        </p>

                    </div>
                    <div class="ar-title">
                        <p class="m-0">
                            تفاصيل المنتج
                        </p>

                    </div>
                </div>
                <hr>
                <div class="row" *ngFor='let item of pdfDownload?.contactAddress'>
                    <div class="col-6">
                        <p class='title'>{{item.title}}</p>
                        <p class='sub-title'>{{item.value}}</p>

                    </div>
                    <div class="col-6 " dir='rtl'>
                        <p class='title'>{{item.title_ar}}</p>
                        <p class='sub-title'>{{item.value_ar}}</p>
                    </div>
                </div>
                <div class=" d-flex w-100 justify-content-between ">
                    <div class="title">
                        <p class="m-0">
                            Credit Card Appication Form

                        </p>

                    </div>
                    <div class="ar-title">
                        <p class="m-0">
                            تفاصيل المنتج
                        </p>

                    </div>
                </div>
                <hr>


                <div class="row" *ngFor='let item of pdfDownload?.homeCountryAdd'>
                    <div class="col-6">
                        <p class='title'>{{item.title}}</p>
                        <p class='sub-title'>{{item.value}}</p>

                    </div>
                    <div class="col-6 " dir='rtl'>
                        <p class='title'>{{item.title_ar}}</p>
                        <p class='sub-title'>{{item.value_ar}}</p>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div> -->