<div class="container">
    <form [formGroup]="formGroup">
        <div class="row contact-row">
            <div class="pro-main-label">
                <p>
                    Obligations
                </p>
            </div>
        </div>



        <div class="row">
            <div class="col-md-6" *ngFor='let nam of obligDetailsForm let i=index;'>

                <div class="form-group-n w-100">
                    <label class="form-label-n d-block">
                    {{nam.name}}
                  </label>
                    <input [type]="nam.type=='date'?'date':'text'" [formControlName]='nam.formControl' class="text-form-input w-100">

                </div>

            </div>

        </div>
        <hr class="profile-line">
        <div class="row">

            <div class='col-md-4' *ngFor='let nam of obligDetails let i=index;'>
                <div class="person-details">
                    <p class="title-label">
                        {{nam.name}}
                    </p>
                    <p class="title-value">
                        {{nam.value && nam.value !==''?nam.value:"NA"}}
                    </p>
                </div>
            </div>
        </div>


        <hr class="profile-line">

        <div class="row">
            <div class="col-md-6">

                <div class="form-group-n w-100">
                    <label class="form-label-n d-block">
                    Transportation Type</label>
                    <select formControlName='TransportationType' class="select-form-input w-100">
                    <!-- <option *ngFor='let nut of naturesBusses' [value]="nut">{{nut}}</option>
                    op -->
                    <option value="">
                      Select the type
                    </option>
                    <option *ngFor='let trns of transportationTypes' [value]="trns.name">{{trns.name}}</option>
                  </select>

                </div>

            </div>

        </div>
        <hr class="profile-line">

        <div class="row contact-row">
            <div class="pro-main-label">
                <p>
                    Obligations
                </p>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6" *ngFor='let cont of formObjetRespon'>

                <div class="form-group-n w-100">
                    <label class="form-label-n d-block">
                {{cont.labelName}}</label>
                    <input type="text" [formControlName]='cont.formControl' placeholder="Write the data" class="text-form-input w-100">

                </div>

            </div>

        </div>
        <hr class="profile-line">
        <div class="row">
            <div class='col-md-4'>
                <div class="person-details">
                    <p class="title-label">
                        Total Responsible Lending
                    </p>
                    <p class="title-value">
                        {{profileDate?.userPersonalProfile?.totalResponsibleLending?profileDate?.userPersonalProfile?.totalResponsibleLending:"NA"}}
                    </p>
                </div>
            </div>
        </div>
    </form>
</div>