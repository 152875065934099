import { Component, OnInit,Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CreditlyServicesService } from 'src/app/creditly-services.service';

@Component({
  selector: 'app-compare-list-shared',
  templateUrl: './compare-list-shared.component.html',
  styleUrls: ['./compare-list-shared.component.scss']
})
export class CompareListSharedComponent implements OnInit,OnDestroy {
  @Input() compareList:{
    compareListData,
    comperdType,
    catId,
    catName,
    formData?
  }
  isDropup = true;
  catId;
  compareListData
  userData = JSON.parse(localStorage.getItem('loginResponse'));
  currentLang: string;
  unsubscribeAll = new Subject<null>();
  catName: any;
  constructor(private router:Router,private cdr:ChangeDetectorRef,
    private creditlyServeices: CreditlyServicesService,
   private ngNavigatorShareService: NgNavigatorShareService,
   private translate: TranslateService
    ) { }

  ngOnInit(): void {
    this.compareListData = this.compareList.compareListData
    this.catId = this.compareList.catId;
    this.catName = this.compareList.catName;
    this.currentLang = localStorage.getItem('currentLang');
    this.translate.onLangChange.pipe(takeUntil(this.unsubscribeAll)).subscribe(lang=>{
      this.currentLang = lang.lang
    })
  }
ngOnDestroy() {
  this.unsubscribeAll.next();
  this.unsubscribeAll.complete();

}

  public removeProduct(product): void {
    const index = this.compareListData.indexOf(product);
    if (index > -1) {
      this.compareListData.splice(index, 1);
    }
    console.log(this.compareListData);
  }
  public navigateToComparePage(): void {
    if (this.compareListData.length > 1) {
      localStorage.setItem("compairCards", JSON.stringify(this.compareListData))
      sessionStorage.setItem("CategoryID", this.compareList.catId);
      this.router.navigateByUrl('creditly/cardsCompare');
    } else {
      alert("Add two or more products to Compare...!")
    }
  }

  public resetList(): void {
    console.log('rested')
    this.compareListData.splice(0,this.compareListData.length+1 );
    this.compareListData = [];
    this.compareListData.length = 0;
    console.log(this.compareListData)

    localStorage.setItem("compairCards", JSON.stringify(this.compareListData))
    this.cdr.detectChanges()

  }

  shareURL(item) {
    /*  // let baseUrl = this.creditlyServices.shareProductUrl;
     let baseUrl = "http://beta.creditly.sa/#/"
     // let baseUrl = "http://localhost:2040/#/"
     let componentUrl = "creditly/shareProductView"
     let totUrl = `${baseUrl}${componentUrl}/?title=${item.seo.url}&prodType=1`
     console.log("totUrl", totUrl);
     const sss = this.router.createUrlTree([], { queryParams: { title: item.seo.url, prodType: '1' } });
     console.log("sss", this.serializer.serialize(sss));
      */

    sessionStorage.setItem("CategoryID", this.catId);
    if (!this.ngNavigatorShareService.canShare()) {
      this.creditlyServeices.notify('This service/api is not supported in your Browser', 'error');
      return
    }

    let baseUrl = this.creditlyServeices.shareProductUrl;
    let componentUrl = "creditly/applycard"
    let totUrl = `${baseUrl}${componentUrl}/?title=${item.seo.url}&prodType=3`
    console.log("totUrl", totUrl);

    this.ngNavigatorShareService.share({
      title: 'My Awesome app',
      text: 'hey check out my Share button',
      url: totUrl
    }).then((response) => {
      console.log('share response..', response);
      // this.creditlyServices.notify(`${response}`, 'info');
    }).catch((error) => {
      console.log('share error response..', error);
      // this.creditlyServices.notify(`${error}`, 'error');
    })
  }
  FilterValue(id, array) {
    return array?.fees.filter((val) => val.name == "Management Fees")[0]?.value
      ? array?.fees.filter((val) => val.name == "Management Fees")[0]?.value
      : 0;
  }
  previewProduct(product){
    if (this.userData) {
      if(this.compareList.comperdType == 'cards list'){
        sessionStorage.setItem("CategoryID", this.catId );
        sessionStorage.setItem("selectedCard", JSON.stringify(product));
        localStorage.removeItem('selectedVehicleId');
        // sessionStorage.setItem("selectedCard", JSON.stringify(data));
        // sessionStorage.setItem("CategoryID", "2");
        if(this.catId == "2"){

          localStorage.setItem("applyLoanData", JSON.stringify({ duration: this.compareList.formData.Period * 12, mangementFee: this.FilterValue(1, product?.fees) }));
        }
        this.router.navigateByUrl('creditly/applyProduct/'+product.id+'?For='+this.compareList.catName);
  }
} else {
  this.router.navigateByUrl('/login');

}
  }
}
