import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getName'
})
export class GetNamePipe implements PipeTransform {
currentLang = localStorage.getItem('currentLang')
  transform(value: any, arr:Array<any>,currentLang): string {
    console.log(this.currentLang);

    let getName = arr.find(res=>res.id == value);
    console.log(getName);

    return currentLang=='en'? getName?.name:getName?.name_ar;
  }

}
