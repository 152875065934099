import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { CreditlyServicesService } from 'src/app/creditly-services.service';
import { residenceTypes } from 'src/app/shared/data/list-menus';
import { ProfileService } from 'src/app/shared/services/profile.service';

@Component({
  selector: 'app-financial-details',
  templateUrl: './financial-details.component.html',
  styleUrls: ['./financial-details.component.scss']
})
export class FinancialDetailsComponent implements OnInit {
  @Input() item = '';
  profileDate = JSON.parse(localStorage.getItem("loginResponse"));
  formGroup: FormGroup;
  loginFormValidationFlag: boolean = false;
  submit = false;
  cardLimits = [{ value: 0 }];
transportationTypes = residenceTypes
  calci = false
  private ngUnsubscribe = new Subject();
  formDataObligations: { formData: { formControl: string; isEditable: boolean; visible: boolean; labelName: string; selectMenu: boolean; }[]; formGroup: FormGroup; };
  formDataRespo: { formData: { formControl: string; isEditable: boolean; visible: boolean; labelName: string; selectMenu: boolean; }[]; formGroup: FormGroup; };
  obligDetails;
  formObjetRespon: { formControl: string; labelName: string; selectMenu: boolean; }[];
  obligDetailsForm: ({ name: string; formControl: string; value: any; type?: undefined; } | { name: string; formControl: string; value: any; type: string; })[];
  constructor(private ApiService: CreditlyServicesService,private profile:ProfileService) {
let formObjecObligations = {
  // id: new FormControl(this.profileDate?.userPersonalEmployement?.id),
  userID: {
    formControl:'userID',
    isEditable:false,
    visible:false,
    labelName:'',
    selectMenu:false
  },
  monthlyDeductions: {
    formControl:'monthlyDeductions',
    isEditable:true,
    visible:true,
    labelName:'Total Monthly Deductions',
    selectMenu:false

  },
  otherCardsLimits:{
    formControl:'otherCardsLimits',
    isEditable:true,
    visible:true,
    labelName:'Total Credit Cards Limits',
    selectMenu:false

  },
  RemainingMonths: {
    formControl:'RemainingMonths',
    isEditable:true,
    visible:true,
    labelName:'Remaining Months',
    selectMenu:false

  },
  CurrentDBR: {
    formControl:'CurrentDBR',
    isEditable:false,
    visible:true,
    labelName:'Debt Burden Ration (DBR)',
    selectMenu:false

  },
  // monthlyExtraExpences: new FormControl(this.profileDate?.userPersonalProfile?.monthlyExtraExpences, [Validators.required]),
  // dependencies: new FormControl(this.profileDate?.userPersonalProfile?.dependencies, [Validators.required]),
  // currentDBR: new FormControl(this.profileDate?.userPersonalProfile?.currentDBR, [Validators.required]),
  // housing: new FormControl(this.profileDate?.userPersonalProfile?.housing, [Validators.required]),
  // utilities: new FormControl(this.profileDate?.userPersonalProfile?.utilities, [Validators.required]),
  // fAndB: new FormControl(this.profileDate?.userPersonalProfile?.fAndB, [Validators.required]),
  // householdAssistance: new FormControl(this.profileDate?.userPersonalProfile?.householdAssistance, [Validators.required]),
  // expatFees: new FormControl(this.profileDate?.userPersonalProfile?.expatFees, [Validators.required]),
  // transportation: new FormControl(this.profileDate?.userPersonalProfile?.transportation, [Validators.required]),
  // communication: new FormControl(this.profileDate?.userPersonalProfile?.communication, [Validators.required]),
  // tuitionFees: new FormControl(this.profileDate?.userPersonalProfile?.tuitionFees, [Validators.required]),
  // healthcareAndInsurance: new FormControl(this.profileDate?.userPersonalProfile?.healthcareAndInsurance, [Validators.required]),
  // isSave: new FormControl(true, [Validators.required])
}




  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  ngOnInit(): void {
    this.initForm();
    this.profile.emitChanges.subscribe(res => {
      console.log(res);

      if(res == 'financial'){

        this.update();
      }
    })
    if (this.item == 'true') {
      this.ApiService.subscribe().subscribe(value => {
        this.loginFormValidationFlag = this.formGroup.invalid ? true : false;
        if (this.formGroup.invalid) {
          this.ApiService.validObject.financial.valid = false;
        } else {
          this.ApiService.validObject.financial.valid = true;
        }
      })
      // this.formGroup.valueChanges.subscribe(change => {
      //   this.ApiService.validObject.financial.change = true;
      //   console.log(this.formGroup)
      // })

    }
    this.ApiService.formCompletion = this.formGroup.invalid == false ? this.ApiService.formCompletion + 1 : this.ApiService.formCompletion;

this.obligDetails = [


      // {
      //   name: 'Remaining Months',value:this.profileDate?.userPersonalProfile?.remainingMonths
      // },
    {
      name: 'Number of Dependents',value:this.profileDate?.userPersonalProfile?.dependencies
    },
    {
      name: 'DBR',value:this.profileDate?.userPersonalProfile?.currentDBR
    }

];
this.obligDetailsForm =
  [
    {
      name: 'Monthly Deductions',formControl:'monthlyDeductions',value:this.profileDate?.userPersonalProfile?.monthlyDeductions
    },{
        name: 'Total Credit Cards Limits',formControl:'otherCardsLimits',value:this.profileDate?.userPersonalProfile?.otherCardsLimits
      },
      {
        name: 'Date of First Payment',formControl:'DateOfFirstPayment',value:this.profileDate?.userPersonalProfile?.monthlyDeductions,type:'date'
      },{
          name: 'Date of Last Payment',formControl:'DateOfLastPayment',value:this.profileDate?.userPersonalProfile?.otherCardsLimits,type:'date'
        }
    ];
    let formObjecObligations = [
      // id: new FormControl(this.profileDate?.userPersonalEmployement?.id),
       {
        formControl:'userID',
        isEditable:false,
        visible:false,
        labelName:'',
        selectMenu:false
      },
       {
        formControl:'monthlyDeductions',
        isEditable:true,
        visible:true,
        labelName:'Total Monthly Deductions',
        selectMenu:false

      },
      {
        formControl:'otherCardsLimits',
        isEditable:true,
        visible:true,
        labelName:'Total Credit Cards Limits',
        selectMenu:false

      },
      {
        formControl:'RemainingMonths',
        isEditable:true,
        visible:true,
        labelName:'Remaining Months',
        selectMenu:false

      },
      {
        formControl:'currentDBR',
        isEditable:false,
        visible:true,
        labelName:'Debt Burden Ration (DBR)',
        selectMenu:false

      },
    ]

    this.formObjetRespon = [

      {
        formControl:'HousingAndRent',
        labelName:'Housing and Rent',
        selectMenu:false,

      },
       {
        formControl:'HousingAndRent',
        labelName:'utilities',
        selectMenu:false
      },
      {
        formControl:'Food',
        labelName:'Food',
        selectMenu:false
      },
      {
        formControl:'householdAssistance',
        labelName:'Household Assistance',
        selectMenu:false
      },
      {
        formControl:'Education',
        labelName:'Education Assistance',
        selectMenu:false
      },
      {
        formControl:'transportation',
        labelName:'Transportation',
        selectMenu:false
      },
      {
        formControl:'Healthcare',
        labelName:'Healthcare',
        selectMenu:false
      },
      {
        formControl:'Insurance',
        labelName:'HealtInsurancehcare',
        selectMenu:false
      },
      {
        formControl:'OtherLiabilities',
        labelName:'Other Liabilities',
        selectMenu:false
      },
      // {
      //   formControl:'TotalResponsibleLending',
      //   labelName:'Total Responsable Lending',
      //   selectMenu:false
      // },
      // expatFees: new FormControl(this.profileDate?.userPersonalProfile?.expatFees, [Validators.required]),
      // communication: new FormControl(this.profileDate?.userPersonalProfile?.communication, [Validators.required]),
      // tuitionFees: new FormControl(this.profileDate?.userPersonalProfile?.tuitionFees, [Validators.required]),
      // OtherLiabilities: new FormControl(this.profileDate?.userPersonalProfile?.dependencies, [Validators.required]),
      // TotalResponsibleLending: new FormControl(this.profileDate?.userPersonalProfile?.healthcareAndInsurance, [Validators.required]),
    ]
    // setTimeout(() => {

      // this.formDataObligations = {
      //   formData:formObjecObligations,
      //   formGroup: this.formGroup,
      //   // formLabels:Object.keys(formObjecObligations)
      // }
      // this.formDataRespo = {
      //   formData:formObjetRespon,
      //   formGroup: this.formGroup,
      //   // formLabels:Object.keys(formObjecObligations)
      // }
      // console.log(this.formDat)
    // }, 0);
  }

  ngAfterViewInit(){

  }
  formDat
  private initForm() {
    console.log('this.profileDate?.userPersonalEmployement :>> ', this.profileDate?.userPersonalEmployement);
    this.formGroup = new FormGroup({
      // id: new FormControl(this.profileDate?.userPersonalEmployement?.id),
      userID: new FormControl(this.profileDate?.id),
      monthlyDeductions: new FormControl(this.profileDate?.userPersonalProfile?.monthlyDeductions, [Validators.required]),
      otherCardsLimits: new FormControl(this.profileDate?.userPersonalProfile?.otherCardsLimits,[Validators.required]),
      // mon
      // monthlyExtraExpences: new FormControl(this.profileDate?.userPersonalProfile?.monthlyExtraExpences, [Validators.required]),
      CurrentDBR: new FormControl(this.profileDate?.userPersonalProfile?.currentDBR, [Validators.required]),
      RemainingMonths:new FormControl(this.profileDate?.userPersonalProfile?.remainingMonths, [Validators.required]),
  // dependencies: new FormControl(this.profileDate?.userPersonalProfile?.dependencies, [Validators.required]),
  TransportationType:new FormControl(this.profileDate?.userPersonalProfile?.TransportationType, [Validators.required]),
      HousingAndRent: new FormControl(this.profileDate?.userPersonalProfile?.housingAndRent, [Validators.required]),
      utilities: new FormControl(this.profileDate?.userPersonalProfile?.utilities, [Validators.required]),
      Food: new FormControl(this.profileDate?.userPersonalProfile?.food, [Validators.required]),
      householdAssistance: new FormControl(this.profileDate?.userPersonalProfile?.householdAssistance, [Validators.required]),
      Education:new FormControl(this.profileDate?.userPersonalProfile?.education, [Validators.required]),
      // expatFees: new FormControl(this.profileDate?.userPersonalProfile?.expatFees, [Validators.required]),
      transportation: new FormControl(this.profileDate?.userPersonalProfile?.transportation, [Validators.required]),
      // communication: new FormControl(this.profileDate?.userPersonalProfile?.communication, [Validators.required]),
      // tuitionFees: new FormControl(this.profileDate?.userPersonalProfile?.tuitionFees, [Validators.required]),
      Healthcare: new FormControl(this.profileDate?.userPersonalProfile?.healthcare, [Validators.required]),
      Insurance: new FormControl(this.profileDate?.userPersonalProfile?.insurance, [Validators.required]),
      TotalResponsibleLending: new FormControl(this.profileDate?.userPersonalProfile?.totalResponsibleLending),
      OtherLiabilities: new FormControl(this.profileDate?.userPersonalProfile?.otherLiabilities, [Validators.required]),
      // OtherLiabilities: new FormControl(this.profileDate?.userPersonalProfile?.dependencies, [Validators.required]),
      DateOfFirstPayment:new FormControl(this.profileDate?.userPersonalProfile?.DateOfFirstPayment),
      DateOfLastPayment:new FormControl(this.profileDate?.userPersonalProfile?.DateOfLastPayment),


      // currentDBR:new FormControl(this.profileDate?.userPersonalProfile?.currentDBR, [Validators.required]),
      isSave: new FormControl(true, [Validators.required])
    });

  }
  update() {
    console.log(this.formGroup.value)
    let payload ={
      "userID":this.formGroup.value.userID,
      "monthlyDeductions":this.formGroup.value.monthlyDeductions,
      "RemainingMonths":this.profileDate.userPersonalProfile.remainingMonths,
      "otherCardsLimits":this.formGroup.value.otherCardsLimits,
      "TotalMonthlyDeductions":this.formGroup.value.monthlyDeductions,
      "CurrentDBR":this.formGroup.value.CurrentDBR,
      "HousingAndRent":this.formGroup.value.HousingAndRent,
      "utilities":this.formGroup.value.utilities,
      "Food":this.formGroup.value.Food,
      "householdAssistance":this.formGroup.value.householdAssistance,
      "Education":this.formGroup.value.Education,
      "transportation":this.formGroup.value.transportation,
      "Healthcare":this.formGroup.value.Healthcare,
      "Insurance":this.formGroup.value.Insurance,
      "OtherLiabilities":this.formGroup.value.OtherLiabilities,
      "TotalResponsibleLending":this.formGroup.value.TotalResponsibleLending,

      "TransportationType":this.formGroup.value.TransportationType,
    "DateOfFirstPayment":this.formGroup.value.DateOfFirstPayment,
    "DateOfLastPayment":this.formGroup.value.DateOfLastPayment,
      "isSave":false,


      // mon
      // monthlyExtraExpences: new FormControl(this.profileDate?.userPersonalProfile?.monthlyExtraExpences, [Validators.required]),
      // CurrentDBR: new FormControl(this.profileDate?.userPersonalProfile?.currentDBR, [Validators.required]),
      // RemainingMonths:new FormControl(this.profileDate?.userPersonalProfile?.remainingMonths, [Validators.required]),
  // dependencies: new FormControl(this.profileDate?.userPersonalProfile?.dependencies, [Validators.required]),


    }
    this.loginFormValidationFlag = this.formGroup.invalid ? true : false;
    if (!this.loginFormValidationFlag) {
      this.submit = true;
      this.profile.updateProfile(payload,"updateFinance").subscribe(value => {
        this.ApiService.refreshData();
        this.submit = false;
        if (this.item !== 'true') this.ApiService.notify("Successfully updated", "success");
      })
    }
  }
  otherLimits(i, e) {
    if(e.target.value){
    this.cardLimits[i].value = parseFloat(e.target.value);
    let totalCardLimit =this.profileDate?.userPersonalProfile?.otherCardsLimits?this.profileDate?.userPersonalProfile?.otherCardsLimits: 0;
    for (let x of this.cardLimits) {
      totalCardLimit = totalCardLimit + x.value;
      console.log(x.value, totalCardLimit)
    }
    this.formGroup.patchValue({ otherCardsLimits: totalCardLimit });
    let data = {
      "NetSalary": this.formGroup.value.otherCardsLimits ? this.formGroup.value.otherCardsLimits : 0,
      "OtherCardsLimits": this.formGroup.value.otherCardsLimits,
      "MonthlyObligations": this.formGroup.value.monthlyDeductions,
      "ResponsibleLending": this.formGroup.value.monthlyExtraExpences,
      "UserId": this.profileDate?.userPersonalProfile?.userID
    }
    this.submit = true;
    this.ApiService.DBR(data).subscribe(val => {
      this.formGroup.patchValue({
        currentDBR: val.dbr
      })
      this.submit = false;
    }, err => {
      this.ApiService.notify("something went wrong", 'error');
      this.submit = false;
      this.calci = false;
    })
  }
  }
  addCard() {
    this.cardLimits.push({ value: 0 })
  }
  removeCard(i) {
    this.cardLimits.splice(i, 1);
    let totalCardLimit = this.profileDate?.userPersonalProfile?.otherCardsLimits?this.profileDate?.userPersonalProfile?.otherCardsLimits:0;
    for (let x of this.cardLimits) {
      totalCardLimit = totalCardLimit + x.value;
      console.log(x.value, totalCardLimit)
    }
    this.formGroup.patchValue({ otherCardsLimits: totalCardLimit });
  }
  valueSender(control: string) {
    if (this.formGroup.controls[control].value && this.formGroup.controls[control].value !== "") {
      return parseFloat(this.formGroup.controls[control].value);
    } else {
      return 0;
    }
  }
  obligationsCalculation() {
    let totalObligation = 0;
    let arr = [
      "housing",
      "fAndB",
      "expatFees",
      "communication",
      "healthcareAndInsurance",
      "utilities",
      "householdAssistance",
      "transportation",
      "tuitionFees",
    ]
    for (let a of arr) {
      totalObligation = totalObligation + this.valueSender(a);
    }
    this.formGroup.patchValue({ monthlyExtraExpences: totalObligation })
  }
}
