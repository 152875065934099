import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProfileComponent } from './features/profile/profile.component';
import { LandingPageCardsListComponent } from './landing-page-cards-list/landing-page-cards-list.component';

import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NotFound404Component } from './not-found404/not-found404.component';
import { RouteResolver } from './route.resolver';
import { MainViewComponent } from './main-view/main-view.component';
import { AuthGuard } from './core/routingGuard/auth.guard';
import { ProfileUserViewComponent } from './profile-user-view/profile-user-view.component';
import { UserHomePageComponent } from './landig-page-logged-in-user/user-homepage.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'creditly/LandingPage',
    pathMatch: 'full',
  },
  {
    path: 'creditly',
    redirectTo: 'creditly/LandingPage',
    pathMatch: 'full',
  },
  
  {
    path: 'creditly',
    component: MainViewComponent,
    children: [
      {
        path:'userHomepage',
        component:UserHomePageComponent
      },
      {
        path: '',
        loadChildren: () =>
          import(`./features/products/creditly-b2b.module`).then(
            (m) => m.CreditlyB2bModule
          )

        // resolve: [RouteResolver]
      },
      {
        path: 'LandingPage',
        loadChildren: () =>
          import(`./features/home-page/co.module`).then((m) => m.CoModule),
      },
      {
        path: 'about',
        loadChildren: () =>
          import(`./features/about/about.module`).then((m) => m.AboutModule),

        // resolve: [RouteResolver]
      },
      {
        path: 'userProfile',
        component: ProfileComponent,
        canActivate: [AuthGuard],
      },
      {
        path: '',
        loadChildren: () =>
          import(`./features/apply-process/apply-process.module`).then(
            (m) => m.ApplyProcessModule
          )

        // resolve: [RouteResolver]
      },
      {
        path: 'userProfile',
        loadChildren: () =>
          import(`./features/profile/profile.module`).then(
            (m) => m.ProfileModule
          ),
      }
    ]
  },
  {
    path: '',
    component: ProfileUserViewComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import(`./core/authntication/authntication.module`).then(
            (module) => module.AuthnticationModule
          ),
        // resolve: [RouteResolver]
      }


    ],
  },

  { path: '**', component: NotFound404Component },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      onSameUrlNavigation: 'reload',
    }),
    NgxSliderModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
