
<style type="text/css">
  .fa, .far, .fas, .fa-thin,.fab,.fal{
    font-family: "Font Awesome 5 Free"!important;
}
.fa-brands, .fab {
    font-family: "Font Awesome 6 Brands"!important;
    
}
</style>
<div _ngcontent-ald-c225="" class="backgroundMask2" ng-reflect-ng-class="[object Object]"></div>

<div class="container mt-5 mb-5">
  <div class="row">
    <div class="col mb-4"><br><br><br><br>
      <h4>Complete your applications</h4>
    <div class="alert alert-primary" role="alert">
  A simple primary alert—check it out!
</div>
    </div>
  </div>
    <div class="row row-cols-1 row-cols-md-3 g-4">
        <div class="col">
          <div class="card h-100 p-4">
            <img src="../../../assets/images/approved-card.png" class="card-img-top img-fluid" alt="...">
            <div class="card-body p-0">
                <h5 class="mt-3">Mazeed Platinum Credit Card</h5>
                <div class="row col-12 mb-3 credit-icon"><i class="fa-light fa-credit-card-blank"></i>Emirates NBD</div>
                <div class="row col-12 mb-3">
                    <div class="col-8 px-0 text-secondary"><strong><i class="fa-regular fa-credit-card"></i> Credit Cards</strong></div> 
                    <div class="alert alert-danger p-1 mt-2 px-2" role="alert">
                      Incompleted application form
                    </div>
                </div>
               
            </div>
            <div class="card-footer bg-white pt-4 p-0">
              <button type="button" class="btn btn-success application-icon d-block w-100 p-2">
                Finish application <i class="fa-solid fa-arrow-right-from-bracket"></i>
              </button>
              <button type="button" class="btn btn-outline-success application-icon d-block w-100 p-2 mt-3">
                Cancel
            </button>
          </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100 p-4">
            <img src="../../../assets/images/approved-card.png" class="card-img-top img-fluid" alt="...">
            <div class="card-body p-0">
                <h5 class="mt-3">Mazeed Platinum Credit Card</h5>
                <div class="row col-12 mb-3 credit-icon"><i class="fa-light fa-credit-card-blank"></i>Emirates NBD</div>
                <div class="row col-12 mb-3">
                    <div class="col-8 px-0 text-secondary"><strong><i class="fa-regular fa-credit-card"></i> Credit Cards</strong></div> 
                    <div class="alert alert-danger text-danger p-1 mt-2 px-2" role="alert"  data-bs-toggle="modal" data-bs-target="#exampleModal">
                      Missing documents
                    </div>
                    <!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Are you sure you want to cancel the request?</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form>
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">Cancelation reason<span class="text-danger">*</span></label>
            <!-- <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Write the reason to cancel" aria-describedby="emailHelp"> -->
            <textarea class="form-control" id="exampleInputEmail1" placeholder="Write the reason to cancel" aria-describedby="emailHelp"></textarea>
            <div id="emailHelp" class="form-text"></div>
          </div>
          </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn dismissBtn" data-bs-dismiss="modal">Dismiss</button>
        <button type="button" class="btn btn-danger"><mat-icon>block</mat-icon>Confirm</button>
      </div>
    </div>
  </div>
</div>
                </div>
               
            </div>
            <div class="card-footer bg-white pt-4 p-0">
              <button type="button" class="btn btn-success application-icon d-block w-100 p-2">
                Finish application <i class="fa-solid fa-arrow-right-from-bracket"></i>
              </button>
              <button type="button" class="btn btn-outline-success application-icon d-block w-100 p-2 mt-3">
                Cancel
            </button>
          </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100 p-4">
            <img src="../../../assets/images/approved-card.png" class="card-img-top img-fluid" alt="...">
            <div class="card-body p-0">
                <h5 class="mt-3">Mazeed Platinum Credit Card</h5>
                <div class="row col-12 mb-3 credit-icon"><i class="fa-light fa-credit-card-blank"></i>Emirates NBD</div>
                <div class="row col-12 mb-3">
                    <div class="col-8 px-0 text-secondary"><strong><i class="fa-regular fa-credit-card"></i> Credit Cards</strong></div> 
                    <div class="alert alert-danger text-danger p-1 mt-2 px-2" role="alert">
                      Missing documents
                    </div>
                </div>
               
            </div>
            <div class="card-footer bg-white pt-4 p-0">
              <button type="button" class="btn btn-success application-icon d-block w-100 p-2">
                Finish application <i class="fa-solid fa-arrow-right-from-bracket"></i>
              </button>
              <button type="button" class="btn btn-outline-success application-icon d-block w-100 p-2 mt-3">
                Cancel
            </button>
          </div>
          </div>
        </div>
      </div>

      <!--Recommended Products  -->
      <div class="row">
        <div class="col mb-4"><br><br><br><br>
          <h4>Recommended Products </h4>
         
        </div>
      </div>
        <div class="row row-cols-1 row-cols-md-3 g-4">
            <div class="col">
              <div class="card h-100 p-4">
                <img src="../../../assets/images/approved-card.png" class="card-img-top img-fluid" alt="...">
                <div class="card-body p-0">
                    <h5 class="mt-3">Mazeed Platinum Credit Card</h5>
                    <div class="row col-12 mb-3 credit-icon"><i class="fa-light fa-credit-card-blank"></i>Emirates NBD</div>
                    <div class="row col-12 mb-3">
                        <div class="col-8 px-0 text-secondary"><strong><i class="fa-regular fa-credit-card"></i> Credit Cards</strong></div> 
                        <div class="alert alert-danger p-1 mt-2 px-2" role="alert">
                          Incompleted application form
                        </div>
                    </div>
                   
                </div>
                <div class="card-footer bg-white pt-4 p-0">
                   
                  <button type="button" class="btn btn-outline-success application-icon d-block w-100 p-2 mt-3">
                    Learn more
                </button>
              </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100 p-4">
                <img src="../../../assets/images/approved-card.png" class="card-img-top img-fluid" alt="...">
                <div class="card-body p-0">
                    <h5 class="mt-3">Mazeed Platinum Credit Card</h5>
                    <div class="row col-12 mb-3 credit-icon"><i class="fa-light fa-credit-card-blank"></i>Emirates NBD</div>
                    <div class="row col-12 mb-3">
                        <div class="col-8 px-0 text-secondary"><strong><i class="fa-regular fa-credit-card"></i> Credit Cards</strong></div> 
                        <div class="alert alert-danger text-danger p-1 mt-2 px-2" role="alert" >
                          Missing documents
                        </div>
                        
                    </div>
                   
                </div>
                <div class="card-footer bg-white pt-4 p-0">
                  <button type="button" class="btn btn-success application-icon d-block w-100 p-2">
                    Learn more
                </button>
              </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100 p-4">
                <img src="../../../assets/images/approved-card.png" class="card-img-top img-fluid" alt="...">
                <div class="card-body p-0">
                    <h5 class="mt-3">Mazeed Platinum Credit Card</h5>
                    <div class="row col-12 mb-3 credit-icon"><i class="fa-light fa-credit-card-blank"></i>Emirates NBD</div>
                    <div class="row col-12 mb-3">
                        <div class="col-8 px-0 text-secondary"><strong><i class="fa-regular fa-credit-card"></i> Credit Cards</strong></div> 
                        <div class="alert alert-danger text-danger p-1 mt-2 px-2" role="alert">
                          Missing documents
                        </div>
                    </div>
                   
                </div>
                <div class="card-footer bg-white pt-4 p-0">
                  <button type="button" class="btn btn-success application-icon d-block w-100 p-2">
                    Learn more
                </button>
              </div>
              </div>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col text-center">
              <button type="button" class="btn mt-3 btn-success application-icon py-2 px-3">
                See all available credit cards
            </button>
            </div>
          </div>

          <!-- Recently viewed products  -->
          <br><br><br><br>
      <div class="row justify-content-between">
        <div class="col mb-4">
          <h4>Recently viewed products </h4>         
        </div>
        <div class="col mb-4 text-end">
          <button type="button" class="btn btn-outline-success application-icon py-1 px-2 mr-2">
          &gt;
        </button>
        <button type="button" class="btn btn-outline-success application-icon py-1 px-2">
      &lt;
      </button>
        </div>
      </div>
      <!-- slider -->
      <!-- slider -->
      <div class="row"></div>
        <div class="row row-cols-1 row-cols-md-3 g-4">
            <div class="col">
              <div class="card h-100 p-4">
                <img src="../../../assets/images/approved-card.png" class="card-img-top img-fluid" alt="...">
                <div class="card-body p-0">
                    <h5 class="mt-3">Mazeed Platinum Credit Card</h5>
                    <div class="row col-12 mb-3 credit-icon"><i class="fa-light fa-credit-card-blank"></i>Emirates NBD</div>
                    <div class="row col-12 mb-3">
                        <div class="col-8 px-0 text-secondary"><strong><i class="fa-regular fa-credit-card"></i> Credit Cards</strong></div> 
                        <div class="alert alert-danger p-1 mt-2 px-2" role="alert">
                          Incompleted application form
                        </div>
                    </div>
                   
                </div>
                <div class="card-footer bg-white pt-4 p-0">
                   
                  <button type="button" class="btn btn-outline-success application-icon d-block w-100 p-2 mt-3">
                    Learn more
                </button>
              </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100 p-4">
                <img src="../../../assets/images/approved-card.png" class="card-img-top img-fluid" alt="...">
                <div class="card-body p-0">
                    <h5 class="mt-3">Mazeed Platinum Credit Card</h5>
                    <div class="row col-12 mb-3 credit-icon"><i class="fa-light fa-credit-card-blank"></i>Emirates NBD</div>
                    <div class="row col-12 mb-3">
                        <div class="col-8 px-0 text-secondary"><strong><i class="fa-regular fa-credit-card"></i> Credit Cards</strong></div> 
                        <div class="alert alert-danger text-danger p-1 mt-2 px-2" role="alert" >
                          Missing documents
                        </div>
                        
                    </div>
                   
                </div>
                <div class="card-footer bg-white pt-4 p-0">
                  <button type="button" class="btn btn-success application-icon d-block w-100 p-2">
                    Learn more
                </button>
              </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100 p-4">
                <img src="../../../assets/images/approved-card.png" class="card-img-top img-fluid" alt="...">
                <div class="card-body p-0">
                    <h5 class="mt-3">Mazeed Platinum Credit Card</h5>
                    <div class="row col-12 mb-3 credit-icon"><i class="fa-light fa-credit-card-blank"></i>Emirates NBD</div>
                    <div class="row col-12 mb-3">
                        <div class="col-8 px-0 text-secondary"><strong><i class="fa-regular fa-credit-card"></i> Credit Cards</strong></div> 
                        <div class="alert alert-danger text-danger p-1 mt-2 px-2" role="alert">
                          Missing documents
                        </div>
                    </div>
                   
                </div>
                <div class="card-footer bg-white pt-4 p-0">
                  <button type="button" class="btn btn-success application-icon d-block w-100 p-2">
                    Learn more
                </button>
              </div>
              </div>
            </div>
          </div>
           <!-- Recently viewed products  -->
           <br><br><br><br>
           <div class="row justify-content-between">
             <div class="col mb-4">
               <h4>Latest news </h4>         
             </div>
           </div>
           <div class="row">
             <div class="col-sm-6">
               <!-- Card wrapper -->
               <div class="card-wrapper">
              <div class="card mb-3">
                <div class="row g-0">
                  <div class="col-md-2">
                    <img src="../../../assets/images/image 170.png" class="img-fluid rounded-start m-2" alt="...">
                  </div>
                  <div class="col-md-7 pl-2">
                    <div class="card-body p-1"> 
                      <p class="card-text">Your request for <strong>Platinum Credit Card</strong> has been approved</p>
                      
                    </div>
                  </div>
                  <div class="col-md-3 text-end">
                    <p class="card-text m-1 mr-2"><small class="text-muted">16:34<br>
                      Dec 23, 2021</small></p>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="row g-0">
                  <div class="col-md-2">
                    <img src="../../../assets/images/image 170.png" class="img-fluid rounded-start m-2" alt="...">
                  </div>
                  <div class="col-md-7 pl-2">
                    <div class="card-body p-1"> 
                      <p class="card-text">Your request for <strong>Platinum Credit Card</strong> has been approved</p>
                      
                    </div>
                  </div>
                  <div class="col-md-3 text-end">
                    <p class="card-text m-1 mr-2"><small class="text-muted">16:34<br>
                      Dec 23, 2021</small></p>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="row g-0">
                  <div class="col-md-2">
                    <img src="../../../assets/images/image 170.png" class="img-fluid rounded-start m-2" alt="...">
                  </div>
                  <div class="col-md-7 pl-2">
                    <div class="card-body p-1"> 
                      <p class="card-text">Your request for <strong>Platinum Credit Card</strong> has been approved</p>
                      
                    </div>
                  </div>
                  <div class="col-md-3 text-end">
                    <p class="card-text m-1 mr-2"><small class="text-muted">16:34<br>
                      Dec 23, 2021</small></p>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="row g-0">
                  <div class="col-md-2">
                    <img src="../../../assets/images/image 170.png" class="img-fluid rounded-start m-2" alt="...">
                  </div>
                  <div class="col-md-7 pl-2">
                    <div class="card-body p-1"> 
                      <p class="card-text">Your request for <strong>Platinum Credit Card</strong> has been approved</p>
                      
                    </div>
                  </div>
                  <div class="col-md-3 text-end">
                    <p class="card-text m-1 mr-2"><small class="text-muted">16:34<br>
                      Dec 23, 2021</small></p>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="row g-0">
                  <div class="col-md-2">
                    <img src="../../../assets/images/image 170.png" class="img-fluid rounded-start m-2" alt="...">
                  </div>
                  <div class="col-md-7 pl-2">
                    <div class="card-body p-1"> 
                      <p class="card-text">Your request for <strong>Platinum Credit Card</strong> has been approved</p>
                      
                    </div>
                  </div>
                  <div class="col-md-3 text-end">
                    <p class="card-text m-1 mr-2"><small class="text-muted">16:34<br>
                      Dec 23, 2021</small></p>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="row g-0">
                  <div class="col-md-2">
                    <img src="../../../assets/images/image 170.png" class="img-fluid rounded-start m-2" alt="...">
                  </div>
                  <div class="col-md-7 pl-2">
                    <div class="card-body p-1"> 
                      <p class="card-text">Your request for <strong>Platinum Credit Card</strong> has been approved</p>
                      
                    </div>
                  </div>
                  <div class="col-md-3 text-end">
                    <p class="card-text m-1 mr-2"><small class="text-muted">16:34<br>
                      Dec 23, 2021</small></p>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="row g-0">
                  <div class="col-md-2">
                    <img src="../../../assets/images/image 170.png" class="img-fluid rounded-start m-2" alt="...">
                  </div>
                  <div class="col-md-7 pl-2">
                    <div class="card-body p-1"> 
                      <p class="card-text">Your request for <strong>Platinum Credit Card</strong> has been approved</p>
                      
                    </div>
                  </div>
                  <div class="col-md-3 text-end">
                    <p class="card-text m-1 mr-2"><small class="text-muted">16:34<br>
                      Dec 23, 2021</small></p>
                  </div>
                </div>
              </div>
            </div>
            <!-- Card wrapper -->
             </div>
             <div class="col-sm-6">
               <!-- Card wrapper -->
               <div class="frm-wrapper">
              <div class="mb-3">
                <h4>Have some questions? We are here to help!</h4>
                <p>Please select a topic below related to your inquiry. If you don't find what you need, fill out our contact form and we will get back to you shortly.</p>
                <label for="Inquiry" class="form-label">Inquiry Type *</label>
                <input type="text" class="form-control" id="Inquiry" placeholder="Select the type">
              </div>
              <div class="mb-3">
                <label for="Message" class="form-label">Message</label>
                <textarea class="form-control" id="Message" rows="3" placeholder="Add any additional details we can use to help you"></textarea>
              </div>
              <div class="mb-3 text-end">
                
                <button type="submit" class="btn btn-success p-2 mb-3">Submit</button>
              </div>
              </div>
             </div>
           </div>
            </div>