import { Component, OnInit,ChangeDetectorRef,AfterViewInit, HostListener, EventEmitter, Output, ElementRef, ViewChild  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CreditlyServicesService } from 'src/app/creditly-services.service';
import { ProfileService } from '../../shared/services/profile.service';
import html2canvas from 'html2canvas';
import { position } from 'html2canvas/dist/types/css/property-descriptors/position';
import jspdf from 'jspdf';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, AfterViewInit {
  pdfDownload: { productDetails: { title: string; title_ar: string; value: any; value_ar: any; }[]; personalInfo: { title: string; title_ar: string; value: any; value_ar: any; }[]; relativeContactInfo: { title: string; title_ar: string; value: any; value_ar: any; }[]; contactAddress: { title: string; title_ar: string; value: any; value_ar: any; }[]; homeCountryAdd: { title: string; title_ar: string; value: any; value_ar: any; }[]; employmentInfo: { title: string; title_ar: string; value: any; value_ar: any; }[]; incomeInfo: { title: string; title_ar: string; value: any; value_ar: any; }[]; declarations: any; };
  cateSave: string;
  constructor(
    private router: Router,
    public ApiService: CreditlyServicesService,
    private cdr: ChangeDetectorRef, private profile:ProfileService, 
    private activatedRoute : ActivatedRoute,private auth: AuthService
  ) {}

  public currentTab = 'personal';
  profileDate = JSON.parse(localStorage.getItem('loginResponse'));
  userDetails = JSON.parse(localStorage.getItem('loginResponse'));
  slides = [];
  percent: number = 0;
  applyStickyStyles: boolean = false;
  showEnglishLogo: boolean = false;

  testC = `<app-profile-info></app-profile-info>`
  slideConfig = {
    centerPadding: '10px',
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: "<div class='slick-next'></div>",
    prevArrow: "<div class='slick-prev'></div>",
    dots: false,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  getAllDataa(req){
    if(req){
      // this.pdfDownload = pdfDownload(req)
    }
  }
 update(){
   this.profile.emitChanges.next(this.currentTab);
  //  this.cateSave = this.currentTab
  //  console.log(data);


 }
  // public captureScreen() {
  //   var data = document.getElementById('contentToConvert1');
  //   var data2 = document.getElementById('contentToConvert2');
  //   let pdf = new jspdf('p', 'mm', 'a4',true); // A4 size page of PDF

  //   html2canvas(data,).then((canvas) => {
  //     // Few necessary setting options
  //     console.log(canvas);
  //       var context = canvas.getContext("2d");
  //         context.scale(2,2);
  //         context["imageSmoothingEnabled"] = false;
  //         context["mozImageSmoothingEnabled"] = false
  //         context["oImageSmoothingEnabled"] = false
  //         context["webkitImageSmoothingEnabled"] = false
  //         context["msImageSmoothingEnabled"] = false
  //         var ctx = canvas.getContext('2d');
  //         ctx.drawImage(canvas, 0, 0, 750, 1050);
  //         var dataURL = canvas.toDataURL('image/png');
  //     var imgWidth = 100;
  //     var pageHeight = 295;
  //     var imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     var heightLeft = imgHeight;

  //     const contentDataURL = canvas.toDataURL('image/png');
  //     var position = 0;
  //     pdf.addImage(dataURL, 'PNG', 0, position, imgWidth, imgHeight);


  //   });
  //   html2canvas(data2).then(can=>{
  //     var imgWidth = 210;
  //     var pageHeight = 295;
  //     var imgHeight = (can.height * imgWidth) / can.width;
  //     var heightLeft = imgHeight;
  //     var position = 0;
  //     // data2.height() + 100
  //     const contentDataURL2 = can.toDataURL('image/png');
  //     pdf.addPage()
  //   pdf.addImage(contentDataURL2, 'PNG', 0,position, imgWidth, imgHeight);
  //   pdf.save('aplllication.pdf'); // Generated PDF
  // })
  // }

  public captureScreen(req) {
    var data = document.getElementById('contentToConvert1');
    var data2 = document.getElementById('contentToConvert2');
    let pdf = new jspdf('p', 'mm', 'a4',true); // A4 size page of PDF
    var width = pdf.internal.pageSize.getWidth();
    var height = pdf.internal.pageSize.getHeight();
    html2canvas(data,{scale: 2}).then((canvas) => {
      // Few necessary setting options
      console.log(canvas);

      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png',1);
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, width, height);


    });
    html2canvas(data2,{scale: 3}).then(can=>{
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (can.height * imgWidth) / can.width;
      var heightLeft = imgHeight;
      var position = 0;
      // data2.height() + 100
      const contentDataURL2 = can.toDataURL('image/png',1);
      pdf.addPage()
    pdf.addImage(contentDataURL2, 'PNG', 0,position, imgWidth, height);
    pdf.save('aplllication.pdf'); // Generated PDF
  })
  }
  ngAfterViewInit() {
    console.log(this.ApiService.formCompletion);

    this.percent = this.ApiService.formCompletion * 10 + 20;
    this.cdr.detectChanges();
  }
  calculate() {}
  slickInit(e) {
    console.log('slick initialized');
  }

  breakpoint(e) {
    console.log('breakpoint');
  }

  afterChange(e) {
    console.log('afterChange');
  }

  beforeChange(e) {
    console.log('beforeChange');
  }
  headName ="Personal Information";
  routeparams=false;
  ngOnInit(): void {
    this.profileDate = JSON.parse(localStorage.getItem('loginResponse'));
    var section =this.activatedRoute.snapshot.queryParams.section;
    if(section ) {
      if(section == 'request') {
      this.currentTab = 'pending';
      this.headName = 'Pending Requests';
      this.routeparams = true;
      }

      if(section == 'support') {
        this.currentTab = 'support';
        this.headName = 'Support';
        this.routeparams = true;
      }
    }

    this.ApiService.formCompletion = 0;
    this.ApiService.finding = true;
    this.profile.getRequests().subscribe(
      (val) => {
        console.log('val :>> ', val);
        localStorage.setItem(
          'productRequest',
          JSON.stringify(val.userRequests)
          );
          this.getAllDataa(val?.userRequests?val?.userRequests[0]:'')
        this.ApiService.finding = false;
        this.ApiService.request.next(true);
      },
      (err) => {
        this.ApiService.finding = false;
      }
    );
  }

  public selectLanguage(type): void {
    console.log('event language', type);
    if (type == 'AR') {
      this.showEnglishLogo = true;
    } else {
      this.showEnglishLogo = false;
    }
  }

  logout(): void {
    let masterData = localStorage.getItem('masterData');
    let token = localStorage.getItem('token');
    localStorage.clear();
    localStorage.setItem('masterData', masterData);
    localStorage.setItem('token', token);
    this.ApiService.to = 'Login';
     this.auth.logOut().subscribe((res)=>{
      this.router.navigateByUrl('/login');
    });
    
  }
}
