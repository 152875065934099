<link rel="stylesheet" href="https://site-assets.fontawesome.com/releases/v6.0.0/css/all.css">
    <div class="container px-0">
    <div class="row">
        <div class="col-12">
            <div class="media">
                <div class="col-4">
                    <img src="../../../assets/images/approved-card.png" class="img-fluid" alt="...">
                </div>
                <div class="col-8">
                    <div class="row col-12 px-0">
                        <div class="col-8 px-0">
                            <div class="media-body">
                                <h5 class="mt-0">Mazeed Platinum Credit Card</h5>
                                <div class="row col-12 mb-3 credit-icon"><i class="fa-light fa-credit-card-blank"></i>Credit Cards</div>
                                <div class="row col-12 mb-3">
                                    <div class="col-8 px-0"><strong>Req Date:  </strong>Dec 23, 2021</div>
                                    <div class="col-4 px-0"><strong>Req #  </strong>17</div>
                                </div>
                            </div>  
                        </div>
                        <div class="col-4 d-flex align-items-start justify-content-end px-0">
                            <button type="button" class="btn btn-outline-success application-icon">
                                <i class="fa-light fa-arrow-down-to-bracket"></i>Application
                            </button>
                        </div>
                    </div>

                    <div class="row col-12 px-0">
                        <div class="col-8 px-0">
                            <div class="media-body">
                                <div class="row col-12">
                                    <button type="button" class="btn btn-danger">Not Eligible Credit History</button>
                                </div>
                            </div>  
                        </div>
                        <div class="col-4 d-flex align-items-start justify-content-end px-0">
                            <button type="button" class="btn btn-success">See all products</button>
                        </div>
                    </div>


                </div>            
            </div>
        </div>
    </div>
</div>