<div class="container-fluid pl-0">
    <div class="row align-items-center">
        <!-- <app-profile-form-shared [loginFormValidationFlag]='loginFormValidationFlag' *ngIf='formDataPersonal' (newItemEvent)='update()' [formComingData]='formDataPersonal'></app-profile-form-shared> -->
        <div class="edit-sec">

            <form [formGroup]='personalForm'>

                <div class="from-group">
                    <label class="form-label-n">
               Title
              </label>
                    <div class="radio-contain d-flex align-items-center">

                        <div class="radio-group d-flex align-items-center" (click)='selectTitle(tit)' *ngFor="let tit of titles">
                            <div class="radio-field" (click)='selectTitle(tit)' [ngClass]="{'checked':tit.checked}">
                                <input formControlName='Title' [id]='tit.title' name="Title" type="radio" [checked]='tit.checked' (click)='selectTitle(tit)'>
                            </div>
                            <label class="m-0" (click)='selectTitle(tit)' [for]="tit.title">{{tit.title}}</label>
                        </div>
                    </div>

                </div>

                <div class="form-group-n">
                    <label class="form-label-n d-block">
                    Education
                  </label>
                    <select class="select-form-input" formControlName='educationLevel'>
                    <option [value]="ed.id" *ngFor='let ed of educationsDegrees'>{{ed.name}}</option>
                  </select>
                </div>

            </form>
        </div>







    </div>
    <hr class="profile-line">
    <div class="row">
        <div class="col-md-3" *ngFor='let nam of nameDetails'>
            <div class="person-details">
                <p class="title-label">
                    {{nam.name}}
                </p>
                <p class="title-value">
                    {{nam.value && nam.value !==''?nam.value:"NA"}}
                </p>
            </div>
        </div>
    </div>
    <hr class="profile-line">
    <div class="row">
        <div class="col-md-3" *ngFor='let nam of statusDetails'>
            <div class="person-details">
                <p class="title-label">
                    {{nam.name}}
                </p>
                <p class="title-value">
                    {{nam.value}}
                </p>
            </div>
        </div>
    </div>
    <hr class="profile-line">
    <div class="row">
        <div [ngClass]="{'col-md-4':i>=2,'col-md-3':i<2}" *ngFor='let nam of birthDetails let i=index;'>
            <div class="person-details">
                <p class="title-label">
                    {{nam.name}}
                </p>
                <p class="title-value">
                    {{nam.value}}
                </p>
            </div>
        </div>
    </div>

    <hr class="profile-line">
    <div class="row">
        <div [ngClass]="{'col-md-4':i>=1,'col-md-3':i<1}" *ngFor='let nam of expireDetails let i=index;'>
            <div class="person-details">
                <p class="title-label">
                    {{nam.name}}
                </p>
                <p class="title-value">
                    {{nam.value}}
                </p>
            </div>
        </div>
    </div>
</div>
