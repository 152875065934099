import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  baseUrl = environment.baseUrl;
  mainBaseUrl = environment.mainBaseUrl;
  emitChanges = new Subject()
  constructor(private http: HttpClient) { }

  updateProfile(data,catogery): Observable<any> {
    // let headers = this.headers.set('Token', localStorage.getItem('sessionToken') ? localStorage.getItem('sessionToken') : localStorage.getItem('token'));

 return this.http.post(`${this.mainBaseUrl}user/profile/${catogery}`, data).pipe(tap(res=>{
   console.log(res);
  //  if(!res?.status || !res){

  // }else{

    localStorage.setItem('loginResponse',JSON.stringify(res))
  // }

 }));
}
  // profileContact(data): Observable<any> {

  //   return this.http.post(`${this.mainBaseUrl}user/profile/updateContact`, data);
  // }
  // profileEmployment(data): Observable<any> {

  //   return this.http.post(`${this.mainBaseUrl}user/profile/updateEmployment`, data);
  // }
  // profileBank(data): Observable<any> {

  //   return this.http.post(`${this.mainBaseUrl}user/profile/updateBank`, data);
  // }
  // profileFinance(data): Observable<any> {

  //   return this.http.post(`${this.mainBaseUrl}user/profile/updateFinance`, data);
  // }

  getRequests(): Observable<any> {
    return this.http.get(`${this.mainBaseUrl}user/request`);
  }
  getRecomndedProducts(): Observable<any> {
    return this.http.get(`${this.mainBaseUrl}user/dashboard`);
  }

  UpdateReqStatus(data): Observable<any> {
  //   let data={
  //     "UserRequestID":id,
  //     "UserRequestStatusCode":107,
  //     "Comment":null
  // }
    return this.http.post(`${this.mainBaseUrl}user/request/statusupdate`, data);
  }
}
