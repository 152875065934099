import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'app-user-homepage',
    templateUrl: './user-homepage.component.html',
    styleUrls: ['./user-homepage.component.scss'],
  })
  export class UserHomePageComponent implements OnInit {
      ngOnInit(): void {
          
      }
  }