<!-- <p>login works!</p> -->

<section id="login_page">
    <!-- <div class=""> -->
    <div class="background_shapes">
        <div class="top_background_shape">

        </div>
        <div class="bottom_background_shape">

        </div>
    </div>
    <!-- <div class="container"> -->
    <div class="login_card">
        <div class="creditly_logo" routerLink="/">
            <a style="cursor: pointer;">
                <img src="../../assets/images/header-images/header-main-logo.png" alt="">
            </a>
            <h2>Creditly</h2>
        </div>
        <div class="card custom-card">
            <div class="card-body">
                <div class="main_heading">
                    <img src="/assets/images/error-icon.png">
                    <h4>Sorry !</h4>
                    <p> We could not find the page you are looking for</p>
                </div>

                <div class="goback-home mt-5 mb-5">
                    <div class="goback">
                        <a (click)="backroute()">
                            < Go back</a>
                    </div>
                    <div class="home-pae">
                        <a routerLink="/"><i class="fa fa-home"></i> Home page</a>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="terms_conditions">
        <a>Privacy & Terms</a>
    </div>

</section>