<div [ngClass]="{'accordion-row': !userProfile, 'accordion-row-profile': userProfile}" class="">
    <div class="container" [ngClass]="{'p-0': userProfile}">
        <p *ngIf="!userProfile" class="text-c">Complete your profile</p>
        <div class="row">
            <div [ngClass]="{'col-md-12': !userProfile, 'wide-from': userProfile}">
                <div class="accordion bank-ac-accordion" id="accordionExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                            <a class="accordion-button" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span><strong>Personal details</strong></span>
                                <span><img src="assets/images/main_images/np_arrow.png"
                                        width="12px" /></span>
                            </a>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div class="accordion-body px-4 py-4">
                                <!-- <div class="container">
                                    <div class="row">
                                        <div class="col-md-6 col-12 details-row mb-3">
                                            <div class="">
                                                <p class="opacity">Arabic Name</p>
                                                <p><b>Test</b></p>
                                            </div>
                                            <div>
                                                <p class="opacity">Date of Birth</p>
                                                <p><b>13 April 2021</b></p>
                                            </div>
                                            <div>
                                                <p class="opacity">ID Number</p>
                                                <p><b>101010</b></p>
                                            </div>
                                            <div>
                                                <p class="opacity">Nationality</p>
                                                <p><b>Saudi</b></p>
                                            </div>
                                            <div>
                                                <p class="opacity">Martial Status</p>
                                                <p><b>Married</b></p>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-12 details-row">
                                            <div>
                                                <p class="opacity">English Name</p>
                                                <p><b>Test Name</b></p>
                                            </div>
                                            <div>
                                                <p class="opacity">Gender</p>
                                                <p><b>Male</b></p>
                                            </div>
                                            <div>
                                                <p class="opacity">Expiration Date</p>
                                                <p><b>20/10/2021</b></p>
                                            </div>
                                            <div>
                                                <p class="opacity">Place of Birth</p>
                                                <p><b>Riyadh</b></p>
                                            </div>
                                            <div>
                                                <div class="form-group">
                                                    <label class="form-label" for="">Mobile
                                                            number</label>
                                                    <input type="text" class="form-control" placeholder="Enter mobile number" value="0542400099">
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                <app-personal-details></app-personal-details>


                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                            <a class="accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                <span><strong>Contact</strong></span>
                                <span><img src="assets/images/main_images/np_arrow.png"
                                        width="12px" /></span>
                            </a>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                            <div class="accordion-body px-4 py-4">
                                <!-- <div class="container">
                                    <div class="row">

                                        <div class="col-md-12 col-12 contact-row">
                                            <div class="row">
                                                <div class="col-md-5">
                                                    <p class="opacity">Mobile Number</p>
                                                    <p><b>0542400099</b></p>
                                                </div>
                                                <div class="col-md-2"></div>
                                                <div class="col-md-5">
                                                    <div class="form-group">
                                                        <label class="form-label" for="">Email</label>
                                                        <input type="text" class="form-control" placeholder="Enter Email" value="Saja@brave.net">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-12 col-12 contact-row">
                                            <div class="row">
                                                <div class="col-md-5 col-12">
                                                    <div class="form-group">
                                                        <label class="form-label" for="">National
                                                                Address</label>
                                                        <input type="text" class="form-control" placeholder="2922 Anas lbn Malik" value="2922 Anas lbn Malik">
                                                    </div>
                                                </div>
                                                <div class="col-md-2"></div>
                                                <div class="col-md-5 col-12">

                                                    <div class="form-group select-row">
                                                        <label class="form-label" for="">Type of
                                                                Residence</label>
                                                        <select class="form-select" aria-label="Default select example">
                                                                <option selected>Select your choice
                                                                </option>
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                                <option value="3">Three</option>
                                                            </select>
                                                    </div>


                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div> -->
                                <app-contact-info></app-contact-info>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                            <a class="accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <span><strong>Employment details</strong></span>
                                <span><img src="assets/images/main_images/np_arrow.png"
                                        width="12px" /></span>
                            </a>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                            <div class="accordion-body px-4 py-4">
                                <!-- <div class="container">
                                    <div class="row">
                                        <div class="col-md-5 col-12 details-row mb-2">
                                            <div>
                                                <div class="form-group">
                                                    <label class="form-label" for="">Basic
                                                            salary</label>
                                                    <input type="text" class="form-control" placeholder="Enter mobile number" value="SAR 17,345.55">
                                                </div>
                                            </div>
                                            <div>
                                                <div class="form-group">
                                                    <label class="form-label" for="">Other
                                                            allowances</label>
                                                    <input type="text" class="form-control" placeholder="Enter mobile number" value="SAR 17,345.55">
                                                </div>
                                            </div>
                                            <div>
                                                <p class="">Net salary</p>
                                                <p>SAR <b>33,345.55</b></p>
                                            </div>
                                            <div>
                                                <div class="form-group">
                                                    <label class="form-label" for="">Other
                                                            income</label>
                                                    <input type="text" class="form-control" placeholder="Enter mobile number" value="SAR 0">
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-md-1"></div>
                                        <div class="col-md-5 col-12 details-row mb-2">
                                            <div>
                                                <div class="form-group">
                                                    <label class="form-label" for="">Housing
                                                            allowances</label>
                                                    <input type="text" class="form-control" placeholder="Enter mobile number" value="SAR 33,345.55">
                                                </div>
                                            </div>
                                            <div>
                                                <p class="opacity">Gross salary</p>
                                                <p><b>SAR 35,345.55</b></p>
                                            </div>
                                            <div>
                                                <p class="opacity">Primary source of income</p>
                                                <p><b>Salary</b></p>
                                            </div>

                                            <div>
                                                <div class="form-group">
                                                    <label class="form-label" for="">Source of other
                                                            income</label>
                                                    <input type="text" class="form-control" placeholder="Enter mobile number" value="Business">
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 mt-5">
                                            <p class="mb-2"><b>Employment details</b></p>
                                        </div>
                                        <div class="col-md-6 col-12 details-row mb-3">
                                            <div>
                                                <p class="opacity">Occupation</p>
                                                <p><b>Pilot</b></p>
                                            </div>
                                            <div>
                                                <p class="opacity">Job sector</p>
                                                <p><b>private</b></p>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-12 details-row">
                                            <div>
                                                <p class="opacity">Total years of service</p>
                                                <p><b>26</b></p>
                                            </div>
                                            <div>
                                                <p class="opacity">Employer Name</p>
                                                <p><b>Company X</b></p>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                <app-employment-details></app-employment-details>

                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingFour">
                            <a class="accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <span><strong>Financial details</strong></span>
                                <span><img src="assets/images/main_images/np_arrow.png"
                                        width="12px" /></span>
                            </a>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                            <div class="accordion-body px-4 py-4">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-5 finance-row">
                                                    <div>
                                                        <div class="form-group">
                                                            <label class="form-label" for="">Total
                                                                    monthly
                                                                    deductions</label>
                                                            <input type="text" class="form-control" placeholder="Enter monthly" value="SAR 7,345.55">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-1"></div>
                                                <div class="col-md-5 finance-row">
                                                    <div>
                                                        <div class="form-group">
                                                            <label class="form-label" for="">Total
                                                                    monthly
                                                                    Obligations</label>
                                                            <input type="text" class="form-control" placeholder="Enter mobile number" value="SAR 4390">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- <div class="col-md-12">
                                            <p class="">Credit cards</p>
                                        </div>
                                        <div class="col-md-12 mb-3">
                                            <div class="row color-section">
                                                <div class="col-md-5 details-row">
                                                    <div>
                                                            <div class="form-group">
                                                                <label class="form-label" for="">Card
                                                                    Name</label>
                                                                <input type="text" class="form-control"
                                                                    placeholder="Enter card name"
                                                                    value="Card #1">
                                                            </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-1"></div>
                                                <div class="col-md-5 details-row">
                                                    <div>
                                                            <div class="form-group">
                                                                <label class="form-label" for="">Card
                                                                    limit</label>
                                                                <input type="text" class="form-control"
                                                                    placeholder="Enter mobile number"
                                                                    value="SAR 4390">
                                                            </div>
                                                    </div>
                                                    <button
                                                        class="btn btn-sm f-btn float-right mb-2">Save</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 p-0">
                                            <button class="btn addcard-btn">Add Card &nbsp;<i
                                                    class="fa fa-plus"></i></button>
                                        </div> -->
                                        <div class="col-md-12 details-row">
                                            <p class="opacity">DBR</p>
                                            <p><b>4.5</b></p>
                                        </div>
                                        <div class="col-md-12 mt-4">
                                            <p class="mb-2"><b>Responsible lending</b></p>
                                        </div>
                                        <div class="col-md-12 details-row mb-2">
                                            <p class="opacity">Number of dependencys</p>
                                            <p><b>3</b></p>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-5 details-row">
                                                    <div>
                                                        <div class="form-group">
                                                            <label class="form-label" for="">Housing</label>
                                                            <input type="text" class="form-control" placeholder="Housing" value="SAR 100">
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="form-group">
                                                            <label class="form-label" for="">F&B</label>
                                                            <input type="text" class="form-control" placeholder="F&B" value="SAR 100">
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="form-group">
                                                            <label class="form-label" for="">Expat
                                                                    fees</label>
                                                            <input type="text" class="form-control" placeholder="Expat feesr" value="SAR 100">
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="form-group">
                                                            <label class="form-label" for="">Communication</label>
                                                            <input type="text" class="form-control" placeholder="Communication" value="SAR 100">
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="form-group">
                                                            <label class="form-label" for="">Healthcare &
                                                                    insurance</label>
                                                            <input type="text" class="form-control" placeholder="Healthcare" value="SAR 100">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-1"></div>
                                                <div class="col-md-5 details-row">
                                                    <div>
                                                        <div class="form-group">
                                                            <label class="form-label" for="">Utilities</label>
                                                            <input type="text" class="form-control" placeholder="Enter Utilities" value="SAR 100">
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="form-group">
                                                            <label class="form-label" for="">Household
                                                                    assistance</label>
                                                            <input type="text" class="form-control" placeholder="Household assistance" value="SAR 100">
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="form-group">
                                                            <label class="form-label" for="">Transportation</label>
                                                            <input type="text" class="form-control" placeholder="Transportation" value="SAR 100">
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="form-group">
                                                            <label class="form-label" for="">Tuition
                                                                    fees</label>
                                                            <input type="text" class="form-control" placeholder="Tuition fees" value="SAR 100">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <app-financial-details></app-financial-details>

                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingFive">
                            <a class="accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <span><strong>Bank account</strong></span>
                                <span><img src="assets/images/main_images/np_arrow.png"
                                        width="12px" /></span>
                            </a>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                            <div class="accordion-body px-4 py-4">
                                <!-- <div class="container">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <form [formGroup]="bankGroup">
                                                <div class="row">
                                                    <div class="col-md-5 col-6 details-row mb-3">
                                                        <div>
                                                            <div class="form-group">
                                                                <label class="form-label" for="">Your salary transfer bank</label>
                                                                <div class="form-control" [ngClass]="{
                                                                        validationClass:
                                                                          loginFormValidationFlag &&
                                                                          bankGroup.controls?.bankAccountID?.errors
                                                                      }">{{bankType}}
                                                                    <select name="cars" id="cars" formControlName="bankAccountID" (change)="filter($event)">
                                                                            <option *ngFor="let res of banks;let i=index;" [value]="res.id">{{res.name}}</option>
                                                                          </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1"></div>
                                                    <div class="col-md-5 col-6 details-row">
                                                        <div>
                                                            <div class="form-group">
                                                                <label class="form-label" for="">IBAN</label>
                                                                <input type="text" class="form-control" placeholder="Enter IBAN" formControlName="iBAN" [ngClass]="{
                                                                      validationClass:
                                                                        loginFormValidationFlag &&
                                                                        bankGroup?.controls?.iBAN.errors
                                                                    }">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div> -->
                                <app-bank-account-details></app-bank-account-details>

                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="!userProfile">
                        <div class="col-md-12 text-center mt-4">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                <label class="form-check-label" for="flexCheckDefault">
                                    I agree on the <u><strong>terms and conditions</strong></u>
                                </label>
                            </div>
                            <div class="mt-2">
                                <button class="btn btn-md apply-btn" (click)="apply()">Apply</button>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>