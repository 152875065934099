import { Pipe, PipeTransform } from '@angular/core';
import { LabelType, Options } from '@angular-slider/ngx-slider';
import { TranslateService } from '@ngx-translate/core';
@Pipe({
  name: 'sliderAr'
})
export class SliderArPipe implements PipeTransform {

  transform(n,x):Options {
    // console.log(x)
    // new Options().
    console.log(n)
    console.log(new Intl.NumberFormat('en-IN',{}).format(n.floor))

    let op:Options = {
      floor: n.floor,
      ceil: n.ceil,
      rightToLeft:true,
      translate: (value: number, label: LabelType): string => {
        return new Intl.NumberFormat('ar-SA',{}).format(value);
      },

    }
    // let optEn = {
    //   // console.log(x)
    //     floor: n.floor,
    //     ceil: n.ceil,
    //     rightToLeft:false,
    //     translate: (value: number, label: LabelType): string => {
    //       return new Intl.NumberFormat('en-IN',{}).format(value);
    //     }
    //   }
    // console.log(n)
    if (n === null || n === undefined) {
      return {};
    }
    if(x == 'en'){

   return n
  }

    return op;

  }

}









