<div class="container-fluid">
    <div class="row">

        <div class="col-md-6">
            <h2 class="uload-header">
                Bank statement
            </h2>
        </div>
        <div class="col-md-12 d-flex justify-content-between align-items-center">
            <span class='upload-des ml-2'>
              No uploaded document yet
     </span>

            <button (click)="uploadPopup(content)" class="btn btn btn-outline-success">
              <img class='mr-2 mb-1' src="../../../../assets/images/icons/upload-icon.svg" alt="upload">
              <span>

                Upload
               </span>
            </button>
            <!-- </input> -->
        </div>
    </div>
    <hr class="profile-line">
    <div class="row">

        <div class="col-md-6">
            <h2 class="uload-header">
                Salary Certificate
            </h2>
        </div>
        <div class="col-md-12 d-flex justify-content-between align-items-center">
            <span class='upload-des ml-2'>
            No uploaded document yet
   </span>

            <button (click)="uploadPopup(content)" class="btn btn btn-outline-success">
            <img class='mr-2 mb-1' src="../../../../assets/images/icons/upload-icon.svg" alt="upload">
            <span>

              Upload
             </span>
          </button>
            <!-- </input> -->
        </div>
    </div>
    <hr class="profile-line">
    <div class="row">

        <div class="col-md-6">
            <h2 class="uload-header">
                SIMAH Report
            </h2>
        </div>
        <div class="col-md-12 d-flex justify-content-between align-items-center">
            <span class='upload-des ml-2'>
          No uploaded document yet
 </span>

            <button (click)="uploadPopup(content)" class="btn btn btn-outline-success">
          <img class='mr-2 mb-1' src="../../../../assets/images/icons/upload-icon.svg" alt="upload">
          <span>

            Upload
           </span>
        </button>
            <!-- </input> -->
        </div>
    </div>
    <hr class="profile-line">


</div>

<ng-template #content let-modal>

    <div class="modal-body ">
        <h2 class="uload-header">
            Bank Statement Uploading
        </h2>
        <form [formGroup]="formGroup">

            <!-- <label for="upload" class="upload-doc">
              <div class="drag-upload">
                <img src="../../../../assets/images/icons/upload-doc-main.svg" alt="upload">
                <p class="drag-text">
                  Drag a document here or click to choose a file
                </p>
              </div> -->


            <!-- <input id="upload" type='file'   (change)="upload('1',$event)">
<span>

Upload
</span>
</label> -->

            <div class="drop-zone" appUpload (fileDropped)="onFileDropped($event)">
                <input type="file" #fileDropRef id="fileDropRef" (change)="fileBrowseHandler($event.target.files)" />


                <div class="drag-upload align-items-center justify-content-center">
                    <img src="../../../../assets/images/icons/upload-doc-main.svg" alt="upload">
                    <p class="drag-text">
                        Drag a document here or click <br> to choose a file
                    </p>
                </div>
                <!-- <h3>or</h3> -->

                <label for="fileDropRef" class="btn btn-outline-success">Choose file


</label>
            </div>
            <div class="files-list">
                <div class="single-file" *ngFor="let file of files; let i = index">
                    <!-- <div class="file-icon" style="width: 50px">
                </div> -->
                    <div class="info d-flex align-items-center">
                        <h4 class="name">
                            <img src="../../../../assets/images/icons/doc-upload.svg" alt=""> {{ file?.name }}
                        </h4>
                        <!-- <p class="size">
                        {{ formatBytes(file?.size) }}
                    </p> -->
                        <!-- <app-progress [progress]="file?.progress"></app-progress> -->

                        <mat-spinner *ngIf="uploadSpin"></mat-spinner>

                        <div class="delete" (click)="deleteFile(i)">
                            <span>
                         Delete
                       </span>
                        </div>
                    </div>


                </div>
            </div>
            <div class="files-footer row align-items-end">
                <div class="col-md-8">

                    <div class="form-group m-0">
                        <label for="">
                  Validation End Date <span>
                    *
                  </span>
                </label>
                        <input type="text" class="text-form-input w-100">
                    </div>
                </div>
                <div class="col-md-4">
                    <button class="btn btn-success d-flex w-100 align-items-center justify-content-center">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.3335 11.3334L1.3335 12.1667C1.3335 13.5475 2.45278 14.6667 3.8335 14.6667L12.1668 14.6667C13.5475 14.6667 14.6668 13.5475 14.6668 12.1667L14.6668 11.3334M11.3335 4.66675L8.00016 1.33341M8.00016 1.33341L4.66683 4.66675M8.00016 1.33341L8.00016 11.3334" stroke="#ffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                                    <span class="ml-2">

                  Upload
                 </span>
              </button>
                </div>


            </div>
        </form>

    </div>

</ng-template>
