import { HttpEvent, HttpEventType } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CreditlyServicesService } from 'src/app/creditly-services.service';
import { FileUploadService } from 'src/app/file-upload.service';
import { ProfileService } from 'src/app/shared/services/profile.service';


export interface PeriodicElement {
  name: string;
  // position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  { name: 'Helium', weight: 4.0026, symbol: 'He'},
  { name: 'Lithium', weight: 6.941, symbol: 'Li'},
  { name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
  { name: 'Boron', weight: 10.811, symbol: 'B'},
  { name: 'Carbon', weight: 12.0107, symbol: 'C'},
  { name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
  { name: 'Oxygen', weight: 15.9994, symbol: 'O'},
  { name: 'Fluorine', weight: 18.9984, symbol: 'F'},
  { name: 'Neon', weight: 20.1797, symbol: 'Ne'},
];
@Component({
  selector: 'app-history-details',
  templateUrl: './history-details.component.html',
  styleUrls: ['./history-details.component.scss'],

})
export class HistoryDetailsComponent implements OnInit {
  @Input() reqType:string;
  @Input() reqFrom:boolean=false;
  categoryID=sessionStorage.getItem("CategoryID");
  closeResult: string;
    private profile:ProfileService;private  cancelReason = new FormControl(null,[Validators.required  ]);

  displayedColumns: string[] = [ 'Name', 'Product', 'Date','Status'];
  dataSource ;
  pending: any[];
  pendingReqs: any[];
  approvedReqs: any[];
  rejReqs: any[];
  constructor(public apiService: CreditlyServicesService,
    public uploadService:FileUploadService,private modalService: NgbModal) { }
  progress={
    p1:0,
    p2:0,
    p3:0
  };
  reqCancel:number=0;
  idCancel:number=0;
  submitReq=false;
  slides = [];
  request = [];
  finding = false;
  fileName1:string;
  fileName2:string;
  fileName3:string;
  idState=1;
  salaryState=1;
  otherState=1;
  idFile:File;
  salaryFile:File;
  otherFile:File;
  slideConfig = {
    centerPadding: '10px',
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: "<div class='slick-next'></div>",
    prevArrow: "<div class='slick-prev'></div>",
    dots: false,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  slickInit(e) {
    console.log('slick initialized');
  }

  breakpoint(e) {
    console.log('breakpoint');
  }

  afterChange(e) {
    console.log('afterChange');
  }

  beforeChange(e) {
    console.log('beforeChange');
  }
  ngOnChanges(){
this.reqType
console.log(this.reqType);
if(this.reqType =='pending'){
  this.request =this.reqs.filter(res=>{
    return  res.status == 101

})
}else if(this.reqType =='approved'){

  this.request = this.reqs.filter(res=>{
  return  res.status == 104

  })
}else {

  this.request = this.reqs.filter(res=>{
  return  res.status == 104

  })
}
  }
  reqs = []
  ngOnInit(): void {
    // console.log('s'.());
 console.log(this.reqType);

    this.finding=true;
    this.apiService.request.subscribe(res=>{
           this.reqs=JSON.parse(localStorage.getItem("productRequest"));
           this.dataSource =this.reqs;
           if(this.reqFrom) {
            this.request = this.reqs;
           }
          console.log(this.request);
        })
    console.log(document.getElementById("confirmBtn2"))
  }
  filter(arr: Array<any>, status) {
    // console.log(arr.filter(val => val.code == status)[0]?.name)
    return arr.filter(val => val.code == status)[0]?.name ? arr.filter(val => val.code == status)[0]?.name : "Unknown";
  }
  onFileChange1(event) {
    if (event.target.files.length > 0) {
      this.idFile = event.target.files[0];
      this.fileName1=this.idFile.name;
      this.idState=2;
    }
  }
  onFileChange2(event) {
    if (event.target.files.length > 0) {
      this.salaryFile = event.target.files[0];
      this.fileName2=this.salaryFile.name;
      this.salaryState=2;
    }
  }
  onFileChange3(event) {
    if (event.target.files.length > 0) {
       this.otherFile = event.target.files[0];
      this.fileName3=this.otherFile.name;
      this.otherState=2;
    }
  }
  submit(){
    if(this.idState==2||this.salaryState==2||this.otherState==2)
    {
      this.submitReq=true;
      if(this.idState==2) this.submitUser("p1",this.idFile);
      if(this.salaryState==2) this.submitUser("p2",this.salaryFile);
      if(this.otherState==2) this.submitUser("p3",this.otherFile);
  }else{
    this.apiService.notify("please upload file","error")
  }
  }
  submitUser(by,file:File) {
    this.uploadService.addUser(
      1,
      file
    ).subscribe((event: HttpEvent<any>) => {
    if(by=="p1")this.idState=3;
    if(by=="p2")this.salaryState=3;
    if(by=="p3")this.otherState=3;
      switch (event.type) {
        case HttpEventType.Sent:
          console.log('Request has been made!');
          break;
        case HttpEventType.ResponseHeader:
          console.log('Response header has been received!');
          break;
        case HttpEventType.UploadProgress:
          this.progress[by] = Math.round(event.loaded / event.total * 100);
          console.log(`Uploaded! ${this.progress}%`);
          break;
        case HttpEventType.Response:
          console.log('User successfully created!', event.body);
          if(by=="p1")this.idState=4;
          if(by=="p2")this.salaryState=4;
          if(by=="p3")this.otherState=4;
          this.submitReq=true;
          setTimeout(() => {
            this.progress[by] = 0;
          }, 1500);
      }
    })
  }
  cancel(id,req,i){
    this.reqCancel=req;
    this.idCancel=id;
  }
  click(){
    document.getElementById("confirmBtn2").click();
  }
  cancelByConfirm(comment){
    this.submitReq=true;
    this.profile.UpdateReqStatus({
      "UserRequestID":this.cancelId,
    "UserRequestStatusCode":107,
    "Comment":comment
    }).subscribe(val=>{
    this.submitReq=false;
    this.modalService.dismissAll()
    // document.getElementById("confirmBtn2").click();
      if(this.request.findIndex(val=>val.id==this.cancelId)>-1){
        this.request[this.request.findIndex(val=>val.id==this.idCancel)]["requestStatus"]["code"]=107;
        this.request[this.request.findIndex(val=>val.id==this.idCancel)]["requestStatus"]["description"]="Cancel by user";
      }
    })
  }




  cancelId
  open(content,id) {
    this.cancelId = id

    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',centered:true}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
