import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { CreditlyServicesService } from 'src/app/creditly-services.service';
import { residenceTypes } from 'src/app/shared/data/list-menus';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent implements OnInit {
  @Input() item = '';
@Output() loginFormValidationFlagEvent = new EventEmitter<any>();

  profileDate = JSON.parse(localStorage.getItem("loginResponse"));
  resType:any ;
  type = { id: "", name: "" };
  loginFormValidationFlag = false;
  contactData = {
    "userID": this.profileDate?.userPersonalProfile?.userID,
    "residenceTypeID": this.profileDate?.userPersonalProfile?.residenceTypeID,
    "email": this.profileDate?.email,
    "addressId": this.profileDate?.userAddress[0]?.id,
    "isSave": true
  }
  submit = false;
  re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  formDatacontactData: { formData: ({ formControl: string; isEditable: boolean; visible: boolean; labelName: string; selectMenu: boolean; menuData?: undefined; } | { formControl: string; isEditable: boolean; visible: boolean; labelName: string; selectMenu: boolean; menuData: any; })[]; formGroup: FormGroup; };
  homeCountryForm: FormGroup;
  ;
  formGroup: FormGroup;
  private ngUnsubscribe = new Subject();
  homeCountryAddress = JSON.parse(localStorage.getItem('loginResponse')).homeCountryAddress;
  myDetails = JSON.parse(localStorage.getItem('loginResponse')).relativeContactInfo;
  addressDetails = JSON.parse(localStorage.getItem('loginResponse')).userAddress[0];
  userData = JSON.parse(localStorage.getItem('loginResponse'))
  isSaudiUser : boolean;
  constructor(private ApiService: CreditlyServicesService,private cdr:ChangeDetectorRef,private profile:ProfileService) {
    this.initForm()
  }
  private initForm() {
    this.formGroup = new FormGroup({
      userID: new FormControl(this.profileDate?.id),
      residenceTypeID: new FormControl(this.profileDate?.userPersonalProfile?.residenceTypeID , [Validators.required]),
      // TransportationType: new FormControl(this.profileDate?.userPersonalProfile?.transportationType=="None"?null:this.profileDate?.userPersonalProfile?.transportationType, [Validators.required]),
      email: new FormControl(this.profileDate?.email, [Validators.required, Validators.pattern(this.re)]),
      // addressId: new FormControl(this.profileDate?.userAddress[0]?.id, [Validators.required]),
    // addressNational: new FormControl(this.profileDate?.iDorIqamaIssuePlace, [Validators.required]),

      mobile: new FormControl(this.profileDate?.mobile, [Validators.required]) ,
      LandLine: new FormControl(this.profileDate?.landLine, [Validators.required]),
      CityName:  new FormControl(this.homeCountryAddress?.cityName,[Validators.required]),
      DistrictName:  new FormControl(this.homeCountryAddress?.districtName,[Validators.required]),
      StreetName:  new FormControl(this.homeCountryAddress?.streetName ,[Validators.required]),
      BuildingNumber : new FormControl(this.homeCountryAddress?.buildingNumber,[Validators.required]),
      UnitNumber:  new FormControl(this.homeCountryAddress?.unitNumber,[Validators.required]),
      ZipCode:  new FormControl(this.homeCountryAddress?.zipCode,[Validators.required]),
      PoBOX:new FormControl(this.homeCountryAddress?.poBOX,[Validators.required]),
      TelephoneNumber: new FormControl(this.homeCountryAddress?.telephoneNumber,[Validators.required]),
      isSave: new FormControl(true, [Validators.required]),
      FullName : new FormControl(this.myDetails?.fullName,[Validators.required]),
      RelationshipType:  new FormControl(this.myDetails?.relationshipType,[Validators.required]),
      MobileNumber:  new FormControl(this.myDetails?.mobileNumber,[Validators.required]),
      RelativeTelephoneNumber:  new FormControl(this.myDetails?.relativeTelephoneNumber ,[Validators.required]),
    });

    // this.homeCountryForm = new FormGroup({

    // })
  }
  relativeContactsentData
  nationalData
  homeContry
  ngOnInit(): void {
    if(this.userData) {
      this.isSaudiUser = this.userData.id.toString()[0] == '1' ? true :false;
    }
    this.resType = residenceTypes.map(res=>({
      ...res,
      checked:this.profileDate?.userPersonalProfile?.residenceTypeID == res.id &&this.profileDate?.userPersonalProfile?.residenceTypeID?true:false
    }))

    this.nationalData = [
      [{
        name:"City Name",value:this.addressDetails?.city
      },{
        name:"District Name",value:this.addressDetails?.district
      },{
        name:"Street Name",value:this.addressDetails?.streetName
      },
      {
        name:"Building Number",value:this.addressDetails?.buildingNumber
      }],
      [{
        name:"ZIP Code",value:this.addressDetails?.postCode
      },{
        name:"Additional No.",value:this.addressDetails?.additionalNumber
      },{
        name:"Unit Number",value:this.addressDetails?.unitNumber
      },
      {
        name:"Mobile Number",value:this.userData?.mobile
      }]

    ];
    this.relativeContactsentData =[
      {
          formControl:'FullName',
          labelName:'Full Name',
     selectMenu:false

        },
      {
     formControl:'RelationshipType',
     labelName:'Relationship Type ',
     selectMenu:true,
     menuData:['father','brother','mother','sister','cousin']
   },
   {
    formControl:'MobileNumber',
    labelName:'Mobile Number',
    selectMenu:false,


  },
  {
    formControl:'RelativeTelephoneNumber',
    labelName:'Telephone Number',
    selectMenu:false
  },









  ]
     this.homeContry =[
      {
     formControl:'CityName',
     labelName:'City Name',
     selectMenu:false
   },
   {
    formControl:'DistrictName',
    labelName:'District Name',
    selectMenu:false,


  },
  {
    formControl:'StreetName',
    labelName:'Street Name',
    selectMenu:false
  },
  {
    formControl:'BuildingNumber',
    labelName:'Building Number',
    selectMenu:false
  },
  {
    formControl:'UnitNumber',
    labelName:'Unit Number',
    selectMenu:false
  },

  {
    formControl:'ZipCode',
    labelName:'Zip Code',
    selectMenu:false
  },
  {
    formControl:'PoBOX',
    labelName:'Po BOX',
    selectMenu:false,
    // menuData:this.residenceTypes
   },
  {
    formControl:'TelephoneNumber',
    labelName:'Telephone Number',
    selectMenu:false
  },









  ]
    if (this.item == 'true') {
      this.ApiService.subscribe().subscribe(value => {
        this.loginFormValidationFlag = this.formGroup.invalid ? true : false;
        if (this.formGroup.invalid) {
          this.ApiService.validObject.contact.valid = false;
        } else {
          this.ApiService.validObject.contact.valid = true;
        }
      })
      this.formGroup.valueChanges.subscribe(change => {
        this.ApiService.validObject.contact.change = true;
      })
      this.ApiService.contact.subscribe(res => {
        this.update();
      })
    }

    this.ApiService.formCompletion = this.formGroup.invalid == false ? this.ApiService.formCompletion + 1 : this.ApiService.formCompletion;
   let contatData =[ {
      formControl:'mobile',
      isEditable:false,
      visible:true,
      labelName:'Mobile Number',
      selectMenu:false
    },
    {
      formControl:'email',
      isEditable:true,
      visible:true,
      labelName:'Email',
      selectMenu:false
    },
    {
      formControl:'LandLine',
      isEditable:true,
      visible:true,
      labelName:'LandLine',
      selectMenu:false
    },
    {
      formControl:'addressNational',
      isEditable:false,
      visible:true,
      labelName:'National Address',
      selectMenu:false
    },
    {
      formControl:'residenceTypeID',
      isEditable:true,
      visible:true,
      labelName:'Type of Residence',
      selectMenu:true,
      menuData:this.resType

    },
    {
      formControl:'TransportationType',
      isEditable:true,
      visible:true,
      labelName:'Type of Transportation',
      selectMenu:true,
      menuData:this.resType?.map(res=>res.name)
    }
    ]

        //  this.formDatacontactData = {
        //     formData:contatData,
        //     formGroup: this.formGroup,
        //     // formLabels:Object.keys(formObjecObligations)
        //   }
this.profile.emitChanges.subscribe(res=>{
  if(res=='contact'){
    this.update()
  }
})
  }

  selectResType(res){
    console.log(res);

    for(let ti of this.resType){
      if(res.name == ti.name){
        ti.checked = true
      } else {
       ti.checked = false

      }
   }
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  change(event) {
    let id = event.target.value
    this.formGroup.patchValue({ residenceTypeID: parseInt(id) });
  }
  update() {
    console.log(this.formGroup.value.RelationshipType);

    let payload = {

      "userID":this.formGroup.value.userID,
      "residenceTypeID":this.formGroup.value.residenceTypeID,
      "email":this.formGroup.value.email,
      "LandLine":this.formGroup.value.LandLine,

       "CountryName":this.homeCountryAddress.CountryName,
      "CityName":this.formGroup.value.CityName,
      "DistrictName":this.formGroup.value.DistrictName,
      "StreetName":this.formGroup.value.StreetName,
      "BuildingNumber":this.formGroup.value.BuildingNumber,
      "UnitNumber":this.formGroup.value.UnitNumber,
      "ZipCode":this.formGroup.value.ZipCode,
      "PoBOX":this.formGroup.value.PoBOX,
      "TelephoneNumber":this.formGroup.value.TelephoneNumber,

      "FullName":this.formGroup.value.FullName,
      "RelationshipType":this.formGroup.value.RelationshipType,
      "MobileNumber":this.formGroup.value.MobileNumber,
      "RelativeTelephoneNumber":this.formGroup.value.RelativeTelephoneNumber,
      "isSave": true
    }
    this.loginFormValidationFlag = this.formGroup.invalid ? true : false;
    this.loginFormValidationFlagEvent.emit(this.loginFormValidationFlag);
    if (!this.loginFormValidationFlag) {
      this.submit = true;
      this.profile.updateProfile(payload,"updateContact").subscribe(val => {
        this.submit = false;
        // this.ApiService.refreshData();
        if(this.item!=='true')this.ApiService.notify("Successfully updated", "success");
      })
    } else {
      this.ApiService.notify("Please fill all required fileds", "error");
    }
  }
  changeAddress(id) {
    this.formGroup.patchValue({ addressId: parseFloat(id) })
  }
  ngAfterViewChecked() {
  }

  enableEdit(formG){
    formG.pristine = !formG.pristine;
    formG.updateValueAndValidity()
    this.cdr.detectChanges()

    console.log(formG)
  }

  // filter(data: any, id: number | string) {
  //   if (data?.length && data?.filter((val) => val.id == id)[0]?.name)
  //     return data.filter((val) => val.id == id)[0]?.name;
  //   else return 'NA';
  // }
}
