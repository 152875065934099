import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { LandingPageCardsListComponent } from './landing-page-cards-list/landing-page-cards-list.component';
import { RouterModule, Router } from '@angular/router';
import { HashLocationStrategy, LocationStrategy, DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS,HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotifierModule } from 'angular-notifier';
import { HeaderComponent } from './shared/header/header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReCaptchaModule } from 'angular-recaptcha3';
import { NotFound404Component } from './not-found404/not-found404.component';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSpinnerModule } from "ngx-spinner";
import { CarouselModule } from 'ngx-bootstrap/carousel';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { MainViewComponent } from './main-view/main-view.component';
import { SharedModule } from './shared/shared.module';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import { InterceptorService } from './core/interceptor/interceptor.service';
import { ProfileUserViewComponent } from './profile-user-view/profile-user-view.component';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { UserHomePageComponent } from './landig-page-logged-in-user/user-homepage.component';
import {MatIconModule} from '@angular/material/icon';


/* const RECAPTCHA_OPTION = {
  language?: 'en';
  invisible?: IRecaptchaOption;
  normal?: IRecaptchaOption;
} */

// export function createTranslateLoader(http: HttpClient) {
//   return new TranslateHttpLoader(http);
// }
@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    LandingPageCardsListComponent,
    NotFound404Component,
    MainViewComponent,
    ProfileUserViewComponent,
    UserHomePageComponent,
  ],
  imports: [
    MatIconModule,
    BrowserModule,
    AppRoutingModule,
    NgxSliderModule, BrowserAnimationsModule,
    RouterModule,
    HttpClientModule, NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    NotifierModule,
    NgIdleKeepaliveModule.forRoot(),
    NgbModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
     InfiniteScrollModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient]
      }
  }),
    ReCaptchaModule.forRoot({
      invisible: {
        sitekey: '6LeH3YkaAAAAADd7pL1TWMulqSuDjTfFE5MMSS89',
      },
      normal: {
        sitekey: '6LeH3YkaAAAAADd7pL1TWMulqSuDjTfFE5MMSS89',
      },
      language: 'en'
    }),
    CarouselModule.forRoot(),
  SharedModule,

  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    DatePipe,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    BsDatepickerConfig
  ],
  bootstrap: [AppComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
// platformBrowserDynamic().bootstrapModule(AppModule);
export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
