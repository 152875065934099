import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortEligible'
})
export class SortEligiblePipe implements PipeTransform {

  transform(value: any[], ...args: unknown[]): unknown {
    let falseVal = []
    let trueVal = []
    for( let item of value){
      if(item.isEligible){
       trueVal.push(item)
      } else{
       falseVal.push(item)
      }

    }
    return trueVal.concat(falseVal);
  }

}
