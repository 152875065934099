<div class="mt-150">
    <div class="layerMask">
        <div class="backgroundMask2" [ngClass]='{"backgroundMask2_ar":currentLang  == "ar"}'>

        </div>
        <div class="backgroundMask1">

            <div class="background">
                <div class="own-bg"></div>
                <div class="content_on_grey_bg">
                    <div class="two_parts">
                        <div class="first_part">
                            <!-- <h1>Find and <br> compare auto lease products across Saudi banks</h1>

                           -->
                            <img *ngIf='imageBackground == "credit_card"&&backgroundData.twoParts' class='line-oran' src="../../../../assets/images/main_images/orang-line-credt.svg" alt="">

                            <h1>
                                <!-- Find the perfect car<br> lease for your needs -->
                                {{mainTitle|translate}}
                            </h1>
                            <p class="sub-title text-light">
                                {{subTitle|translate}}

                            </p>

                        </div>
                        <div class="second_part d-lg-block d-none">
                            <!-- <img src="../../../../assets/images/main_images/auto_lease_header_image.svg" alt=""> -->

                            <app-auo-lease-image *ngIf='backgroundData.twoParts && imageBackground !== "credit_card"'></app-auo-lease-image>
                            <img *ngIf='imageBackground == "credit_card"&&backgroundData.twoParts' src="../../../../assets/images/main_images/credit-card-back.svg" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>