import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl = environment.baseUrl;
  mainBaseUrl = environment.mainBaseUrl;
  rigisterURL = 'https://devapi.creditly.sa/api/session/'
  encryptSecretKey = "Creditly#Secret"
  // headers = new HttpHeaders();

  constructor(private http: HttpClient) {
    // this.headers =
    // this.allCountries()
    // this.http.get('../data/countries.json')
   }
  //  url = 'https://github.com/Dinuks/country-nationality-list/blob/master/countries.json';


// headersReq(){
//   return this.headers
//     .set(
//       'Authorization',
//       'API_User_Auth ' +
//       'JGNyZWRpdGx5d2ViYXBpOmUxMGFkYzM5NDliYTU5YWJiZTU2ZTA1N2YyMGY4ODNl'
//     )
//     .set('APIKey', '5A2A6A7E-F86F-4877-83D3-9')
//     .set('APPType', '1')
//     .set('APPCode', 'CR1T1')
//     .set('Token', localStorage.getItem('sessionToken')?localStorage.getItem('sessionToken'):localStorage.getItem('token'))
//     .set('Content-Type', 'application/json')
//     .set('Accept-Language', 'en');
// }

  // registerFirstStep(data, token): Observable<any> {
  //   let headers = this.headers.set('Token', token);
  //   return this.http.post(`${this.baseUrl}register`, data, {
  //     headers: headers,
  //   });
  // }
  // registerSubStep(data): Observable<any> {
  //   let headers = this.headers.set(
  //     'Token',
  //     localStorage.getItem('token')
  //   );
  //   return this.http.post(`${this.baseUrl}register`, data, {
  //     headers: headers,
  //   });
  // }

  // login(data, token): Observable<any> {
  //   let headers = this.headers.set('Token', token);
  //   localStorage.setItem("refreshRequest", this.encryptData({ password: data.password, mobile: data.Mobile }))
  //   return this.http.post(`${this.baseUrl}login`, data, { headers: headers });
  // }
  // loginData() {
  //   let value: any = this.decryptData(localStorage.getItem("refreshRequest"));
  //   let obj = { Mobile: value.mobile, password: value.password };
  //   this.login(obj, localStorage.getItem("token")).subscribe(result => {
  //     if (result != null && result != undefined) {
  //       localStorage.setItem("loginResponse", JSON.stringify(result));
  //     }
  //   })
  // }

  // encryptData(data) {
  //   try {
  //     return CryptoJS.AES.encrypt(JSON.stringify(data), this.encryptSecretKey).toString();
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }
  // decryptData(data) {

  //   try {
  //     const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey);
  //     if (bytes.toString()) {
  //       return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  //     }
  //     return data;
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }
  // verification(data): Observable<any> {
  //   let headers = this.headers.set('Token', localStorage.getItem('token'));
  //   return this.http.post(`${this.mainBaseUrl}session/register/validation`, data, {
  //     headers: headers,
  //   });
  // }
// loginDec(data,a){
//   // 69adb9eb54f7badc7a4e9d0eb082483e
//     let headers = this.headers.set('Token', '69adb9eb54f7badc7a4e9d0eb082483e');

//   return this.http.post(`${this.baseUrl}register/employmentconfirmation`,data, {headers:  headers})
// }
// te(){

//   let test = localStorage.getItem('test')?localStorage.getItem('test'):70;
//   return test
// }

// rea(){
//   console.log(this.te())
//   return
// }

  // register steps //
  registerStepGenric(data,step): Observable<any>{
    // let headers = this.headers.set('Token', 'ffa5bf1cf5197a8ccdc8ffd5d0784e4d');
    return this.http.post(`${this.baseUrl}register/${step}`,data)
    // {headers:  this.headersReq()})

  }

  loginGetOtp(loginData):Observable<any>{
    // let headers = this.headers.set('Token', 'ffa5bf1cf5197a8ccdc8ffd5d0784e4d')

    return this.http.post(`${this.baseUrl}login/getotp`,loginData)
    // {headers:  this.headersReq()})
  }

  loginVerifytOtp(otp):Observable<any>{
    // let headers = this.headers.set('Token', 'ffa5bf1cf5197a8ccdc8ffd5d0784e4d')

    return this.http.post(`${this.baseUrl}login`,otp)
    // {headers:  this.headersReq()})
  }


  forgotPassword(idNum):Observable<any>{
    return this.http.get(`${this.baseUrl}forgotpassword/${idNum}`)
    // ,{headers:  this.headersReq()})

  }

  forgotPasswordOtpVerify(otb):Observable<any>{
    return this.http.get(`${this.baseUrl}forgotpasswordotpVarify/${otb}`)
    // ,{headers:  this.headersReq()})

  }

  updatePassword(pass):Observable<any>{
    return this.http.post(`${this.baseUrl}forgotpassword`,pass)
    // ,{headers:  this.headersReq()})

  }
  // registerVerifyMobileStep(data): Observable<any>{
  //   return this.http.post(`${this.rigisterURL}register/verifymobile`,data)

  // }

  // registerPasswordStep(data): Observable<any>{
  //   return this.http.post(`${this.rigisterURL}register/createAccount`,data)

  // }

  // registerDeclarationsStep(data): Observable<any>{
  //   return this.http.post(`${this.rigisterURL}register/declarations`,data)

  // }

  // registerAbsherStep(data): Observable<any>{
  //   return this.http.post(`${this.rigisterURL}register/absher`,data)

  // }
  // registerVerifyAbsherStep(data): Observable<any>{
  //   return this.http.post(`${this.rigisterURL}register/verifyabsher`,data)

  // }
  // registerEmploymentStep(data): Observable<any>{
  //   return this.http.post(`${this.rigisterURL}register/employmentconfirmation`,data)

  // }

  getUserToken(){
    return localStorage.getItem('sessionToken')?localStorage.getItem('sessionToken'):localStorage.getItem('token');
  }
  public logOut() {
    return this.http.get(`${this.baseUrl}logout`);
  }
}
