// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://devapi.creditly.sa/api/session/',
  mainBaseUrl: 'https://devapi.creditly.sa/api/',
  // mainBaseUrl: 'http://193.122.75.138:4000/api/',

  countiresUrl:"https://raw.githubusercontent.com/Dinuks/country-nationality-list/master/countries.json"
};
// export const baseUrl = 'http://193.122.75.138:4000/api/session/';
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
