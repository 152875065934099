<div class="card_details_starts" *ngIf="catName!=='CreditCard'">
    <div class="row ">
        <div class="col-md-12 col-12 mt-3">
            <div class="row align-items-center details-title">
                <div class="col-md-8 col-12">
                    <div class="inner-block-header ">
                        <div class="d-flex">
                            <img src="../../../assets/images/main_images/np_free_338463.svg" alt="">
                            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="21.727" height="21.72" viewBox="0 0 21.727 21.72">
                                          <path id="np_free_338463_000000"
                                              d="M21.128,18.12a.944.944,0,1,0-.944-.944A.946.946,0,0,0,21.128,18.12Zm4.451,1.114H20.226l-1.835-2.058,1.835-2.059h5.353Zm.574-5.264H25.77V9.279A.575.575,0,0,0,25.2,8.7H24.1V6.647a.575.575,0,0,0-.574-.574H20.845a.574.574,0,0,0,0,1.149h2.1V8.7H19.736a.574.574,0,0,0,0,1.149h4.886V13.97H19.969a.572.572,0,0,0-.428.191l-2.348,2.632a.575.575,0,0,0,0,.765l2.348,2.632a.572.572,0,0,0,.428.192h4.653V24.5H13.082a.574.574,0,0,0,0,1.149H25.2a.575.575,0,0,0,.574-.574v-4.69h.383a.575.575,0,0,0,.574-.574V14.543a.575.575,0,0,0-.574-.573Zm-6.69-8.925a.574.574,0,0,0-.753.306l-8.666,20.57a.564.564,0,0,0,0,.439.574.574,0,0,0,1.061.007L19.769,5.8a.575.575,0,0,0-.306-.753ZM8.968,24.5h-1a1.82,1.82,0,0,1-1.818-1.818V9.706a1.894,1.894,0,0,0,.731.147h8.742a.574.574,0,1,0,0-1.149H6.878a.73.73,0,0,1-.729-.729V7.952a.731.731,0,0,1,.731-.73h9.852a.574.574,0,0,0,0-1.149H6.88A1.878,1.878,0,0,0,5,7.949V22.68a2.97,2.97,0,0,0,2.967,2.967h1a.574.574,0,0,0,0-1.149Z"
                                              transform="translate(-5 -5)" fill="#96f"
                                              fill-rule="evenodd" />
                                      </svg> -->
                            <span class="title-header ml-2 mr-2">{{"Fees_details"|translate}}</span>
                            <img src="../../../assets/images//credit-cards/rect-down.svg" alt="" srcset="">
                        </div>

                    </div>
                </div>
                <div class="col-md-12 col-12">
                    <div class="row amount_types d-lg-grid d-flex">
                        <div class="col-lg-2 d-lg-block col-12 d-flex flex-row-reverse justify-content-between">
                            <div class="amount m-lg-auto m-0">
                                <h2 [ngClass]='{ "sar_0":item.totalPayments == 0}'> {{item?.totalPayments |convertNumbers:currentLang}} SAR</h2>
                            </div>
                            <div class="amount_text m-lg-auto m-0">
                                <p>{{"All Payments"|translate}}<br class='d-lg-block d-none'> {{"Including Fees"|translate}}</p>
                            </div>
                        </div>
                        <div class="col-lg-2 d-lg-block col-12 d-flex flex-row-reverse justify-content-between">
                            <div class="amount m-lg-auto m-0">
                                <h2 [ngClass]='{ "sar_0":item.monthlyInstallment == 0}'> {{item.monthlyInstallment|convertNumbers:currentLang}} SAR</h2>
                                <!-- <span>%</span> -->
                            </div>
                            <div class="amount_text m-lg-auto m-0">
                                <p>{{"Monthly"|translate}}<br class='d-lg-block d-none'> {{"Installment"|translate}}</p>
                            </div>
                        </div>
                        <div class="col-lg-2 d-lg-block col-12 d-flex flex-row-reverse justify-content-between">

                            <div class="amount m-lg-auto m-0">
                                <!-- <h2> {{item.managementFee}} </h2> <span>%</span> -->
                                <h2>{{mangmentFee|convertNumbers:currentLang}}%</h2>
                            </div>
                            <div class="amount_text m-lg-auto m-0">
                                <p>{{"Management"|translate}}<br class='d-lg-block d-none'>{{"Fees"|translate}}</p>
                            </div>
                        </div>

                        <div *ngIf='catName =="Mortgage"' class="col-lg-2 d-lg-block col-12 d-flex flex-row-reverse justify-content-between">
                            <div class="amount m-lg-auto m-0">
                                <!-- <h2> {{item.managementFee}} </h2> <span>%</span> -->
                                <h2 [ngClass]='{ "sar_0":item.apr == 0}'> {{item?.apr|convertNumbers:currentLang}} %</h2>
                            </div>
                            <div class="amount_text m-lg-auto m-0 pr-md-5 pr-0">
                                <p class="pr-md-5 pr-0">{{"APR"|translate}}</p>
                            </div>


                        </div>


                        <div class="col-lg-2 d-lg-block col-12 d-flex flex-row-reverse justify-content-between">
                            <div class="amount m-lg-auto m-0">
                                <!-- <h2> {{item.managementFee}} </h2> <span>%</span> -->
                                <h2 [ngClass]='{ "sar_0":item.maxPrincipal == 0}'> {{item?.maxPrincipal|convertNumbers:currentLang}} SAR</h2>
                            </div>
                            <div class="amount_text m-lg-auto m-0 pr-md-5 pr-0">
                                <p class="pr-md-5 pr-0">{{"Loan amount"|translate}}</p>
                            </div>


                        </div>
                        <div *ngIf='catName !=="Mortgage"' class="col-lg-2 d-lg-block col-12 d-flex flex-row-reverse justify-content-between">
                            <div class="amount m-lg-auto m-0">
                                <!-- <h2> {{item.managementFee}} </h2> <span>%</span> -->
                                <h2 *ngIf='catName !=="Mortgage"' [ngClass]='{ "sar_0":item.loanMaximumValue == 0}'> {{item?.loanMaximumValue|convertNumbers:currentLang}} SAR</h2>

                            </div>
                            <div class="amount_text m-lg-auto m-0 pr-md-5 pr-0">
                                <p>{{"Max financing amoun"|translate}}</p>

                            </div>


                        </div>

                        <div div class="col-lg-2 d-lg-block col-12 d-flex flex-row-reverse justify-content-between">
                            <div class="amount m-lg-auto m-0">
                                <!-- <ng-container *ngFor="let feeItem of item?.fees"> -->
                                <h2>
                                    {{earlyFees|convertNumbers:currentLang}} {{"Months_numbers"|translate}}
                                </h2>

                                <!-- </ng-container> -->
                                <!-- <span *ngIf='item?.fees?.length == 0'>
                                  <h2>

                                    {{"0"|convertNumbers:currentLang}} {{"Months_numbers"|translate}}
                                  </h2>
                                        </span> -->
                                <!--  <span>{{FilterValue(2,item?.fees)}}
                                                                Months</span> -->
                            </div>
                            <div class="amount_text m-lg-auto m-0 pr-md-5 pr-0">
                                <p>{{"Early Settelement Fees"|translate}}</p>

                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>
        <div class="col-md-12 col-12 mt-3 details-title">
            <div class="row align-items-start">
                <div class="col-md-6 col-12 mt-3" *ngIf="item?.featuresList">
                    <div class="inner-block-header">
                        <div class="d-flex">
                            <img src="../../../../assets/images/icons/np_stars.svg" alt="">
                            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="21.727" height="21.72" viewBox="0 0 21.727 21.72">
                                      <path id="np_free_338463_000000"
                                          d="M21.128,18.12a.944.944,0,1,0-.944-.944A.946.946,0,0,0,21.128,18.12Zm4.451,1.114H20.226l-1.835-2.058,1.835-2.059h5.353Zm.574-5.264H25.77V9.279A.575.575,0,0,0,25.2,8.7H24.1V6.647a.575.575,0,0,0-.574-.574H20.845a.574.574,0,0,0,0,1.149h2.1V8.7H19.736a.574.574,0,0,0,0,1.149h4.886V13.97H19.969a.572.572,0,0,0-.428.191l-2.348,2.632a.575.575,0,0,0,0,.765l2.348,2.632a.572.572,0,0,0,.428.192h4.653V24.5H13.082a.574.574,0,0,0,0,1.149H25.2a.575.575,0,0,0,.574-.574v-4.69h.383a.575.575,0,0,0,.574-.574V14.543a.575.575,0,0,0-.574-.573Zm-6.69-8.925a.574.574,0,0,0-.753.306l-8.666,20.57a.564.564,0,0,0,0,.439.574.574,0,0,0,1.061.007L19.769,5.8a.575.575,0,0,0-.306-.753ZM8.968,24.5h-1a1.82,1.82,0,0,1-1.818-1.818V9.706a1.894,1.894,0,0,0,.731.147h8.742a.574.574,0,1,0,0-1.149H6.878a.73.73,0,0,1-.729-.729V7.952a.731.731,0,0,1,.731-.73h9.852a.574.574,0,0,0,0-1.149H6.88A1.878,1.878,0,0,0,5,7.949V22.68a2.97,2.97,0,0,0,2.967,2.967h1a.574.574,0,0,0,0-1.149Z"
                                          transform="translate(-5 -5)" fill="#96f"
                                          fill-rule="evenodd" />
                                  </svg> -->
                            <span class="title-header ml-2 mr-2">{{"Features"|translate}}</span>
                            <img src="../../../assets/images//credit-cards/rect-down.svg" alt="" srcset="">
                            <!-- </div>
                        <img src="../../../assets/images/main_images/np_airplane.svg" alt="">
                        <span class="title-header">Features</span>
                    </div> -->
                        </div>
                    </div>
                    <div class="col-md-8 col-12">
                        <ul class="card-details-list">
                            <ng-container *ngIf="currentLang=='en' else feature_ar">
                                <li *ngFor="let val of item?.featuresList">
                                    <img src="../../../assets/images/icons/check.svg" alt="" />
                                    <span>{{val}}</span>
                                </li>
                            </ng-container>
                            <ng-template #feature_ar>
                                <li *ngFor="let val of item?.featuresList_ar">
                                    <img src="../../../assets/images/icons/check.svg" alt="" />
                                    <span>{{val}}</span>
                                </li>
                            </ng-template>

                        </ul>
                    </div>
                </div>

                <div class="col-md-6 col-12 mt-3" *ngIf="item?.eligibilityCriteriaList ">

                    <div class="inner-block-header">
                        <div class="d-flex">
                            <img src="../../../../assets/images/icons/checkmark-circle-outline.svg" alt="">
                            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="21.727" height="21.72" viewBox="0 0 21.727 21.72">
                                          <path id="np_free_338463_000000"
                                              d="M21.128,18.12a.944.944,0,1,0-.944-.944A.946.946,0,0,0,21.128,18.12Zm4.451,1.114H20.226l-1.835-2.058,1.835-2.059h5.353Zm.574-5.264H25.77V9.279A.575.575,0,0,0,25.2,8.7H24.1V6.647a.575.575,0,0,0-.574-.574H20.845a.574.574,0,0,0,0,1.149h2.1V8.7H19.736a.574.574,0,0,0,0,1.149h4.886V13.97H19.969a.572.572,0,0,0-.428.191l-2.348,2.632a.575.575,0,0,0,0,.765l2.348,2.632a.572.572,0,0,0,.428.192h4.653V24.5H13.082a.574.574,0,0,0,0,1.149H25.2a.575.575,0,0,0,.574-.574v-4.69h.383a.575.575,0,0,0,.574-.574V14.543a.575.575,0,0,0-.574-.573Zm-6.69-8.925a.574.574,0,0,0-.753.306l-8.666,20.57a.564.564,0,0,0,0,.439.574.574,0,0,0,1.061.007L19.769,5.8a.575.575,0,0,0-.306-.753ZM8.968,24.5h-1a1.82,1.82,0,0,1-1.818-1.818V9.706a1.894,1.894,0,0,0,.731.147h8.742a.574.574,0,1,0,0-1.149H6.878a.73.73,0,0,1-.729-.729V7.952a.731.731,0,0,1,.731-.73h9.852a.574.574,0,0,0,0-1.149H6.88A1.878,1.878,0,0,0,5,7.949V22.68a2.97,2.97,0,0,0,2.967,2.967h1a.574.574,0,0,0,0-1.149Z"
                                              transform="translate(-5 -5)" fill="#96f"
                                              fill-rule="evenodd" />
                                      </svg> -->
                            <span class="title-header ml-2 mr-2">{{"Eligibility criteria"|translate}}</span>
                            <img src="../../../assets/images/credit-cards/rect-down.svg" alt="" srcset="">
                            <!-- </div>
                            <img src="../../../assets/images/main_images/np_airplane.svg" alt="">
                            <span class="title-header">Features</span>
                        </div> -->
                        </div>
                        <!-- <img src="../../../assets/images/main_images/np_airplane.svg" alt="">
                            <span class="title-header">Eligibility criteria</span> -->
                    </div>

                    <div class="col-md-8 col-12">
                        <ul class="card-details-list">
                            <ng-container *ngIf="currentLang=='en' else eligiblity_ar">

                                <li *ngFor="let val of item?.eligibilityCriteriaList">
                                    <img src="../../../assets/images/icons/check.svg" alt="" /> {{val}}
                                </li>
                            </ng-container>
                            <ng-template #eligiblity_ar>
                                <li *ngFor="let val of item?.eligibilityCriteriaList_ar">
                                    <img src="../../../assets/images/icons/check.svg" alt="" /> {{val}}
                                </li>
                            </ng-template>

                        </ul>

                    </div>
                </div>

                <div class="col-md-12 col-12 mt-3" *ngIf="item?.protections">
                    <div class="row align-items-center">
                        <div class="col-md-4 col-4">
                            <div class="inner-block-header">
                                <img src="../../../../assets/images/icons/sheild.svg" alt="">
                                <span>Protection</span>
                            </div>
                        </div>
                        <div class="col-md-8 col-12">
                            <ul class="card-details-list">
                                <li *ngFor="let point of item?.protections">
                                    <img src="../../../assets/images/icons/check.svg" alt="" />
                                    <span>{{point}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-12 mt-3" *ngIf="item?.travels">
                    <div class="row align-items-center">
                        <div class="col-md-4 col-4">
                            <div class="inner-block-header">
                                <img src="../../../../assets/images/icons/sheild.svg" alt="">
                                <span>Travel</span>
                            </div>
                        </div>
                        <div class="col-md-8 col-12">
                            <ul class="card-details-list">
                                <li *ngFor="let point of item?.travels">
                                    <img src="../../../assets/images/icons/check.svg" alt="" />
                                    <span>{{point}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-12 mt-3" *ngIf="item?.rewardsPrograms">
                    <div class="row align-items-center">
                        <div class="col-md-4 col-4">
                            <div class="inner-block-header">
                                <!-- <img src="../../../assets/images/main_images/np_airplane.svg"
                                                              alt=""> -->
                                <!-- <svg xmlns="http://www.w3.org/2000/svg" width="21.433" height="27.037" viewBox="0 0 21.433 27.037">
                                                                  <svg xmlns="http://www.w3.org/2000/svg" width="12.671" height="12.197" viewBox="0 0 12.671 12.197">
                                                                      <path id="Path_4420" data-name="Path 4420" d="M38.978,25.938a2.17,2.17,0,0,0-.266-.533,2.038,2.038,0,0,0-.761-.657,2.137,2.137,0,0,0-.647-.209l-1.618-.219a.184.184,0,0,1-.124-.086l-.723-1.446a2.041,2.041,0,0,0-1.846-1.132A2.063,2.063,0,0,0,31.138,22.8l-.019.048-.466.951-.228.457a.152.152,0,0,1-.124.086l-1.608.238a1.942,1.942,0,0,0-.58.171,2.052,2.052,0,0,0-.818.685,2.112,2.112,0,0,0-.266.552,2.109,2.109,0,0,0,.533,2.1l1.17,1.123a.147.147,0,0,1,.048.124L28.5,30.924a2.022,2.022,0,0,0,.818,2,2.058,2.058,0,0,0,2.179.162l1.446-.761a.21.21,0,0,1,.152,0l1.446.752A2.065,2.065,0,0,0,37.532,30.9l-.285-1.589a.145.145,0,0,1,.048-.133l1.161-1.132a1.9,1.9,0,0,0,.257-.3,2.044,2.044,0,0,0,.266-1.8Zm-1.846.742-1.17,1.132a2.053,2.053,0,0,0-.59,1.817l.285,1.589a.14.14,0,0,1-.067.143.144.144,0,0,1-.171.019l-1.446-.742a2.059,2.059,0,0,0-1.912,0l-1.446.761a.145.145,0,0,1-.171-.019.141.141,0,0,1-.067-.143l.276-1.589a2.031,2.031,0,0,0-.609-1.817l-1.161-1.123a.139.139,0,0,1,.086-.247l1.608-.238A2.075,2.075,0,0,0,32.127,25.1v-.01l.723-1.446a.148.148,0,0,1,.143-.086.142.142,0,0,1,.143.086l.076.152.485.951.171.343A2.057,2.057,0,0,0,35.42,26.2l1.618.228a.145.145,0,0,1,.133.1.185.185,0,0,1-.038.152Z" transform="translate(-26.669 -21.406)" fill="#96f" stroke="#fff" stroke-width="0.5"/>
                                                                    </svg>

                                                                  <path id="Path_4421" data-name="Path 4421" d="M22.967,6.437a10.46,10.46,0,0,0-7.612,17.641v7.935a.948.948,0,0,0,.447.809.987.987,0,0,0,.932.048l2.731-1.37,3.111,1.389a1.009,1.009,0,0,0,.78,0L26.469,31.5,29.2,32.87a.987.987,0,0,0,.932-.048.951.951,0,0,0,.447-.809V24.078A10.46,10.46,0,0,0,22.968,6.437Zm5.709,24.035-1.76-.875a.961.961,0,0,0-.809-.019l-3.14,1.4-3.14-1.4a.912.912,0,0,0-.809.019l-1.76.875v-4.8a10.42,10.42,0,0,0,11.418,0Zm.951-8.2a7.947,7.947,0,0,1-.951,1.009,8.541,8.541,0,0,1-11.418,0,7.947,7.947,0,0,1-.951-1.009,8.11,8.11,0,0,1-.951-1.456A8.448,8.448,0,0,1,14.4,16.9a8.563,8.563,0,0,1,17.127,0,8.448,8.448,0,0,1-.951,3.911,8.108,8.108,0,0,1-.951,1.456Z" transform="translate(-12.251 -6.187)" fill="#96f" stroke="#fff" stroke-width="0.5"/>
                                                                </svg> -->

                                <!-- <svg xmlns="http://www.w3.org/2000/svg" width="21.433" height="27.037" viewBox="0 0 21.433 27.037">
                                          <g id="np_featured_2858532_000000"
                                              transform="translate(0.25 0.25)">
                                              <path id="Path_4420" data-name="Path 4420"
                                                  d="M38.978,25.938a2.17,2.17,0,0,0-.266-.533,2.038,2.038,0,0,0-.761-.657,2.137,2.137,0,0,0-.647-.209l-1.618-.219a.184.184,0,0,1-.124-.086l-.723-1.446a2.041,2.041,0,0,0-1.846-1.132A2.063,2.063,0,0,0,31.138,22.8l-.019.048-.466.951-.228.457a.152.152,0,0,1-.124.086l-1.608.238a1.942,1.942,0,0,0-.58.171,2.052,2.052,0,0,0-.818.685,2.112,2.112,0,0,0-.266.552,2.109,2.109,0,0,0,.533,2.1l1.17,1.123a.147.147,0,0,1,.048.124L28.5,30.924a2.022,2.022,0,0,0,.818,2,2.058,2.058,0,0,0,2.179.162l1.446-.761a.21.21,0,0,1,.152,0l1.446.752A2.065,2.065,0,0,0,37.532,30.9l-.285-1.589a.145.145,0,0,1,.048-.133l1.161-1.132a1.9,1.9,0,0,0,.257-.3,2.044,2.044,0,0,0,.266-1.8Zm-1.846.742-1.17,1.132a2.053,2.053,0,0,0-.59,1.817l.285,1.589a.14.14,0,0,1-.067.143.144.144,0,0,1-.171.019l-1.446-.742a2.059,2.059,0,0,0-1.912,0l-1.446.761a.145.145,0,0,1-.171-.019.141.141,0,0,1-.067-.143l.276-1.589a2.031,2.031,0,0,0-.609-1.817l-1.161-1.123a.139.139,0,0,1,.086-.247l1.608-.238A2.075,2.075,0,0,0,32.127,25.1v-.01l.723-1.446a.148.148,0,0,1,.143-.086.142.142,0,0,1,.143.086l.076.152.485.951.171.343A2.057,2.057,0,0,0,35.42,26.2l1.618.228a.145.145,0,0,1,.133.1.185.185,0,0,1-.038.152Z"
                                                  transform="translate(-22.537 -17.022)"
                                                  fill="#96f" stroke="#fff"
                                                  stroke-width="0.5" />
                                              <path id="Path_4421" data-name="Path 4421"
                                                  d="M22.967,6.437a10.46,10.46,0,0,0-7.612,17.641v7.935a.948.948,0,0,0,.447.809.987.987,0,0,0,.932.048l2.731-1.37,3.111,1.389a1.009,1.009,0,0,0,.78,0L26.469,31.5,29.2,32.87a.987.987,0,0,0,.932-.048.951.951,0,0,0,.447-.809V24.078A10.46,10.46,0,0,0,22.968,6.437Zm5.709,24.035-1.76-.875a.961.961,0,0,0-.809-.019l-3.14,1.4-3.14-1.4a.912.912,0,0,0-.809.019l-1.76.875v-4.8a10.42,10.42,0,0,0,11.418,0Zm.951-8.2a7.947,7.947,0,0,1-.951,1.009,8.541,8.541,0,0,1-11.418,0,7.947,7.947,0,0,1-.951-1.009,8.11,8.11,0,0,1-.951-1.456A8.448,8.448,0,0,1,14.4,16.9a8.563,8.563,0,0,1,17.127,0,8.448,8.448,0,0,1-.951,3.911,8.108,8.108,0,0,1-.951,1.456Z"
                                                  transform="translate(-12.501 -6.437)"
                                                  fill="#96f" stroke="#fff"
                                                  stroke-width="0.5" />
                                          </g>
                                      </svg> -->

                                <img src="../../../../assets/images/icons/win.svg" alt="">

                                <span>Reward Programs</span>
                            </div>
                        </div>
                        <div class="col-md-8 col-8">
                            <ul class="card-details-list">
                                <li *ngFor="let point of item?.rewardsPrograms">
                                    <img src="../../../assets/images/icons/check.svg" alt="" />
                                    <span>{{point}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-12 mt-3" *ngIf="item?.listOthers">
                    <div class="row align-items-center">
                        <div class="col-md-4 col-4">
                            <div class="inner-block-header">
                                <img src="../../../../assets/images/icons/star.svg" alt="">
                                <span>Others</span>
                            </div>
                        </div>
                        <div class="col-md-8 col-8">
                            <ul class="card-details-list">
                                <li *ngFor="let point of item?.listOthers">
                                    <img src="../../../assets/images/icons/check.svg" alt="" />
                                    <span>{{point}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<div *ngIf="catName=='CreditCard'">
    <div class="card_details_starts">
        <div class="row wrapper">
            <div class="col-md-12 col-12 mt-3 pl-0 pr-0">
                <div class="row">
                    <div class="inner-block-header pl-15">
                        <img src="../../../assets/images/icons/Union.svg" alt="" />
                        <p>{{"Fees"|translate}}</p>
                        <!-- <img src="../../../assets/images//credit-cards/rect-down.svg" alt="" /> -->
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-md-12 col-12">
                        <div class="row fees-sec">

                            <!-- <div class="col-md-4 col-4">
                          <div class="fees-block">
                              <p>Annual</p>
                              <span>SAR {{item?.annualFee}}</span>
                          </div> <div class="col-md-2 col-12">
                                <div class="fees-block">
                                    <span [ngClass]="{ highlighted: item?.overseasPaymentFee === 0 }">
                                      {{item?.overseasPaymentFee|convertNumbers:currentLang}}
                                       {{"SAR"|translate}}</span>
                                    <p>{{"Overseas Payment"|translate}} </p>
                                </div>
                            </div>
                      </div> -->
                            <div class="col-md-4 col-12">
                                <div class="fees-block pr-0">
                                    <span [ngClass]="{ highlighted: item?.annualFee === 0 }">
                              {{item?.annualFee|convertNumbers:currentLang}}
                               {{"SAR"|translate}}</span>
                                    <p>{{"Recurring Annual Fee"|translate}} </p>
                                </div>
                                <hr>
                            </div>
                            <div class="col-md-4 col-12">
                                <div class="fees-block">
                                    <span>{{item?.murabahaMargin | convertNumbers:currentLang}}%</span>
                                    <p>{{"Murabaha Margin"|translate}} </p>
                                </div>
                                <hr>

                            </div>
                            <div class="col-md-4 col-12">
                                <div class="fees-block">
                                    <span [ngClass]="{ highlighted: item?.minimumSalary === 0 }">
                            {{item?.minimumSalary|convertNumbers:currentLang}} {{"SAR"}}
                            </span>
                                    <p>{{"Minimum Monthly Salary"|translate}} </p>
                                </div>
                                <hr>

                            </div>
                            <div class="col-md-4 col-12">
                                <div class="fees-block pr-md-5 pr-0">
                                    <span [ngClass]="{ highlighted: item?.monthly_Fees === 0 }">
                          {{item?.monthly_Fees|convertNumbers:currentLang}}
                           {{"SAR"|translate}}</span>
                                    <p>{{"Monthly Minimum Payment"|translate}} </p>
                                </div>
                                <hr>

                            </div>

                            <div class="col-md-4 col-12">
                                <div class="fees-block">
                                    <span [ngClass]="{ highlighted: item?.overseasPaymentFee === 0 }">
                              {{item?.overseasPaymentFee|convertNumbers:currentLang}}
                               {{"SAR"|translate}}</span>
                                    <p>{{"Overseas Payment"|translate}} </p>
                                </div>
                                <hr>

                            </div>

                            <div class="col-md-4 col-12">
                                <div class="fees-block">
                                    <span [ngClass]="{ highlighted: item?.supplementaryCardFee === 0 }">
                                  {{item?.supplementaryCardFee|convertNumbers:currentLang}}
                                   {{"SAR"|translate}}</span>
                                    <p>{{"Supplementary card"|translate}}</p>
                                </div>
                                <hr>

                            </div>

                            <div class="col-md-4 col-12">
                                <div class="fees-block">
                                    <span [ngClass]="{ highlighted: item?.cardReplacementFee === 0 }">
                                    {{item?.cardReplacementFee|convertNumbers:currentLang}}
                                    {{"SAR"|translate}}</span>
                                    <p>{{"Card Replacement"|translate}}</p>

                                </div>
                                <hr>

                            </div>
                            <div class="col-md-4 col-12">
                                <div class="fees-block">
                                    <span [ngClass]="{ highlighted: item?.cashWithdrawalFee === 0 }">
                                  {{item?.cashWithdrawalFee|convertNumbers:currentLang}}
                                   {{"SAR"|translate}}</span>
                                    <p>{{"Cash Withdrawal"|translate}}</p>
                                </div>
                                <hr>

                            </div>
                            <div class="col-md-4 col-12">
                                <div class="fees-block">
                                    <span [ngClass]="{ highlighted: item?.latePaymentFee === 0 }">
                                  {{item?.latePaymentFee}}  {{"SAR"|translate}}</span>
                                    <p>{{"Late Payment"|translate}}</p>
                                </div>
                                <hr>

                            </div>
                            <div class="col-md-4 col-12">
                                <div class="fees-block">
                                    <span [ngClass]="{ highlighted: item?.collectionFee === 0 }">
                                      {{item?.collectionFee|convertNumbers:currentLang}} {{"SAR"|translate}}</span>
                                    <p>{{"Collection"|translate}}</p>
                                </div>
                                <hr>

                            </div>
                            <div class="col-md-4 col-12">
                                <div class="fees-block pr-5">
                                    <span [ngClass]="{ highlighted: item?.foreignExchangeConversionFee === 0 }">{{item?.foreignExchangeConversionFee|convertNumbers:currentLang}}%</span>
                                    <p>{{"Foreign Exchange Conversion"|translate}}</p>
                                </div>
                                <hr>

                            </div>
                            <div class="col-md-4 col-12">
                                <div class="fees-block">
                                    <span [ngClass]="{ highlighted: item?.chequeReturnedFee === 0 }">
                                      {{item?.chequeReturnedFee|convertNumbers:currentLang}}
                                       {{"SAR"|translate}}</span>
                                    <p>{{"Cheque Returned"|translate}}</p>
                                </div>
                                <hr>

                            </div>
                            <div class="col-md-4 col-12">
                                <div class="fees-block">
                                    <span [ngClass]="{ highlighted: item?.statementRequestFee === 0 }">
                                      {{item?.statementRequestFee|convertNumbers:currentLang}}
                                       {{"SAR"|translate}}</span>
                                    <p>{{"Statement Request"|translate}}</p>
                                </div>
                                <hr>

                            </div>
                            <div class="col-md-4 col-12">
                                <div class="fees-block">
                                    <span [ngClass]="{ highlighted: item?.refundOfCreditBalanceFee === 0 }">
                                      {{item?.refundOfCreditBalanceFee|convertNumbers:currentLang}} {{"SAR"|translate}}</span>
                                    <p class="pr-md-2 pr-0">{{"Refund Of Credit Balance"|translate}}</p>
                                </div>
                                <hr>

                            </div>
                            <div class="col-md-4 col-12">
                                <div class="fees-block">
                                    <span [ngClass]="{ highlighted: item?.directDebitRejectionFee === 0 }">
                                      {{item?.directDebitRejectionFee|convertNumbers:currentLang}}
                                       {{"SAR"|translate}}</span>
                                    <p>{{"Direct Debit Rejection"|translate}}</p>
                                </div>
                                <!-- <hr> -->

                            </div>
                            <div class="col-md-4 col-12">
                                <div class="fees-block pr-0">
                                    <span [ngClass]="{ highlighted: item?.disputeHandlingFee === 0 }">
                                      {{item?.disputeHandlingFee|convertNumbers:currentLang}}
                                       {{"SAR"|translate}}</span>
                                    <p>{{"Dispute Handling"|translate}}</p>
                                </div>
                                <!-- <hr> -->

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 col-12 mt-3" *ngIf="item?.protections">
                    <div class="">
                        <div class="">
                            <div class="inner-block-header">
                                <img src="../../../assets/images/icons/sheild.svg" alt="">
                                <p>{{"Protection"|translate}}</p>
                            </div>
                        </div>
                        <div class="protection-block">
                            <ul class="card-details-list">
                                <ng-container *ngIf='currentLang == "en";else protection_ar'>
                                    <li *ngFor="let point of item?.protections">
                                        <img src="../../../assets/images/icons/check.svg" alt="" />
                                        <span>{{point}}</span>
                                    </li>
                                </ng-container>
                                <ng-template #protection_ar>

                                    <li *ngFor="let point of item?.protections_ar">
                                        <img src="../../../assets/images/icons/check.svg" alt="" />
                                        <span>{{point}}</span>
                                    </li>
                                </ng-template>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-12 mt-3" *ngIf="item?.travels">
                    <div class="">
                        <div class="">
                            <div class="inner-block-header">
                                <img src="../../../assets/images/icons/sheild.svg" alt="">
                                <p>{{"Travel"|translate}}</p>
                                <!-- <img src="../../../assets/images//credit-cards/rect-down.svg" alt="" /> -->
                            </div>
                        </div>
                        <div class="protection-block">
                            <ul class="card-details-list">
                                <ng-container *ngIf='currentLang == "en";else travel_ar'>
                                    <li *ngFor="let point of item?.travels">
                                        <img src="../../../assets/images/icons/check.svg" alt="" />
                                        <span>{{point}}</span>
                                    </li>
                                </ng-container>
                                <ng-template #travel_ar>
                                    <li *ngFor="let point of item?.travels_ar">
                                        <img src="../../../assets/images/icons/check.svg" alt="" />
                                        <span>{{point}}</span>
                                    </li>

                                </ng-template>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-12 mt-3" *ngIf="item?.rewardsPrograms">
                    <div class="">
                        <div class="">
                            <div class="inner-block-header">
                                <img src="../../../assets/images/icons/win.svg" alt="">
                                <p>{{"Reward"|translate}}</p>
                                <!-- <img src="../../../assets/images//credit-cards/rect-down.svg" alt="" /> -->
                            </div>
                        </div>
                        <div class="protection-block">
                            <ul class="card-details-list">
                                <ng-container *ngIf='currentLang == "en";else reward_ar'>

                                    <li *ngFor="let point of item?.rewardsPrograms">
                                        <img src="../../../assets/images/icons/check.svg" alt="" />
                                        <span>{{point}}</span>
                                    </li>
                                </ng-container>
                                <ng-template #reward_ar>
                                    <li *ngFor="let point of item?.rewardsPrograms_ar">
                                        <img src="../../../assets/images/icons/check.svg" alt="" />
                                        <span>{{point}}</span>
                                    </li>
                                </ng-template>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-12 mt-3" *ngIf="item?.listOthers">
                    <div class="">
                        <div class="">
                            <div class="inner-block-header">
                                <img src="../../../assets/images/icons/star.svg" alt="">
                                <p>{{"Other"|translate}}</p>
                                <!-- <img src="../../../assets/images//credit-cards/rect-down.svg" alt="" /> -->
                            </div>
                        </div>
                        <div class="protection-block">
                            <ul class="card-details-list">
                                <ng-container *ngIf='currentLang == "en";else other_ar'>

                                    <li *ngFor="let point of item?.listOthers">
                                        <img src="../../../assets/images/icons/check.svg" alt="" />
                                        <span>{{point}}</span>
                                    </li>
                                </ng-container>
                                <ng-template #other_ar>
                                    <li *ngFor="let point of item?.listOthers_ar">
                                        <img src="../../../assets/images/icons/check.svg" alt="" />
                                        <span>{{point}}</span>
                                    </li>
                                </ng-template>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
