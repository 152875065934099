<section id="myHeader" class="header_part">
    <!-- <div class="topbar">
        <div class="container">
            <div class="topbar-inner">
                <div class="contact-info-top d-flex">
                    <a><i class="fas fa-phone"></i> 920001273</a>
                    <a><i class="far fa-envelope"></i> help@creditly.sa</a>
                    <a>Corporate Banking</a>
                </div>


            </div>
        </div>
    </div> -->

    <div class="header-menu-main head-back" *ngIf="!loggedId">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light">
                <a class="navbar-brand" [routerLink]="['/creditly/LandingPage']" routerLinkActive="active">
                    <img class="header-logo whitelogo" src="/assets/images/icons/Group 3681.svg" alt="" />
                    <!-- <img class="header-logo whitelogo" src="/assets/images/header-images/header-main-logo.png" alt="" /> -->
                </a>
                <!-- <a class="navbar-brand" [routerLink]="['/creditly/LandingPage']">
                    <span *ngIf='!whiteStyle' class="logo-title desktop" [ngClass]="{ white: whiteStyle == true }">Creditly</span>
                </a> -->
                <button id="navbar-toggler" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span [ngClass]="{ white: whiteStyle == true }" class="navbar-toggler-icon"></span>
        </button>

                <div class="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
                    <ul class="navbar-nav" [ngClass]="{'mr-auto':currentLang == 'ar'}">
                        <li class="nav-item active">
                            <a [routerLink]="['/creditly/cards-list']" routerLinkActive="active" class="nav-link">{{"Credit Card"|translate}}</a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="['/creditly/personalFinance']" routerLinkActive="active" class="nav-link">{{"Personal finance"|translate}}</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/creditly/autoLease']" routerLinkActive="active">{{"Auto lease"|translate}}</a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="['/creditly/mortgage']" routerLinkActive="active" class="nav-link">{{"Mortgage"|translate}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/creditly/bank-account']" routerLinkActive="active">{{"Bank account"|translate}}</a>
                        </li>
                    </ul>
                </div>
                <div class="login-lang d-flex align-items-center">
                    <div class="lang-change">
                        <span (click)="changeLanguge('en')" *ngIf="!showEnglishLogo"><img
          src="../../../assets/images/header-images/icons8-saudi-arabia-96.png" alt="ar" width="25px" />
        AR
      </span>
                        <span (click)="changeLanguge('ar')" *ngIf="showEnglishLogo"><img
          src="../../../assets/images/header-images/icons8-usa-96.png" alt="en" width="25px" />
        EN</span>
                    </div>

                    <div class="btn-group" *ngIf="profileDate" class="loggedUer-drop-down">
                        <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{profileDate.firstName}}
          </button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <button class="dropdown-item" type="button" routerLink="/creditly/userProfile">Profile</button>
                            <button class="dropdown-item" type="button">Settings</button>
                            <button class="dropdown-item" type="button" (click)="navigate('request')">Requests</button>
                            <button class="dropdown-item" type="button" (click)="navigate('support')">Support</button>
                            <button class="dropdown-item signout-btn" type="button" (click)="logout()">Sign Out</button>
                        </div>
                    </div>

                    <div class="btn-group ml-5" *ngIf="!profileDate">
                        <button type="button" class="btn btn-success" routerLink='/login'>
            <img src="../../../assets/images/icons/icon-lock.svg" alt="login"> Sign In
          </button>
                        <!-- <div class="dropdown-menu dropdown-menu-right" [ngClass]="{'show':toggleDropdown}">
                            <form [formGroup]="formGroup" (ngSubmit)="loginProcess()">
                                <div class="form-group">
                                    <input type="tel" autocomplete="tel" class='text-light' placeholder="Mobile Number*" formControlName="Mobile" [ngClass]="{validationClass: loginFormValidationFlag && formGroup.controls['Mobile'].errors}">
                                </div>
                                <div class="form-group">
                                    <input autocomplete="current-password" type="password" class='text-light' placeholder="Password*" formControlName="password" [ngClass]="{validationClass: loginFormValidationFlag && formGroup.controls['password'].errors}">
                                </div>

                                <button *ngIf="!sendOTPFlag" class="btn  get-starts-btn" [disabled]="formGroup.invalid||submit" type="submit">Receive OTP</button>
                            </form>
                            <div *ngIf="sendOTPFlag">
                                <div class="form-group" [formGroup]='otpForm'>
                                    <input inputtype="numeric" autocomplete="one-time-code" id='idNumber' type="text" formControlName="OTP" placeholder="OTP*">
                                </div>

                                <button class="btn get-starts-btn " [disabled]="submit||otpForm?.invalid" (click)="loginVerifytOtp()" type="button">Sign In</button>
                            </div>
                            <button class="dropdown-item forgotPass" type="button" routerLink="/forgotpass">Forgot Password?</button>
                            <p>New USer?</p>
                            <button class="dropdown-item signout-btn" type="button" [routerLink]="['/register']">Sign Up Now</button>

                        </div> -->
                    </div>


                </div>
            </nav>
        </div>
    </div>
</section>