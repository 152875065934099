<div class="container" *ngIf='formData'>
    <div class="row">
        <form [formGroup]="formGroup" class="w-100">
            <!-- <div class="col-md-12">
                <p class="">Obligations</p>
            </div> -->
            <div class="col-md-12">
                <div class="row">
                    <ng-container *ngFor='let form of formData'>

                        <div class="col-md-4 col-12 edit-detial-shared" [ngClass]="{'col-md-3 edit-detial-shared_p':type=='apply','col-md-4':type!='apply'}" *ngIf='form.visible && formGroup?.get(form.formControl)'>
                            <div class="edit-detial  d-flex  align-items-center">
                                <!-- {{form}} -->
                                <p class="opacity" [ngClass]="{'validTitle':loginFormValidationFlag&&formGroup.controls[form.formControl].errors}">{{form?.labelName}}</p>

                                <!-- <img *ngIf='form?.isEditable' (click)="enableEdit(formGroup?.controls[form?.formControl])" class="ml-2" src="../../../../assets/images/icons/Icon feather-edit-3.png" alt=""> -->

                            </div>
                            <ng-container *ngIf='!form?.isEditable'>

                                <ng-container *ngIf="form?.formControl !== 'titleName'">

                                    <p *ngIf="formGroup?.controls[form?.formControl]?.pristine"><b>{{formGroup?.controls[form?.formControl]?.value  ||formGroup?.controls[form?.formControl]?.value ==0?form.menuData?(formGroup?.controls[form?.formControl]?.value|getName:form?.menuData:currentLang ):formGroup?.controls[form?.formControl]?.value:'NA'}}</b></p>
                                </ng-container>
                                <ng-container *ngIf="form?.formControl == 'titleName'">

                                    <p *ngIf="formGroup?.controls[form?.formControl]?.pristine"><b>{{formGroup?.controls[form?.formControl]?.value !=null?formGroup?.controls[form?.formControl]?.value:'NA'}}</b></p>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf='form?.isEditable'>
                                <div class="form-field" [ngClass]="{
                                  validationClass:
                                    loginFormValidationFlag&&formGroup.controls[form.formControl].errors
                                }">
                                    <!-- ||
                                (formGroup?.controls[form?.formControl]?.errors&&formGroup?.controls[form?.formControl]?.touched) -->
                                    <div class="form-group" [ngClass]="{'mb-4':!form?.selectMenu,'mb-5':form?.selectMenu}">
                                        <!-- *ngIf="!formGroup?.controls[form?.formControl]?.pristine"> -->

                                        <input *ngIf='!form?.selectMenu' (change)="changeUpdate(formGroup?.controls[form?.formControl])" type="text" class="form-control" [placeholder]="form?.labelName" [formControlName]="form?.formControl" [ngClass]="{
                    validationClass:
                      loginFormValidationFlag&&formGroup.controls[form.formControl].errors
                  }">
                                        <!-- <p *ngIf="formGroup.controls['otherSourceOfIncomeID'].pristine">
                    <b>   {{filterName(sourceOfIncomes, this.formGroup.controls['otherSourceOfIncomeID'].value)}}</b>
                </p> -->
                                        <ng-container *ngIf='form?.selectMenu'>

                                            <!-- <select *ngIf="!formGroup?.controls[form?.formControl]?.pristine" -->
                                            <select (change)="changeUpdate(formGroup.controls[form.formControl])" name="cars" id="cars" class="edit-select" [formControlName]="form.formControl">
                                           <option [value]='!formGroup.controls[form.formControl].value ||formGroup.controls[form.formControl].value =="None"||formGroup.controls[form.formControl].value ==0?formGroup.controls[form.formControl].value:""' selected disabled>Please Select {{form.labelName}}</option>
                                          <option *ngFor="let res of form?.menuData;let i=index;" [value]="res.id?res.id:res">{{res.name?res.name:res == true? "YES" : res == false?"NO":res}}</option>
                  </select>
                                        </ng-container>
                                    </div>
                                    <p *ngIf='loginFormValidationFlag &&formGroup.controls[form.formControl].errors' class='validTitle'>
                                        *This field is required
                                    </p>
                                </div>

                            </ng-container>

                        </div>
                    </ng-container>
                </div>
            </div>
        </form>
    </div>
</div>