import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'app-rejected-requests',
    templateUrl: './rejected-requests.component.html',
    styleUrls: ['./rejected-requests.component.scss'],
  })
  export class RejectedRequestComponent implements OnInit {
      ngOnInit(): void {
          console.log("I am rejected")
      }
  }