import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-profile-form-shared',
  templateUrl: './profile-form-shared.component.html',
  styleUrls: ['./profile-form-shared.component.scss']
})
export class ProfileFormSharedComponent implements OnInit {
@Input() formComingData:{
  formGroup: FormGroup,
  formData,
  formLabels,
  type

}
@Input() loginFormValidationFlag;

@Output() newItemEvent = new EventEmitter<any>();
  formGroup: FormGroup;
  formData: any;
  type: any;
  currentLang: string;
  // itrForm: string[];
  constructor(private translate: TranslateService) {
    // console.log(this.formComingData.formGroup);
    // console.log(this.formGroup);

  }

  ngOnInit(): void {
    // setTimeout(() => {

      this.formGroup = this.formComingData.formGroup;
      this.formData = this.formComingData.formData;
      this.type = this.formComingData.type;

      // this.itrForm = this.formComingData.formLabels
    // },0);
    // this.loginFormValidationFlag?.subscribe(res=>{
    //   console.log(res)
    // })

   console.log(this.formData);
   console.log(this.formGroup);
  //  console.log(this.itrForm);
  this.currentLang = localStorage.getItem('currentLang')
this.translate.onLangChange.subscribe(lang=>{

  this.currentLang = lang.lang
})

  }
  enableEdit(formG){
    formG.pristine = !formG.pristine;
    formG.updateValueAndValidity()
    // this.cdr.detectChanges()

    console.log(formG)
  }

  changeUpdate(form){
//  form.pristine=true;
 console.log(form);
 this.newItemEvent.emit()
//  this.update()
console.log(this.loginFormValidationFlag)
  }


  filterName(data: any, id: number | string) {
    console.log(data);

    if (data?.length && data?.filter((val) => val.id == id)[0]?.name)
      return data.filter((val) => val.id == id)[0].name;
    else return 'NA';
  }
}
