import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { banks } from 'src/app/shared/data/list-menus';

@Component({
  selector: 'app-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss'],
})
export class ProfileFormComponent implements OnInit {
  profileDate = JSON.parse(localStorage.getItem('loginResponse'));
  // bank
  banks = JSON.parse(localStorage.getItem('masterData'))?.banks?JSON.parse(localStorage.getItem('masterData'))?.banks:banks ;
  showCardDetailsFlag: boolean = false;
  bankType = 'select';
  loginFormValidationFlag: boolean = false;
  bankGroup: FormGroup;

  product = [JSON.parse(sessionStorage.getItem('selectedCard'))];
  constructor() {}

  @Input() public userProfile = false

  ngOnInit(): void {
    this.bankForm();
  }
  private bankForm() {
    this.bankGroup = new FormGroup({
      userID: new FormControl(
        this.profileDate?.userPersonalEmployement?.userID
      ),
      bankAccountID: new FormControl(
        this.profileDate?.userBankAccount?.bankAccountID,
        [Validators.required]
      ),
      iBAN: new FormControl(this.profileDate?.userBankAccount?.iban, [
        Validators.required,
      ]),
      isSave: new FormControl(true, [Validators.required]),
    });

    const filteredBankGroup = this.banks?.filter(
      (val) => val.id == this.profileDate?.userBankAccount?.bankAccountID
    );


    if(filteredBankGroup?.length > 0) {
      this.bankGroup.controls.bankAccountID.setValue (filteredBankGroup[0]?.name)
    }
  }
  filter(event) {
    let id = event.target.value;
    // this.bankGroup.patchValue({bankAccountID:parseInt(id)});
    const filteredBanks = this.banks.filter((val) => val.id == id)

    if(filteredBanks.length > 0) {
      this.bankType = filteredBanks[0].name;
    }
  }
  apply() {}
}
