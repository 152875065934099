export const NATURE_BUSSNIESS = [
  "Advertising/Marketing",
  "Agriculture and Forestry",
 "Architectural Services",
  "Arts",
  "Banking",
  "Biotechnology and Pharmaceutical",
  "Building Materials",
  "Communications",
  "Construction",
  "Consulting Services",
  "Distribution",
  "Educational Services",
  "Engineering",
  "Environmental",
  "Financial & Insurance",
  "Food Services",
  "Health Care and Social Assistance",
  "Hospitality/Tourism",
  "Information Technology",
  "Legal Services",
  "Manufacturing",
  "Military",
  "Oil",
  "Other",
  "Public Relations",
  "Publishing/Printing",
  "Real Estate",
  "Retail",
  "State",
  "Telecommunications",
  "Transportation",
  "Utility - Electric",
  "Utility - Other",
  "Wholesale"
];

export const DEPARTMENTS = [
  "Production",
  "Research and Development",
  "Purchasing",
  "Marketing",
  "Human Resource",
  "Accounting and Finance.",
  "Sales",
  "IT",
  "Technology and equipment",
  "Strategy",
  "Operations",
  "Top Management"
];


export const TITLES = [
  "Mr.",
  "Ms.",
  "Mrs.",
  "Dr."
];

export const PRIMARY_INCOMES=[
  {name:"Salary",id:1},
  {name:"Self-employed",id:2},
  {name:"Subsidy",id:3},
  {name:"Commercial Business",id:4},
  {name:"Rental Proceeds",id:5},
  {name:"Investment Products",id:6},
  {name:"Pension",id:7},
  {name:"Inheritance",id:8},
  {name:"From Family Member",id:9},
  {name:"Other",id:10}
];

export const EXTRA_INCOMES=[
  {name:"Bonus",id:1},
  {name:"Equity Investments",id:2},
  {name:"Investment Products",id:3},
  {name:"Stocks",id:4},
  {name:"Rental Proceeds",id:5},
  {name:"From Family Member",id:6}
];

export const  CARD_PURPOSE = [
  "All",
  "PersonalPurchase",
  "Travel",
  "Withdrawal",
];

export const  LOAN_PURPOSE = [
  "Home Improvement",
  "Vacation",
  "Moving",
  "Emergecy Expense",
  "Bussines",
  "Refinance a credit card",
  "Consolidate debt",
  "Other",
];

export const MOTRAGEG_PUROPSE = [
  "Ready Unit",
  "Incomplete",
  "Self Construction ",
  "Land",
  "On plan "
]
export const MOTRAGEG_TYPE = [
  "Villa",
  "Apartment",
  "Floor",
  "Dublex"
]


export const banks =[
  {
      "isBankAPIAvailable": false,
      "isFetchBankAPIAvailable": true,
      "id": 15,
      "name": "meem by GIB",
      "name_ar": "حساب ميم ",
      "userName": "meem@creditly.com",
      "image": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/banks/bank-1633517162.svg",
      "imageName": "bank-1633517162.svg",
      "active_Status": true,
      "selected": false,
      "source": "Bank",
      "formLink": "https://apply.meem.sa/Transactions/Customer/OnBoard/Step1.aspx?Culture=ae&PID=",
      "description": "Open a bank account online!",
      "description_ar": "إفتح حسابك الجاري",
      "isAccOpenAvail": true,
      "isProductAvail": true,
      "isIBANAvail": true,
      "bankCode": 0,
      "masterCategoryIDs": "",
      "loanCategoryMasterIDs": "",
      "masterCategories": [],
      "loanCategoryMasters": [],
      "bankUsers": [],
      "imageDetails": [
          {
              "id": 0,
              "name": "bank-1633517162.svg",
              "baseURL": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/",
              "fullURL": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/banks/bank-1633517162.svg",
              "isPrimary": true,
              "fileType": "BANK",
              "responseStatusCode": 0
          }
      ],
      "bankEnquiryList": [],
      "bankUserTypes": [],
      "bankAccounts": [],
      "responseStatusCode": 0
  },
  {
      "isBankAPIAvailable": false,
      "isFetchBankAPIAvailable": false,
      "id": 20,
      "name": "Amlak International",
      "name_ar": "أملاك العالمية",
      "userName": "Amlak@creditly.com",
      "image": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/banks/bank-1633517279.svg",
      "imageName": "bank-1633517279.svg",
      "active_Status": true,
      "selected": false,
      "source": "Bank",
      "formLink": "-",
      "description": "-",
      "description_ar": "-",
      "isAccOpenAvail": false,
      "isProductAvail": true,
      "isIBANAvail": false,
      "bankCode": 0,
      "masterCategoryIDs": "",
      "loanCategoryMasterIDs": "",
      "masterCategories": [],
      "loanCategoryMasters": [],
      "bankUsers": [],
      "imageDetails": [
          {
              "id": 0,
              "name": "bank-1633517279.svg",
              "baseURL": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/",
              "fullURL": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/banks/bank-1633517279.svg",
              "isPrimary": true,
              "fileType": "BANK",
              "responseStatusCode": 0
          }
      ],
      "bankEnquiryList": [],
      "bankUserTypes": [],
      "bankAccounts": [],
      "responseStatusCode": 0
  },
  {
      "isBankAPIAvailable": false,
      "isFetchBankAPIAvailable": false,
      "id": 21,
      "name": "Nayifat",
      "name_ar": "النايفات",
      "userName": "Nayifat@creditly.com",
      "image": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/banks/bank-1633517301.svg",
      "imageName": "bank-1633517301.svg",
      "active_Status": true,
      "selected": false,
      "source": "Bank",
      "formLink": "N/A",
      "description": "Nayfiat Finance",
      "description_ar": "النايفات للتمويل",
      "isAccOpenAvail": false,
      "isProductAvail": true,
      "isIBANAvail": false,
      "bankCode": 0,
      "masterCategoryIDs": "",
      "loanCategoryMasterIDs": "",
      "masterCategories": [],
      "loanCategoryMasters": [],
      "bankUsers": [],
      "imageDetails": [
          {
              "id": 0,
              "name": "bank-1633517301.svg",
              "baseURL": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/",
              "fullURL": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/banks/bank-1633517301.svg",
              "isPrimary": true,
              "fileType": "BANK",
              "responseStatusCode": 0
          }
      ],
      "bankEnquiryList": [],
      "bankUserTypes": [],
      "bankAccounts": [],
      "responseStatusCode": 0
  },
  {
      "isBankAPIAvailable": false,
      "isFetchBankAPIAvailable": false,
      "id": 4,
      "name": "Bank Albilad",
      "name_ar": "بنك البلاد",
      "userName": "albilad@creditly.com",
      "image": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/banks/bank-1633517347.svg",
      "imageName": "bank-1633517347.svg",
      "active_Status": true,
      "selected": false,
      "source": "Bank",
      "formLink": "https://rib.bankalbilad.com/digi/nc/registration-init?lang=ar",
      "description": "Open a bank account online!",
      "description_ar": "إفتح حسابك الجاري",
      "isAccOpenAvail": true,
      "isProductAvail": true,
      "isIBANAvail": true,
      "bankCode": 0,
      "masterCategoryIDs": "",
      "loanCategoryMasterIDs": "",
      "masterCategories": [],
      "loanCategoryMasters": [],
      "bankUsers": [],
      "imageDetails": [
          {
              "id": 0,
              "name": "bank-1633517347.svg",
              "baseURL": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/",
              "fullURL": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/banks/bank-1633517347.svg",
              "isPrimary": true,
              "fileType": "BANK",
              "responseStatusCode": 0
          }
      ],
      "bankEnquiryList": [],
      "bankUserTypes": [],
      "bankAccounts": [],
      "responseStatusCode": 0
  },
  {
      "isBankAPIAvailable": false,
      "isFetchBankAPIAvailable": false,
      "id": 5,
      "name": "American Express",
      "name_ar": "أمريكان إكسبريس",
      "userName": "americanexpress@creditly.com",
      "image": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/banks/bank-1633517363.svg",
      "imageName": "bank-1633517363.svg",
      "active_Status": true,
      "selected": false,
      "source": "Bank",
      "formLink": "https://www.americanexpress.com.sa/",
      "description": "Open a bank account online!",
      "description_ar": "إفتح حسابك الجاري",
      "isAccOpenAvail": false,
      "isProductAvail": true,
      "isIBANAvail": false,
      "bankCode": 0,
      "masterCategoryIDs": "",
      "loanCategoryMasterIDs": "",
      "masterCategories": [],
      "loanCategoryMasters": [],
      "bankUsers": [],
      "imageDetails": [
          {
              "id": 0,
              "name": "bank-1633517363.svg",
              "baseURL": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/",
              "fullURL": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/banks/bank-1633517363.svg",
              "isPrimary": true,
              "fileType": "BANK",
              "responseStatusCode": 0
          }
      ],
      "bankEnquiryList": [],
      "bankUserTypes": [],
      "bankAccounts": [],
      "responseStatusCode": 0
  },
  {
      "isBankAPIAvailable": false,
      "isFetchBankAPIAvailable": false,
      "id": 6,
      "name": "Riyad Bank",
      "name_ar": "بنك الرياض‎",
      "userName": "riyad@creditly.com",
      "image": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/banks/bank-1633517392.svg",
      "imageName": "bank-1633517392.svg",
      "active_Status": true,
      "selected": false,
      "source": "Bank",
      "formLink": "https://www.riyadbank.com/ar/personal-banking/current-account/onboarding",
      "description": "Open a bank account online!",
      "description_ar": "إفتح حسابك الجاري",
      "isAccOpenAvail": true,
      "isProductAvail": true,
      "isIBANAvail": true,
      "bankCode": 0,
      "masterCategoryIDs": "",
      "loanCategoryMasterIDs": "",
      "masterCategories": [],
      "loanCategoryMasters": [],
      "bankUsers": [],
      "imageDetails": [
          {
              "id": 0,
              "name": "bank-1633517392.svg",
              "baseURL": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/",
              "fullURL": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/banks/bank-1633517392.svg",
              "isPrimary": true,
              "fileType": "BANK",
              "responseStatusCode": 0
          }
      ],
      "bankEnquiryList": [],
      "bankUserTypes": [],
      "bankAccounts": [],
      "responseStatusCode": 0
  },
  {
      "isBankAPIAvailable": false,
      "isFetchBankAPIAvailable": false,
      "id": 7,
      "name": "Bank Aljazira",
      "name_ar": "بنك الجزيرة",
      "userName": "aljazira@creditly.com",
      "image": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/banks/bank-1633517407.svg",
      "imageName": "bank-1633517407.svg",
      "active_Status": true,
      "selected": false,
      "source": "Bank",
      "formLink": "https://www.baj.com.sa/Services/AccountOpening/index.aspx?langId=1",
      "description": "Open a bank account online!",
      "description_ar": "إفتح حسابك الجاري",
      "isAccOpenAvail": true,
      "isProductAvail": true,
      "isIBANAvail": true,
      "bankCode": 0,
      "masterCategoryIDs": "",
      "loanCategoryMasterIDs": "",
      "masterCategories": [],
      "loanCategoryMasters": [],
      "bankUsers": [],
      "imageDetails": [
          {
              "id": 0,
              "name": "bank-1633517407.svg",
              "baseURL": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/",
              "fullURL": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/banks/bank-1633517407.svg",
              "isPrimary": true,
              "fileType": "BANK",
              "responseStatusCode": 0
          }
      ],
      "bankEnquiryList": [],
      "bankUserTypes": [],
      "bankAccounts": [],
      "responseStatusCode": 0
  },
  {
      "isBankAPIAvailable": false,
      "isFetchBankAPIAvailable": true,
      "id": 10,
      "name": "Arab National Bank",
      "name_ar": "البنك العربي الوطني",
      "userName": "arabnational@creditly.com",
      "image": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/banks/bank-1633517442.svg",
      "imageName": "bank-1633517442.svg",
      "active_Status": true,
      "selected": false,
      "source": "Bank",
      "formLink": "https://onboarding.anb.com.sa/OnlineAccountOpening_UI/?lang=ar-SA",
      "description": "Open a bank account online!",
      "description_ar": "إفتح حسابك الجاري",
      "isAccOpenAvail": true,
      "isProductAvail": true,
      "isIBANAvail": true,
      "bankCode": 0,
      "masterCategoryIDs": "",
      "loanCategoryMasterIDs": "",
      "masterCategories": [],
      "loanCategoryMasters": [],
      "bankUsers": [],
      "imageDetails": [
          {
              "id": 0,
              "name": "bank-1633517442.svg",
              "baseURL": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/",
              "fullURL": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/banks/bank-1633517442.svg",
              "isPrimary": true,
              "fileType": "BANK",
              "responseStatusCode": 0
          }
      ],
      "bankEnquiryList": [],
      "bankUserTypes": [],
      "bankAccounts": [],
      "responseStatusCode": 0
  },
  {
      "isBankAPIAvailable": false,
      "isFetchBankAPIAvailable": false,
      "id": 22,
      "name": "Bidaya",
      "name_ar": "بداية",
      "userName": "bidaya@creditly.com",
      "image": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/banks/bank-1633517469.svg",
      "imageName": "bank-1633517469.svg",
      "active_Status": true,
      "selected": false,
      "source": "Bank",
      "formLink": "https://www.bidaya.com.sa",
      "description": "Bidaya",
      "description_ar": "بداية",
      "isAccOpenAvail": false,
      "isProductAvail": true,
      "isIBANAvail": false,
      "bankCode": 0,
      "masterCategoryIDs": "",
      "loanCategoryMasterIDs": "",
      "masterCategories": [],
      "loanCategoryMasters": [],
      "bankUsers": [],
      "imageDetails": [
          {
              "id": 0,
              "name": "bank-1633517469.svg",
              "baseURL": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/",
              "fullURL": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/banks/bank-1633517469.svg",
              "isPrimary": true,
              "fileType": "BANK",
              "responseStatusCode": 0
          }
      ],
      "bankEnquiryList": [],
      "bankUserTypes": [],
      "bankAccounts": [],
      "responseStatusCode": 0
  },
  {
      "isBankAPIAvailable": false,
      "isFetchBankAPIAvailable": true,
      "id": 1,
      "name": "Saudi Investment Bank",
      "name_ar": "البنك السعودي للاستثمار",
      "userName": "saudiinvestmentbank@creditly.com",
      "image": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/banks/bank-1633517482.svg",
      "imageName": "bank-1633517482.svg",
      "active_Status": true,
      "selected": false,
      "source": "Bank",
      "formLink": "https://forms.saib.com.sa/Online/Account/GettingStarted/?lang=en",
      "description": "Open a bank account online!",
      "description_ar": "إفتح حسابك الجاري",
      "isAccOpenAvail": true,
      "isProductAvail": true,
      "isIBANAvail": true,
      "bankCode": 0,
      "masterCategoryIDs": "",
      "loanCategoryMasterIDs": "",
      "masterCategories": [],
      "loanCategoryMasters": [],
      "bankUsers": [],
      "imageDetails": [
          {
              "id": 0,
              "name": "bank-1633517482.svg",
              "baseURL": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/",
              "fullURL": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/banks/bank-1633517482.svg",
              "isPrimary": true,
              "fileType": "BANK",
              "responseStatusCode": 0
          }
      ],
      "bankEnquiryList": [],
      "bankUserTypes": [],
      "bankAccounts": [],
      "responseStatusCode": 0
  },
  {
      "isBankAPIAvailable": false,
      "isFetchBankAPIAvailable": false,
      "id": 19,
      "name": "Emirates NBD",
      "name_ar": "بنك الإمارات دبي الوطني",
      "userName": "emirates@creditly.com",
      "image": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/banks/bank-1633517667.svg",
      "imageName": "bank-1633517667.svg",
      "active_Status": true,
      "selected": false,
      "source": "Bank",
      "formLink": "http://www.emiratesnbd.com.sa/ar-sa/apply-now-form/product/?product=psb-ac-1&ref=prd-slider&src=web",
      "description": "Open a bank account online!",
      "description_ar": "إفتح حسابك الجاري",
      "isAccOpenAvail": false,
      "isProductAvail": true,
      "isIBANAvail": true,
      "bankCode": 0,
      "masterCategoryIDs": "",
      "loanCategoryMasterIDs": "",
      "masterCategories": [],
      "loanCategoryMasters": [],
      "bankUsers": [],
      "imageDetails": [
          {
              "id": 0,
              "name": "bank-1633517667.svg",
              "baseURL": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/",
              "fullURL": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/banks/bank-1633517667.svg",
              "isPrimary": true,
              "fileType": "BANK",
              "responseStatusCode": 0
          }
      ],
      "bankEnquiryList": [],
      "bankUserTypes": [],
      "bankAccounts": [],
      "responseStatusCode": 0
  },
  {
      "isBankAPIAvailable": false,
      "isFetchBankAPIAvailable": false,
      "id": 23,
      "name": "Quara Finance ",
      "name_ar": "كوارا للتمويل ",
      "userName": "Quara@creditly.com",
      "image": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/banks/bank-1633517680.svg",
      "imageName": "bank-1633517680.svg",
      "active_Status": true,
      "selected": false,
      "source": "Bank",
      "formLink": "https://www.quarafinance.com/%d8%a7%d9%84%d8%aa%d9%85%d9%88%d9%8a%d9%84/",
      "description": "Quara Finance",
      "description_ar": "كوارا للتمويل ",
      "isAccOpenAvail": true,
      "isProductAvail": true,
      "isIBANAvail": true,
      "bankCode": 0,
      "masterCategoryIDs": "",
      "loanCategoryMasterIDs": "",
      "masterCategories": [],
      "loanCategoryMasters": [],
      "bankUsers": [],
      "imageDetails": [
          {
              "id": 0,
              "name": "bank-1633517680.svg",
              "baseURL": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/",
              "fullURL": "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axtscqt3skdc/b/Creditly-Dev/o/banks/bank-1633517680.svg",
              "isPrimary": true,
              "fileType": "BANK",
              "responseStatusCode": 0
          }
      ],
      "bankEnquiryList": [],
      "bankUserTypes": [],
      "bankAccounts": [],
      "responseStatusCode": 0
  }
]


export const residenceTypes=  [
  {
    "id": 2,
    "name": "Owned",
    "name_ar": "ملك"
},
{
    "id": 3,
    "name": "Rental",
    "name_ar": "إيجار"
},
  {
      "id": 4,
      "name": "Provided by Employer",
      "name_ar": "من جهة العمل"
  },
  {
      "id": 1,
      "name": "Family",
      "name_ar": "مع الأهل"
  }
//   {
//     "id": 1,
//     "name": "Installments",
//     "name_ar": "مع الأهل"
// },

]


export const   sourceOfIncomes= [
  // {
  //     "id": 2,
  //     "name": "Business",
  //     "name_ar": "عمل حر"
  // },
  // {
  //     "id": 3,
  //     "name": "Others",
  //     "name_ar": "أخرى"
  // },
  // {
  //     "id": 1,
  //     "name": "Salary",
  //     "name_ar": "راتب"
  // }
  //  {id: 2, name: "Business", name_ar: "عمل حر"}
   {id: 1, name: "Salary", name_ar: "راتب"},
    {id: 4, name: "Subsidy", name_ar: "دعم مالي"},
 {id: 3, name: "Other", name_ar: "أخرى"}
 , {id: 6, name: "Self-Employed", name_ar: "العاملون لحسابهم الخاص"}
 , {id: 5, name: "Pension", name_ar: "راتب تقاعد"}

  // {name:"Salary",id:1},
  // {name:"Self-employed",id:2},
  // {name:"Subsidy",id:3},

  // {name:"Rental Proceeds",id:5},

  // {name:"Pension",id:7},
  // {name:"Inheritance",id:8},
  // {name:"From Family Member",id:9},
  // {name:"Other",id:10}
]

export const   BANK_PURPOSE= [

      "Payroll"

  ,
      "Saving"
  ,

      "Payment"

]
