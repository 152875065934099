import { DatePipe } from "@angular/common";
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BsDatepickerModule, BsDatepickerConfig, BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CreditlyServicesService } from "src/app/creditly-services.service";

@Component({
    selector: 'app-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.scss'],
  })
  export class SupportComponent implements OnInit {
    closeResult = '';
    timeList = [];
    bsValue = new Date();
    curDate:any
    defaultDate:any;
    time="";
    callbackDate;
    @ViewChild("d") d: BsDatepickerDirective;
    @ViewChild("input") input: HTMLInputElement;
    modalRef =  BsModalRef;
    desc="";
    constructor(private modalService: BsModalService,public datepipe: DatePipe, private creditlyService :CreditlyServicesService) {
      this.curDate = this.datepipe.transform(this.bsValue,"dd/MM/yyyy");
      this.bsValue = new Date(this.datepipe.transform(this.curDate,"dd/MM/yyyy"));
    }
    ngOnInit(): void {
      this.loadTime()
    }
    openModal(template:TemplateRef<any>) {
      this.modalService.show(template)
    }
    loadTime(){
      for(let startPoint = new Date().getHours(); startPoint < 24; startPoint++){
        let timePeriod;
        let endPoint = startPoint + 1;
        if(startPoint > 12){
            let newstartPoint = startPoint - 12;
            let newendPoint = endPoint - 12;
            timePeriod = newstartPoint + " PM" + " to " + newendPoint + (newendPoint == 12 ? " AM" : " PM");
        }else if(startPoint == 12 || startPoint == 0){
            if(startPoint == 12) timePeriod = startPoint + " PM" + " to " + 1 + " PM";
            else timePeriod = 12 + " AM" + " to " + endPoint + " AM"
        }
        else{
          timePeriod = startPoint + " AM" + " to " + endPoint + (endPoint == 12 ? " PM" : " AM");
        }
        this.timeList.push(timePeriod);
      }
    }
    submitQuery() {
    this.creditlyService.submitEnquiry('test',this.desc).subscribe((res:any)=>{
      if(res.statusCode == 200) {
       this.creditlyService.notify("Thank you for contacting us!. We have recevied your message and will get back to you sonn","success")
      }
    })
    }
    requestCallback() {
       let date = this.datepipe.transform(this.curDate,"dd-MM-yyyy");
      // this.creditlyService.requestCallBack(date,'10:00Am 11:00Am').subscribe((res:any)=>{
      //   if(res.statusCode == 200) {
      //     this.creditlyService.notify("Thank you for contacting us!. We have recevied your message and will get back to you sonn","success")
      //    }
      // })
      this.desc="subject= request callback date and Time ="+date+"10Am 11Am";
    }
  }