import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AppRoutingModule } from '../app-routing.module';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NotifierModule } from 'angular-notifier';
import { ScrollDirective } from './directives/scroll.directive';
import { TranslateModule } from '@ngx-translate/core';
import { ConvertNumbersPipe } from './Pipes/convert-numbers.pipe';
import { SliderArPipe } from './Pipes/slider-ar.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GetNamePipe } from './Pipes/get-name.pipe';
import { SortEligiblePipe } from './Pipes/sort-eligible.pipe';
import { AnimationSectionComponent } from './components/animation-section/animation-section.component';
import { MainBackgroundComponent } from './components/main-background/main-background.component';
import { AutoLeaseCardSharedComponent } from './components/auto-lease-card-shared/auto-lease-card-shared.component';
import { BankListSharedComponent } from './components/bank-list-shared/bank-list-shared.component';
import { CardProductDetailsComponent } from './components/card-product-details/card-product-details.component';
import { CompareListSharedComponent } from './components/compare-list-shared/compare-list-shared.component';
import { ProfileFormSharedComponent } from '../features/profile/profile-form-shared/profile-form-shared.component';
import { IncomeInfoComponent } from '../features/products/shared/components/income-info/income-info.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { UploadDirective } from './directives/upload.directive';




@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,

    ScrollDirective,
    ConvertNumbersPipe,
    SliderArPipe,
    BankListSharedComponent,
    GetNamePipe,
    SortEligiblePipe,
    AnimationSectionComponent,
    AutoLeaseCardSharedComponent,
    CardProductDetailsComponent,
    MainBackgroundComponent,
    CompareListSharedComponent,
    ProfileFormSharedComponent,
    IncomeInfoComponent,
    UploadDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    NotifierModule,
    // TranslateModule,
    NgbModule,
    TranslateModule.forChild({
      extend:true

        }),

ShareButtonsModule.withConfig({
      debug: true
    }),
    ShareIconsModule,
    BsDropdownModule.forRoot()

  ],
  exports: [
    TranslateModule,
    HeaderComponent,
    FooterComponent,
    ConvertNumbersPipe,
    SliderArPipe,
    BankListSharedComponent,
    GetNamePipe,
    SortEligiblePipe,
    AnimationSectionComponent,
    AutoLeaseCardSharedComponent,
    CardProductDetailsComponent,
    MainBackgroundComponent,
    CompareListSharedComponent,
    ProfileFormSharedComponent,
    IncomeInfoComponent,
    ShareButtonsModule,
ShareIconsModule
  ],
  providers:[DatePipe]
})
export class SharedModule { }
