<div class="container">
    <div class="row">
        <div class="col-md-12 pro-main-label">
            <p>
               Contact Information
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="d-flex">
                <span><i class="fas fa-map-marker-alt mt-2"></i></span>
                <p class="contact pl-1">Musa Ibn Nusair  Street - Al Olaya, Riyadn 2244, Saudi Arabia</p>
            </div>
        </div>
        <div class="col-md-6" >
            
            
            <i class="fa-brands fa-twitter"></i>
            <i class="fa-brands fa-facebook"></i>
            <i class="fa-brands fa-google"></i>

            
        </div>
    </div>
    <div class="row">
        <div class="col-md-6"><i class="fa-thin fa-envelope pr-2"></i>hi@creditly.sa</div>
        
    </div>
    <div class="row mt-3">
        <div class="col-md-6">
            <i class="fa fa-phone pr-1"></i> 920001273
        </div>
    </div>
    <div class="row mt-3">
                <div class="col-md-6">
            <button type="button" class="btn btn-outline-success" (click)="openModal(content)"><i class="fas fa-phone pr-1"></i>  Request a callback</button>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-12">
            <hr />
</div>
</div> 
    <div class="row mt-4">
        <div class="col-md-12">
            <h4>How Can We Help?</h4>
            <p>Please select a topic below related to your inquiry. If you don't fint what you need, fill out our contact  form and we will get back to you shortly.</p>
        </div>
    </div>
    
    <div class="row mt-1">
        <div class="col-md-9 form-group">
            <label for="exampleFormControlSelect1">Inquiry Type<span class="text-danger">*</span></label>
                    <select class="form-control" id="exampleFormControlSelect1">
                      <option>Select the type</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </select>
        </div>
     </div>
     <div class="row align-items-end"> 
        <div class="col-md-9 form-group">
            <label for="exampleFormControlTextarea1">Message</label>
            <textarea [(ngModel)]="desc" class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Add any additional details we can use to help you"></textarea>
        </div>
        <div class="col-md-3 form-group">
            <button type="button" class="btn btn-success" (click)="submitQuery()"> Submit</button>
        </div>
     </div>    
  
</div>
<ng-template #content let-modal box>
    <div class="modal-header remove-hr">
      <h4 class="modal-title" id="modal-basic-title">
        Choose your desired date and time
      </h4>
  
    </div>
    <div class="modal-body">
        <div class="mb-3">
          <label class="label-space" for="dateOfBirth">Choose Date</label
          >
          <span class="text-danger">*</span>
          <div class="input-group input-container roundest" >
          <input #input type="text"
           placeholder=""
           class="form-control "
           style="border: hidden;"
           bsDatepicker
           #d="bsDatepicker"
           [bsValue]="bsValue"
           [bsConfig]="{ showWeekNumbers:false,
                         dateInputFormat:'DD/MM/YYYY',
                         containerClass:'',
                         showTodayButton: true,
                         todayPosition: 'center',
                         useUtc:true
                       }"
                       [(ngModel)]="callbackDate"           
           >
           <img
            class="btn"
            src="https://img.icons8.com/material-outlined/24/000000/calendar--v1.png"
           />
          </div>
        </div>
  
        <div class="mb-3">
          <label class="label-space" for="Time">Choose Time</label
          ><span class="text-danger">*</span>
  
          <div class="input-group input-container roundest" >
            <select class="form-control roundest" [(ngModel)]="time" (change)="requestCallback()">
              <option *ngFor="let times of timeList" value="{{times}}">{{times}}</option>
          </select>
          <img class="btn" src="https://img.icons8.com/material-outlined/24/000000/clock--v1.png"/>
            <!-- </button> -->
          </div>
        </div>
    </div>
  
  </ng-template>


   
    
