<div class="container w-100">
    <form [formGroup]="formGroup">
        <div class="row">
            <div class="col-md-6">

                <div class="form-group-n w-100">
                    <label class="form-label-n d-block">
                Primary Bank
              </label>
                    <!-- <input type="text" formControlName='bankAccountID' placeholder="Write the data" class="text-form-input w-100"> -->
                    <select formControlName='bankAccountID' class="select-form-input w-100">
                      <option  value="">Select bank</option>

                       <option *ngFor='let item of banksList' [value]="item.id">{{item.name}}</option>
                     </select>
                </div>

            </div>
            <div class="col-md-6">

                <div class="form-group-n w-100">
                    <label class="form-label-n d-block">
              IBAN
            </label>
                    <input type="text" formControlName='iBAN' placeholder="Write the data" class="text-form-input w-100">

                </div>

            </div>

        </div>
    </form>

</div>