<div class="container">
    <form [formGroup]='formGroup'>

        <div class="row contact-row">
            <div class="pro-main-label">
                <p>
                    Employment Information
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6" *ngFor='let feild of employmrntdetails'>

                <div class="form-group-n w-100">
                    <label class="form-label-n d-block">
              {{feild.labelName}}
</label>
                    <select [formControlName]='feild.formControl' class="select-form-input w-100">
                      <option value="">Select your {{feild.labelName}}</option>
                    <option *ngFor='let nut of feild.menuData' [value]="nut.name?nut.name:nut">{{nut.name?nut.name:nut}}</option>
                  </select>

                </div>

            </div>

        </div>
        <hr class="profile-line">

        <!-- <ng-container > -->

        <div class="row">
            <div class='col-md-3 mt-2' *ngFor='let nam of employerInfo'>
                <div class="person-details">
                    <p class="title-label">
                        {{nam.name}}
                    </p>
                    <p class="title-value">
                        {{nam.value && nam.value !==''?nam.value:"NA"}}
                    </p>
                </div>
            </div>
        </div>
        <!-- <hr class="profile-line"> -->
        <!-- </ng-container> -->
        <!-- <div class="row">
            <div class="col-md-6">

                <div class="form-group-n w-100">
                    <label class="form-label-n d-block">
                Nature of Business
</label>
                    <select formControlName='NatureOfBusiness' class="select-form-input w-100">
                      <option *ngFor='let nut of naturesBusses' [value]="nut">{{nut}}</option>
                    </select>

                </div>

            </div>

        </div> -->
        <hr class="profile-line">
        <div class="row contact-row">
            <div class="pro-main-label">
                <p>
                    Working Address
                </p>
            </div>
        </div>

        <!-- <ng-container *ngFor='let ro of workDetails'> -->

        <div class="row">
            <div class='col-md-6' *ngFor='let feild of workDetails'>
                <div class="form-group-n w-100">
                    <label class="form-label-n d-block">
              {{feild.name}}
</label>
                    <input type="text" class="text-form-input w-100" [formControlName]='feild.formControl'>
                    <!-- <select [formControlName]='feild.formControl' class="select-form-input w-100">
                      <option value="">Select your {{feild.labelName}}</option>
                    <option *ngFor='let nut of feild.menuData' [value]="nut.name?nut.name:nut">{{nut.name?nut.name:nut}}</option>
                  </select> -->

                </div>
            </div>
        </div>
        <hr class="profile-line">
        <!-- </ng-container> -->

        <!-- <hr class="profile-line"> -->


        <div class="row contact-row">
            <div class="pro-main-label">
                <p>
                    Income Information
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-9">
                <div class="form-group">
                    <label class="form-label-n">
                    Primary Source of Income Type
</label>
                    <div class="radio-contain  align-items-center row mt-3">

                        <div class="radio-group d-flex align-items-center mt-2 col-md-4" *ngFor="let re of sourceOfIncomes" (click)='selectSorceIncome(re)'>
                            <div class="radio-field" (click)='selectSorceIncome(re)' [ngClass]="{'checked':re.checked}">
                                <input formControlName='sourceOfIncomeID' [id]='re.name' name="sourceOfIncomeID" type="radio" [checked]='re.checked' (click)='selectSorceIncome(re)'>
                            </div>
                            <label class="m-0" (click)='selectSorceIncome(re)' [for]="re.name">{{re.name}}</label>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <hr class="profile-line">

        <!-- <ng-container > -->

        <div class="row">
            <div class='col-md-4' *ngFor='let nam of incomeInfo'>
                <div class="person-details">
                    <p class="title-label">
                        {{nam.name}}
                    </p>
                    <p class="title-value">
                        {{nam.value && nam.value !==''?nam.value:"NA"}}
                    </p>
                </div>
            </div>
        </div>
        <hr class="profile-line">
        <!-- </ng-container> -->

        <div class="row">
            <div class="col-md-6">

                <div class="form-group-n w-100">
                    <label class="form-label-n d-block">
            Extra Income Amount
          </label>
                    <input formControlName='monthlyExtraIncome' placeholder='Enter Extra Income Amount' class="text-form-input w-100">


                </div>

            </div>
            <div class="col-md-6">
                <div class="form-group-n w-100">
                    <label class="form-label-n d-block">
                    Source of Extra Monthly Income
                  </label>
                    <select formControlName='otherSourceOfIncomeID' class="select-form-input w-100">
                    <option  value="">Select the source</option>

                    <option *ngFor='let nut of exrtaIncome' [value]="nut.id">{{nut.name}}</option>
                  </select>

                </div>

            </div>



        </div>
    </form>



</div>