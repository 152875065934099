export const    Declarations = [
  {
      "Question": "Are you politically known person?",
      "Answer": false
  },
  {
      "Question": "Are you in a relationship with a politically exposed person?",
      "Answer": false
  },
  {
      "Question": "Do you have any special needs or disabilities?",
      "Answer": false
  },
  {
      "Question": "Are you a non-resident of the Kingdom of Saudi Arabia?",
      "Answer": false
  },
  {
      "Question": "Have you ever been refused by another financial institution to establish a business relationship with them?",
      "Answer": false
  },
  {
      "Question": "Are you a sponsor of one of the beneficiaries of the financing services?",
      "Answer": false
  },
  {
      "Question": "Do you occupy, or are you a candidate, for a diplomatic or senior civil office in the government, any military office or an office in a government owned company, etc.? Or are you a member of the immediate family (spouse, parent, sibling or child) of, or a related party (advisor or agent) to, such individual?",
      "Answer": false
  },
  {
      "Question": "Are you a Freelancer or you render professional advisory services (e.g. law firm, financial or accounting consultant and likewise)?",
      "Answer": false
  },
   {
      "Question": "Do you have any other nationality expect the one in your official record?",
      "Answer": false,
      "Nationality" :null
  },
  {
      "Question": "Are you a Tax Resident of any country or countries outside of Saudi Arabia?",
      "Answer": false,
      "Comments": null,
      "AdditionalInfo": {
          "Country": null,
          "TaxID": null
      }
  }
]


export const WORK_types_O = [

  {
      "elmOccupationType": 0,
      "id": 7,
      "name": "Private Sector",
      "name_ar": "قطاع خاص",
      "retireAge": 65,
      "active_Status": true,
      "responseStatusCode": 0
  },
  {
      "elmOccupationType": 0,
      "id": 5,
      "name": "Retired",
      "name_ar": "متقاعد",
      "retireAge": 65,
      "active_Status": true,
      "responseStatusCode": 0
  },
  {
      "elmOccupationType": 0,
      "id": 8,
      "name": "Self Employed",
      "name_ar": "عمل حر",
      "retireAge": 65,
      "active_Status": true,
      "responseStatusCode": 0
  },
  {
      "elmOccupationType": 0,
      "id": 9,
      "name": "Semi-Government",
      "name_ar": "شبه حكومي",
      "retireAge": 60,
      "active_Status": true,
      "responseStatusCode": 0
  },
  {
      "elmOccupationType": 2,
      "id": 10,
      "name": "Student",
      "name_ar": "Student",
      "retireAge": 22,
      "active_Status": true,
      "responseStatusCode": 0
  }
];


export const WORK_types_G = [
  {
      "elmOccupationType": 1,
      "id": 1,
      "name": "Governmental Civil",
      "name_ar": "حكومي مدني",
      "retireAge": 65,
      "active_Status": true,
      "responseStatusCode": 0
  },
  {
      "elmOccupationType": 0,
      "id": 3,
      "name": "Governmental military",
      "name_ar": "حكومي عسكري",
      "retireAge": 65,
      "active_Status": true,
      "responseStatusCode": 0
  },
  {
      "elmOccupationType": 0,
      "id": 5,
      "name": "Governmental Retired",
      "name_ar": "متقاعد",
      "retireAge": 65,
      "active_Status": true,
      "responseStatusCode": 0
  },

  {
      "elmOccupationType": 0,
      "id": 9,
      "name": "Semi-Government",
      "name_ar": "شبه حكومي",
      "retireAge": 60,
      "active_Status": true,
      "responseStatusCode": 0
  }

];

export const RANKS =
[
  {
      "id": 1,
      "workTypeId": 1,
      "isPilot": false,
      "retireeAge": 65
  },
  {
      "id": 2,
      "workTypeId": 7,
      "isPilot": false,
      "retireeAge": 65
  },
  {
      "id": 3,
      "workTypeId": 8,
      "isPilot": false,
      "retireeAge": 65
  },
  {
      "id": 4,
      "workTypeId": 3,
      "militaryRankType": "officers",
      "militaryRank": "Mulazim",
      "isPilot": false,
      "retireeAge": 44,
      "militaryRankType_ar": "ضباط",
      "militaryRank_ar": "ملازم"
  },
  {
      "id": 5,
      "workTypeId": 3,
      "militaryRankType": "officers",
      "militaryRank": "Mulazim awwal",
      "isPilot": false,
      "retireeAge": 44,
      "militaryRankType_ar": "ضباط",
      "militaryRank_ar": "ملازم أول"
  },
  {
      "id": 6,
      "workTypeId": 3,
      "militaryRankType": "officers",
      "militaryRank": "Naqib",
      "isPilot": false,
      "retireeAge": 48,
      "militaryRankType_ar": "ضباط",
      "militaryRank_ar": "نقيب"
  },
  {
      "id": 7,
      "workTypeId": 3,
      "militaryRankType": "officers",
      "militaryRank": "Ra'id",
      "isPilot": false,
      "retireeAge": 50,
      "militaryRankType_ar": "ضباط",
      "militaryRank_ar": "رائد"
  },
  {
      "id": 8,
      "workTypeId": 3,
      "militaryRankType": "officers",
      "militaryRank": "Muqaddam",
      "isPilot": false,
      "retireeAge": 52,
      "militaryRankType_ar": "ضباط",
      "militaryRank_ar": "مقدم"
  },
  {
      "id": 9,
      "workTypeId": 3,
      "militaryRankType": "officers",
      "militaryRank": "Aqid",
      "isPilot": false,
      "retireeAge": 54,
      "militaryRankType_ar": "ضباط",
      "militaryRank_ar": "عقيد"
  },
  {
      "id": 10,
      "workTypeId": 3,
      "militaryRankType": "officers",
      "militaryRank": "Amid",
      "isPilot": false,
      "retireeAge": 56,
      "militaryRankType_ar": "ضباط",
      "militaryRank_ar": "عميد"
  },
  {
      "id": 11,
      "workTypeId": 3,
      "militaryRankType": "officers",
      "militaryRank": "Liwa",
      "isPilot": false,
      "retireeAge": 58,
      "militaryRankType_ar": "ضباط",
      "militaryRank_ar": "لواء"
  },
  {
      "id": 12,
      "workTypeId": 3,
      "militaryRankType": "officers",
      "militaryRank": "Mulazim",
      "isPilot": true,
      "retireeAge": 42,
      "militaryRankType_ar": "ضباط",
      "militaryRank_ar": "ملازم"
  },
  {
      "id": 13,
      "workTypeId": 3,
      "militaryRankType": "officers",
      "militaryRank": "Mulazim awwal",
      "isPilot": true,
      "retireeAge": 42,
      "militaryRankType_ar": "ضباط",
      "militaryRank_ar": "ملازم أول"
  },
  {
      "id": 14,
      "workTypeId": 3,
      "militaryRankType": "officers",
      "militaryRank": "Naqib",
      "isPilot": true,
      "retireeAge": 46,
      "militaryRankType_ar": "ضباط",
      "militaryRank_ar": "نقيب"
  },
  {
      "id": 15,
      "workTypeId": 3,
      "militaryRankType": "officers",
      "militaryRank": "Ra'id",
      "isPilot": true,
      "retireeAge": 48,
      "militaryRankType_ar": "ضباط",
      "militaryRank_ar": "رائد"
  },
  {
      "id": 16,
      "workTypeId": 3,
      "militaryRankType": "officers",
      "militaryRank": "Muqaddam",
      "isPilot": true,
      "retireeAge": 50,
      "militaryRankType_ar": "ضباط",
      "militaryRank_ar": "مقدم"
  },
  {
      "id": 17,
      "workTypeId": 3,
      "militaryRankType": "officers",
      "militaryRank": "Aqid",
      "isPilot": true,
      "retireeAge": 52,
      "militaryRankType_ar": "ضباط",
      "militaryRank_ar": "عقيد"
  },
  {
      "id": 18,
      "workTypeId": 3,
      "militaryRankType": "officers",
      "militaryRank": "Amid",
      "isPilot": true,
      "retireeAge": 54,
      "militaryRankType_ar": "ضباط",
      "militaryRank_ar": "عميد"
  },
  {
      "id": 19,
      "workTypeId": 3,
      "militaryRankType": "officers",
      "militaryRank": "Liwa",
      "isPilot": true,
      "retireeAge": 56,
      "militaryRankType_ar": "ضباط",
      "militaryRank_ar": "لواء"
  },
  {
      "id": 20,
      "workTypeId": 3,
      "militaryRankType": "Individuals",
      "militaryRank": "Earif",
      "isPilot": false,
      "retireeAge": 46,
      "militaryRankType_ar": "أفراد\r\n",
      "militaryRank_ar": "عريف"
  },
  {
      "id": 21,
      "workTypeId": 3,
      "militaryRankType": "Individuals",
      "militaryRank": "Wakil raqib",
      "isPilot": false,
      "retireeAge": 48,
      "militaryRankType_ar": "أفراد\r\n",
      "militaryRank_ar": "وكيل رقيب"
  },
  {
      "id": 22,
      "workTypeId": 3,
      "militaryRankType": "Individuals",
      "militaryRank": "Raqib",
      "isPilot": false,
      "retireeAge": 50,
      "militaryRankType_ar": "أفراد\r\n",
      "militaryRank_ar": "رقيب"
  },
  {
      "id": 23,
      "workTypeId": 3,
      "militaryRankType": "Individuals",
      "militaryRank": "Raqib'awal",
      "isPilot": false,
      "retireeAge": 50,
      "militaryRankType_ar": "أفراد\r\n",
      "militaryRank_ar": "رقيب أول"
  },
  {
      "id": 24,
      "workTypeId": 3,
      "militaryRankType": "Individuals",
      "militaryRank": "Rayiys ruqaba'",
      "isPilot": false,
      "retireeAge": 52,
      "militaryRankType_ar": "أفراد\r\n",
      "militaryRank_ar": "رئيس رقباء"
  }
]
