import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-card-product-details',
  templateUrl: './card-product-details.component.html',
  styleUrls: ['./card-product-details.component.scss']
})
export class CardProductDetailsComponent implements OnInit,OnDestroy {
@Input() autoLeaseData:{
  type,
  item,
  catName
}
item
  unsubscribeAll = new Subject();
  currentLang: string;
  catName: any;
  mangmentFee: any;
  fees: any;
  earlyFees: any;

  constructor(private translate: TranslateService,private cdr:ChangeDetectorRef) { }

  ngOnInit(): void {
    this.item = this.autoLeaseData.item;
    this.fees = this.item.fees;
    this.mangmentFee =this.FilterValue(1,this.fees);
    this.earlyFees =this.FilterValue(2,this.fees);


    this.currentLang = localStorage.getItem('currentLang');
    this.translate.onLangChange.pipe(takeUntil(this.unsubscribeAll)).subscribe(lang=>{
      this.currentLang = lang.lang
    })
    this.catName = this.autoLeaseData.catName
  }
  FilterValue(id, item) {
    console.log(item);
    this.cdr.checkNoChanges
if(item){

  return id == 1 ?item?.find((val) => val.name == "Management Fees")?.value
    :id==2? item?.find((val) => val.name == "Early Settlement Fees")?.value
    : 0;

} else{
  return 0
}
  }
  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
