<div class="container-fluid pl-0">
    <div class="row">
        <app-profile-form-shared class="w-100 " [loginFormValidationFlag]='loginFormValidationFlag' *ngIf='incometData' (newItemEvent)='update()' [formComingData]='incometData'></app-profile-form-shared>






    </div>
</div>