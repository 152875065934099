import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParameterCodec, HttpParams } from '@angular/common/http';
import { $, promise } from 'protractor';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NotifierService } from 'angular-notifier';
// import { baseUrl } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { FormGroup } from '@angular/forms';
@Injectable({
  providedIn: 'root',
})
export class CreditlyServicesService {

  shareProductUrl = "http://beta.creditly.sa/#/";
  // shareProductUrl = "http://localhost:2040/#/";

  private subject = new Subject<any>();
  public bank = new Subject<any>();
  public contact = new Subject<any>();
  public employement = new Subject<any>();
  public financial = new Subject<any>();
  request = new Subject<any>();
  formCompletion = 0;
  finding = false;
  private readonly notifier: NotifierService;
  baseUrl = environment.baseUrl;
  mainBaseUrl = environment.mainBaseUrl;
  countiresUrl = environment.countiresUrl;
  // headers = new HttpHeaders();

  registerCompleteStep = new EventEmitter();


  to = "";
  req = {
    uUID: Math.floor(Math.random() * 20000000 + 1).toString(),
    platformType: 1,
    systemInfo: "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.101 Safari/537.36"
  };
  encryptSecretKey = "Creditly#Secret"

  validObject: any = { bank: { valid: false, change: false }, contact: { valid: false, change: false }, employment: { valid: false, change: false }, financial: { valid: false, change: false } };
  selectedVehicleID: any;
  autoLoanItem = sessionStorage.getItem("CategoryID") == "AutoLoan" ? [JSON.parse(sessionStorage.getItem("selectedCard"))] : [];
  profileDate = JSON.parse(localStorage.getItem("loginResponse"));

 emiForm = new EventEmitter();
 formBehav = new BehaviorSubject(null);
 getForm = this.formBehav.asObservable();

 reqBehav = new BehaviorSubject(null);
 getreq = this.reqBehav.asObservable();

 sendreqData(nex){
  this.reqBehav.next(nex)

}
 sendDataOnEmit(nex){
   this.formBehav.next(nex)

 }
   constructor(private http: HttpClient, notifierService: NotifierService) {
    this.notifier = notifierService;
    this.to = localStorage.getItem("sessionToken") ? "Profile" : "Login";

    // this.headers = this.headers
    //   .set(
    //     'Authorization',
    //     'API_User_Auth ' +
    //     'JGNyZWRpdGx5d2ViYXBpOmUxMGFkYzM5NDliYTU5YWJiZTU2ZTA1N2YyMGY4ODNl'
    //   )
    //   .set('APIKey', '5A2A6A7E-F86F-4877-83D3-9')
    //   .set('APPType', '1')
    //   .set('APPCode', 'CR1T1')
    //   .set('Content-Type', 'application/json')
    //   .set('Accept-Language', 'en');
  }
  change(value) {
    this.subject.next(value);
  }

  subscribe(): Observable<any> {
    return this.subject.asObservable();
  }

  public submitEnquiry(type:string,desc:string) {
    let headers = new HttpHeaders().set('token',localStorage.getItem("token"));
    return this.http.get(`${this.mainBaseUrl}user/requestinquiry/${type}/${desc}`,{'headers': headers});
  }
  public requestCallBack(date,time) {
    let headers = new HttpHeaders().set('token',localStorage.getItem("sessionToken"));
    return this.http.get(`${this.mainBaseUrl}user/requestcallback/${date}/${time}`,{'headers': headers});
  }
  public generateSessionToken(data): Observable<any> {
    console.log('init11')
    // let headers = this.headers.set('Token', 'bb20c0eb6075e1127022d303f1e377fd');
    return this.http.post(this.baseUrl + 'init', data)
    // , { headers: headers });
  }

  // login(data, token): Observable<any> {
  //   let headers = this.headers.set('Token', token);
  //   localStorage.setItem("refreshRequest", this.encryptData({ password: data.password, mobile: data.Mobile }))
  //   return this.http.post(`${this.baseUrl}login`, data, { headers: headers });
  // }
  refreshData() {
    let value: any = this.decryptData(localStorage.getItem("refreshRequest"));
    let obj = { Mobile: value.mobile, password: value.password };
    // this.login(obj, localStorage.getItem("token")).subscribe(result => {
    //   if (result != null && result != undefined) {
    //     localStorage.setItem("loginResponse", JSON.stringify(result));
    //   }
    // })
  }
  public initToken() {
    return new Promise((resolve, reject) => {
      this.generateSessionToken(this.req).subscribe((resp) => {
        let sessionResp = resp
        if (sessionResp != undefined && sessionResp != null) {
          resolve(true);
          localStorage.setItem("token", sessionResp?.token);
        }
      }, err => {
        reject(false);
      })
    })
  }
  // registerFirstStep(data, token): Observable<any> {
  //   let headers = this.headers.set('Token', token);
  //   return this.http.post(`${this.baseUrl}register`, data, {
  //     headers: headers,
  //   });
  // }
  // registerSubStep(data): Observable<any> {
  //   let headers = this.headers.set(
  //     'Token',
  //     localStorage.getItem('token')
  //   );
  //   return this.http.post(`${this.baseUrl}register`, data, {
  //     headers: headers,
  //   });
  // }
  master(token: string): Observable<any> {
    // let headers = this.headers.set('Token', localStorage.getItem('sessionToken') ? localStorage.getItem('sessionToken') : localStorage.getItem('token'));
    return this.http.get(`${this.mainBaseUrl}Index/masters`)
    // , { headers: headers });
  }

  cardList(data): Observable<any> {
    // let headers = this.headers.set('Token', localStorage.getItem('sessionToken') ? localStorage.getItem('sessionToken') : localStorage.getItem('token'));
    return this.http.post(`${this.mainBaseUrl}Card/list`, data)
    // , {
    //   headers: headers,
    // });
  }
  financeList(data): Observable<any> {
    // let headers = this.headers.set('Token', localStorage.getItem('sessionToken') ? localStorage.getItem('sessionToken') : localStorage.getItem('token'));
    return this.http.post(`${this.mainBaseUrl}Loan/list`, data)
    // , {
    //   headers: headers,
    // });
  }


  // verification(data): Observable<any> {
  //   let headers = this.headers.set('Token', localStorage.getItem('token'));
  //   return this.http.post(`${this.mainBaseUrl}session/register/validation`, data, {
  //     headers: headers,
  //   });
  // }

  notify(msg, status) {
    this.notifier.notify(status, msg);
  }



  /* Auto Loans */

  public getAutoLoansList(data): Observable<any> {
    // let headers = this.headers.set('Token', localStorage.getItem('token'));
    try {
      return this.http.post(this.mainBaseUrl + "Auto/list", data, )
      // { headers: headers })
    } catch (error) {
      return error;
    }
  }

  public calculateNetSalaryAutoLoans(data): Observable<any> {
    // let headers = this.headers.set('Token', localStorage.getItem('token'));
    try {
      return this.http.post(this.mainBaseUrl + "user/UserIncomeStrategy", data)
      //  { headers: headers })
    } catch (error) {
      return error;
    }
  }

  public getAllVehiclesList(): Observable<any> {
    // let headers = this.headers.set('Token', localStorage.getItem('token'));
    try {
      return this.http.get(this.mainBaseUrl + "Auto/vehicledetails", )
      // { headers: headers })
    } catch (error) {
      return error;
    }
  }

  FilterValue(id, array) {
    return array?.filter((val) => val.name == "Management Fees")[0]?.value
      ? array?.filter((val) => val.name == "Management Fees")[0]?.value
      : 0;
  }
  FilterValue2(id, array) {
    return array?.filter((val) => val.name == "Early Settlement Fees")[0]?.value
      ? array?.filter((val) => val.name == "Early Settlement Fees")[0]?.value
      : 0;
  }
  public applyAutoLoan(): Observable<any> {

    let req = {

      UserID: this.profileDate.userSessions[0].userID,
      CategoryID: 3,
      autoMainID: this.autoLoanItem[0].autoMainID,
      ProductID: this.autoLoanItem[0].id,
      BankID: this.autoLoanItem[0].bankID,
      UserRequestStatusCode: 101,
      UserRequestProductInfo: {
        LoanOrCardAmount: this.autoLoanItem[0].actualLoanAmount,
        Duration: this.autoLoanItem[0].numberOfPayments,
        ProfitRate: this.autoLoanItem[0].rate,
        MonthlyInstallment: this.autoLoanItem[0].monthlyInstallment,
        ProductCategoryID: 1,
        SalaryTransfer: this.autoLoanItem[0].hasNoSalaryTransfer,
        TotalAmounttobepaid: this.autoLoanItem[0].totalPayments,
        APR: this.autoLoanItem[0].apr,
        Status: true,
        Request_datetime: new Date().toISOString(),
        ManagementFees: this.FilterValue(1, this.autoLoanItem[0]?.fees)
      }
    }

    if(this.selectedVehicleID != undefined) {
      req['VehicleId'] = parseInt(this.selectedVehicleID)
  }

    // let headers = this.headers.set('Token', localStorage.getItem('sessionToken'));
    try {
      return this.http.post(this.mainBaseUrl + "User/request/update", req,)
      //  { headers: headers })
    } catch (error) {
      return error;
    }
  }

applicationForm(app){
return this.http.post(`${this.mainBaseUrl}user/profile/updateapplicationform`,app)
}

productApply(product,category)
{
  return this.http.post(`${this.mainBaseUrl}user/request/${category}`,product)

}
  UserIncomeStrategy(data): Observable<any> {
    // let headers = this.headers.set('Token', localStorage.getItem('sessionToken'));
    return this.http.post(`${this.mainBaseUrl}/user/UserIncomeStrategy`, data)
    // , {
    //   headers: headers,
    // });
  }

  getVerificationOtp(data): Observable<any> {
    // let headers = this.headers.set('Token', localStorage.getItem('sessionToken'));
    console.log(data);
    console.log(decodeURI(data));
//  let re = new HttpParams({encoder: new CustomEncoder(), fromString: data.toString()});
// console.log(re.get('map'));/


    return this.http.get(`${this.mainBaseUrl}user/applyproduct/${data}`)
    // , {
    //   headers: headers,
    // });
  }
  VerifyOtp(otp): Observable<any> {
    // let headers = this.headers.set('Token', localStorage.getItem('sessionToken'));
    return this.http.get(`${this.mainBaseUrl}user/verifyapplyproduct/${otp}`)
    // , {
    //   headers: headers,
    // });
  }

  applyCard(): Observable<any> {
    let payload = {

      "UserID": JSON.parse(localStorage.getItem("loginResponse")).userPersonalEmployement?.userID,
      "CategoryID": parseInt(sessionStorage.getItem("CategoryID")),
      "ProductID": parseInt(JSON.parse(sessionStorage.getItem("selectedCard")).id),
      "BankID": parseInt(JSON.parse(sessionStorage.getItem("selectedCard")).bankID),
      "UserRequestStatusCode": 101,
      "UserRequestProductInfo":
      {
        "LoanOrCardAmount": parseFloat(JSON.parse(sessionStorage.getItem("selectedCard"))?.limit) ? parseFloat(JSON.parse(sessionStorage.getItem("selectedCard")).limit) : 0,
        "ProfitRate": parseFloat(JSON.parse(sessionStorage.getItem("selectedCard")).rate),
        "ProductCategoryID": parseFloat(JSON.parse(sessionStorage.getItem("selectedCard")).cardTypeID),
        "SalaryTransfer": (JSON.parse(sessionStorage.getItem("selectedCard")).hasNoSalaryTransfer),
        "APR": parseFloat(JSON.parse(sessionStorage.getItem("selectedCard")).apr),
        "Status": true,
        "Request_datetime": new Date().toISOString(),
      }
    }
    if (sessionStorage.getItem("CategoryID") == "2") {
      let selectCard = JSON.parse(sessionStorage.getItem("selectedCard"));
      let applyLoanData = JSON.parse(localStorage.getItem("applyLoanData"));
      payload.UserRequestProductInfo.LoanOrCardAmount = selectCard.actualLoanAmount;
      payload.UserRequestProductInfo.ProductCategoryID = selectCard.loanTypeID;
      payload.UserRequestProductInfo["Duration"] = applyLoanData.duration;
      payload.UserRequestProductInfo["MonthlyInstallment"] = selectCard.monthlyPaymentAmount;
      payload.UserRequestProductInfo["TotalAmounttobepaid"] = selectCard.allPaymentExcludingFees + selectCard.feesTotal;
      payload.UserRequestProductInfo["ManagementFees"] = applyLoanData.mangementFee;
    }
    // let headers = this.headers.set('Token', localStorage.getItem('sessionToken'));
    return this.http.post(`${this.mainBaseUrl}/User/request/update`, payload)
    // , {
    //   headers: headers,
    // });
  }





  DBR(data): Observable<any> {
    // let headers = this.headers.set('Token', localStorage.getItem('sessionToken'));
    return this.http.post(`${this.mainBaseUrl}/user/UserDBRStrategy`, data)
    // , {
    //   headers: headers,
    // });
  }

  encryptData(data) {
    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), this.encryptSecretKey).toString();
    } catch (e) {
      console.log(e);
    }
  }
  decryptData(data) {

    try {
      const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }


  /* Forgot passwrod & reset pwd */

  // forgotPassword(id):Observable<any> {
  //   let headers = this.headers.set('Token', localStorage.getItem('token'));
  //   try {
  //     return this.http.get(this.mainBaseUrl + `Session/forgotpassword/${id}`, { headers: headers })
  //   } catch (error) {
  //     return error;
  //   }
  // }

  // updatePassword(data):Observable<any> {
  //   let headers = this.headers.set('Token', localStorage.getItem('token'));
  //   try {
  //     return this.http.post(this.mainBaseUrl + `session/forgotpassword`, data, { headers: headers })
  //   } catch (error) {
  //     return error;
  //   }
  // }

  /* Forgot passwrod & reset pwd */

  /* View Shared Product Details API call */

  getSharedProductDetails(data):Observable<any> {
    // let headers = this.headers.set('Token', localStorage.getItem('token'));
    try {
      return this.http.post(this.mainBaseUrl + `Loan/details`, data)
      //  { headers: headers })
    } catch (error) {
      return error;
    }
  }


  /* View Shared Product Details API call */

  /* Open bank account API call */

  openBankAccount(data):Observable<any> {
    // let headers = this.headers.set('Token', localStorage.getItem('sessionToken'));
    try {
      return this.http.post(this.mainBaseUrl + `User/request/update`, data)
      // { headers: headers })
    } catch (error) {
      return error;
    }
  }


  shareIntent(url,cat) {


    // let headers = this.headers.set('Token', localStorage.getItem('token'));
    if(cat == "CreditCard"){

      // console.log(headers);
      return this.http.post(this.mainBaseUrl + `Card/urldetail`, {URL: url},)
      // { headers: headers })
    } else {
      return this.http.post(this.mainBaseUrl + `Loan/details`, {URL: url})
      //  { headers: headers })


    }

  }


  allCountries() {
    return this.http.get(this.countiresUrl);
  }
  /* Open bank account API call */

  // profileDate: any = JSON.parse(localStorage.getItem("loginResponse"))
  pesonalForm: FormGroup;
  contactForm: FormGroup;
  homeForm: FormGroup;
  relativeForm: FormGroup;
  employeForm: FormGroup;
  incomeForm: FormGroup;
  categoryId: any = sessionStorage.getItem("CategoryID")
  productForm: FormGroup;

  applicationFromData(){
    return {
      "userID":this.profileDate.id,
      "Title": this.pesonalForm.value.titleName,
      "EducationalLevelID":this.pesonalForm.value.educationLevel,
      "residenceTypeID":this.contactForm.value.residentialStatus,
      "email":this.contactForm.value.email,
      "LandLine":this.contactForm.value.Landline,

      "CountryName":this.homeForm.value.CountryName,
      "CityName":this.homeForm.value.CityName,
      "DistrictName":this.homeForm.value.DistrictName,
      "StreetName":this.homeForm.value.StreetName,
      "BuildingNumber":this.homeForm.value.BuildingNumber,
      "UnitNumber":this.homeForm.value.UnitNumber,
      "ZipCode":this.homeForm.value.ZipCode,
      "PoBOX":this.homeForm.value.PoBOX,
      "TelephoneNumber":this.homeForm.value.TelephoneNumber,

      "FullName":this.relativeForm.value.FullName,
      "RelationshipType":this.relativeForm.value.RelationshipType,
      "MobileNumber":this.relativeForm.value.MobileNumber,
      "RelativeTelephoneNumber":this.relativeForm.value.RelativeTelephoneNumber,

      "NatureOfBusiness":this.employeForm.value.NatureOfBusiness,
      "WorkAddress":this.employeForm.value.WorkAddress,
      "DepartmentType":this.employeForm.value.DepartmentType,

      "sourceOfIncomeID":this.incomeForm.value.sourceOfIncomeID,
      "monthlyExtraIncome":this.incomeForm.value.monthlyExtraIncome,
      "otherSourceOfIncomeID":this.incomeForm.value.otherSourceOfIncomeID,
      "TransportationType":this.incomeForm.value.TransportationType
    }
  }


  productInfoReq(){
    let selectedCard = JSON.parse(sessionStorage.getItem('selectedCard'))
    console.log(this.categoryId)
  let categoryId = sessionStorage.getItem("CategoryID");

    let reqData = {}
    let common = {
      "userID":this.profileDate.id,
      "BankID":selectedCard.bankID,
      "ProductID":selectedCard.id,
      "CategoryID":categoryId
    };
    let commonExbank =categoryId != '4'? {
      "AccountNumber":this.productForm.value.AccountNumber,
      "APR":selectedCard.apr?selectedCard.apr:0,
      "NetDBR":selectedCard?.maxDBR?selectedCard?.maxDBR:0,
      "AIP":selectedCard?.maxAIP?selectedCard?.maxAIP:0,
      "ProfitRate":selectedCard.rate,
      "ProductCategoryID":1,
    }:{}

    let creditCard = categoryId == '1'?{
      "CardOnName":this.productForm.value.CardOnName,
      "CardIsAnticipatedSignificantExpenses":Boolean(this.productForm.value.CardIsAnticipatedSignificantExpenses),
      "CardPurpose":this.productForm.value.CardPurpose,
      "CreditLimit":selectedCard.limit,
      "AnnualFee":selectedCard.annualFee,
      "MinimumMonthlySalary":selectedCard.minimumSalary,
      "MonthlyMinimumPayment":selectedCard.monthly_Fees,
    }:{}

    let motragage = categoryId == '5'? {
      "IsFirstHome":this.productForm.value.IsFirstHome,
      "MortgageIsMunicipalityClearance":this.productForm.value.MortgageIsMunicipalityClearance,
      "MortgageEstimatedPropertyValue":this.productForm.value.MortgageEstimatedPropertyValue,
      "MortgagePropertyType":this.productForm.value.MortgagePropertyType,
    }:{}
    let auto = categoryId == '3'? {
      "BalloonPayment":selectedCard.lastPayment?selectedCard.lastPayment:0,
      "VehicleId":this.productForm.value.VehicleId?this.productForm.value.VehicleId:0,
      "AutoMainID":0,

    }:{}
    let commonLoans = categoryId != '1' && categoryId != '4' ? {
      "LoanAmount":this.productForm.value.LoanAmount,
      "ActualLoanAmount":selectedCard.actualLoanAmount,
      "LoanTenure":this.productForm.value.LoanTenure,
      "MontlyInstallment":selectedCard.monthlyInstallment,
      "TotalAmounttobepaid":selectedCard.totalPayments
    }:{}
    let personalMotragage =  categoryId == '2' || categoryId == '5' ?{
      "LoanPurpose":this.productForm?.value.LoanPurpose,
      "ManagementFees":selectedCard?selectedCard?.fees[1]?.value:'',
    }:{}
    // console.log({
    //   "LoanPurpose":this.productForm?.value.LoanPurpose,
    //   "ManagementFees":selectedCard?.fees[1].value}
    // );

    let autoMotragege =categoryId == '3' || categoryId == '5' ? {
  "DownPayment":selectedCard?.productDownPayment

    }:{}
    let bank = {
      "AccountCurrency":"SAR",
      "Deposit":this.productForm.value.Deposit,
      "Withdrawal":this.productForm.value.Withdrawal,
      "AccountType":"Individual",
      "Purpose":this.productForm.value.Purpose,
    }
    // if(categoryId == 1){
    //   reqData = {...common,...creditCard}
    // }
    switch(categoryId){
      case '1' :reqData = {...creditCard,...commonExbank}
      break;
      case '2' :reqData = {...commonExbank,...personalMotragage,...commonLoans}
      break;
      case '3' :reqData = {...commonExbank,...autoMotragege,...commonLoans,...auto}
      break;
      case '4' :reqData = {...bank}
      break;
      case '5' :reqData = {...commonExbank,...autoMotragege,...commonLoans,...personalMotragage,...motragage}
      break;
      default:{}

    }
    console.log(reqData);

    return{
...common,...reqData
    }
  }
}
// "Mobile":"556746315",
//   "password":"Creditly@123"
class CustomEncoder implements HttpParameterCodec {

  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }


}
