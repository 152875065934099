import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { CreditlyServicesService } from 'src/app/creditly-services.service';
import { sourceOfIncomes } from 'src/app/shared/data/list-menus';
import { ProfileService } from 'src/app/shared/services/profile.service';

@Component({
  selector: 'app-income-info',
  templateUrl: './income-info.component.html',
  styleUrls: ['./income-info.component.scss']
})
export class IncomeInfoComponent implements OnInit {
  @Input() item = '';
  profileDate = JSON.parse(localStorage.getItem("loginResponse"));
  resType = JSON.parse(localStorage.getItem("masterData")).residenceTypes;
  type = { id: "", name: "" };
  loginFormValidationFlag = false;
  contactData = {
    "userID": this.profileDate?.userPersonalProfile?.userID,
    "residenceTypeID": this.profileDate?.userPersonalProfile?.residenceTypeID,
    "email": this.profileDate?.email,
    "addressId": this.profileDate?.userAddress[0]?.id,
    "isSave": true
  }
  submit = false;
  re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  incomeForm: FormGroup;
  incometData;
  sourceOfIncomes: { id: number; name: string; name_ar: string; }[];
  ;
  @Output() formGroup = new EventEmitter()
  // formGroup: FormGroup;
  private ngUnsubscribe = new Subject();

  constructor(private ApiService: CreditlyServicesService,private profile:ProfileService) {
    // this.initForm()
  }
  // private initForm() {
  //   this.formGroup = new FormGroup({
  //     userID: new FormControl(this.profileDate?.userPersonalEmployement?.userID),
  //     residenceTypeID: new FormControl(this.profileDate?.userPersonalProfile?.residenceTypeID, [Validators.required]),
  //     email: new FormControl(this.profileDate?.email, [Validators.required, Validators.pattern(this.re)]),
  //     addressId: new FormControl(this.profileDate?.userAddress[0]?.id, [Validators.required]),
  //     isSave: new FormControl(true, [Validators.required]),
  //   });
  // }

  ngOnInit(): void {
    this.sourceOfIncomes = sourceOfIncomes
    this.incomeForm = new FormGroup({
      // id: new FormControl(this.profileDate?.userPersonalEmployement?.id),
      sourceOfIncomeID: new FormControl(this.profileDate?.userPersonalProfile?.sourceOfIncomeID==0?'':this.profileDate?.userPersonalProfile?.sourceOfIncomeID, [Validators.required]),
      netSalary: new FormControl(this.profileDate?.userPersonalProfile?.netSalary, [Validators.required]),
      monthlyBasicIncome: new FormControl(this.profileDate?.userPersonalProfile?.monthlyBasicIncome, [Validators.required]),
      housingAllowance: new FormControl(this.profileDate?.userPersonalProfile?.housingAllowance, [Validators.required]),
      otherSourceOfIncomeID: new FormControl(this.profileDate?.userPersonalProfile?.otherSourceOfIncomeID==0?'':this.profileDate?.userPersonalProfile?.otherSourceOfIncomeID, [Validators.required]),
      monthlyExtraIncome: new FormControl(this.profileDate?.userPersonalProfile?.monthlyExtraIncome, [Validators.required]),
      otherAllowences: new FormControl(this.profileDate?.userPersonalProfile?.otherAllowences, [Validators.required]),
      monthlyDeductions: new FormControl(this.profileDate?.userPersonalProfile?.monthlyDeductions, [Validators.required]),

  dependencies: new FormControl(this.profileDate?.userPersonalProfile?.dependencies, [Validators.required]),

  TransportationType: new FormControl(this.profileDate?.userPersonalProfile?.transportationType, [Validators.required]),
      // retireeAgeAndMilitaryRankId: new FormControl(0, [Validators.required]),

    });


    // this.residenceTypes = residenceTypes
  let incomesentData =[
      {
          formControl:'sourceOfIncomeID',
          isEditable:true,
          visible:true,
          labelName:'Primary Source of Income',
     selectMenu:true,
    menuData:this.sourceOfIncomes


        },
      {
     formControl:'netSalary',
     isEditable:false,
     visible:true,
     labelName:'Net Salary',
     selectMenu:false
   },
   {
    formControl:'monthlyBasicIncome',
    isEditable:false,
    visible:true,
    labelName:'Monthly Basic Salary',
    selectMenu:false,


  },
  {
    formControl:'housingAllowance',
    isEditable:false,
    visible:true,
    labelName:'Monthly Fixed Allowances',
    selectMenu:false
  },  {
    formControl:'otherSourceOfIncomeID',
    isEditable:true,
    visible:true,
    labelName:'Source of Extra Monthly Income',
    selectMenu:true,
    menuData:this.sourceOfIncomes

  },
  {
    formControl:'monthlyExtraIncome',
    isEditable:true,
    visible:true,
    labelName:'Extra Income Amount',
    selectMenu:false
  }
  ,
  {
    formControl:'monthlyDeductions',
    isEditable:false,
    visible:true,
    labelName:'Monthly Deducations',
    selectMenu:false
  },
  {
    formControl:'TransportationType',
    isEditable:true,
    visible:true,
    labelName:'Transportation Type',
    selectMenu:true,
    menuData:this.resType?.map(res=>res.name)
  },
  {
    formControl:'dependencies',
    isEditable:false,
    visible:true,
    labelName:'Number of Dependents',
    selectMenu:false
  }








  ]
  this.incometData= {
    formData:incomesentData,
    formGroup: this.incomeForm,
    type:'apply'

    // formLabels:Object.keys(formObjecObligations)
  }

  this.incomeForm.statusChanges.subscribe((res)=>{
    console.log(res);
  if(res == "VALID"){

    this.formGroup.emit(this.incomeForm);
  }

  })

    }
    ngAfterViewInit(){
   this.formGroup.emit(this.incomeForm);

    }


  // loginFormValidationFlag = false
  update(){
if (this.incomeForm.invalid){
  this.loginFormValidationFlag = true
}
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  change(event) {
    let id = event.target.value
    // this.formGroup.patchValue({ residenceTypeID: parseInt(id) });
  }
  // update() {
  //   this.loginFormValidationFlag = this.formGroup.invalid ? true : false;
  //   if (!this.loginFormValidationFlag) {
  //     this.submit = true;
  //     this.profile.profileContact(this.formGroup.value).subscribe(val => {
  //       this.submit = false;
  //       this.ApiService.refreshData();
  //       if(this.item!=='true')this.ApiService.notify("Successfully updated", "success");
  //     })
  //   } else {
  //     this.ApiService.notify("Please fill all required fileds", "error");
  //   }
  // }
  // changeAddress(id) {
  //   this.formGroup.patchValue({ addressId: parseFloat(id) })
  // }
}
