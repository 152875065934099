<ng-container *ngIf='reqType !== "history"'>

    <div class="row mt-3 mb-5 align-items-start request-contain" *ngFor="let item of request">
        <div class="col-lg-4 mb-3  ">
            <div class="visa_image border-0" [ngClass]="{'border-0':true}">
                <img [src]="item.card?.image" alt="image" onerror="this.onerror=null;this.src='../../../assets/images/main_images/Group_card_compare.svg'">
            </div>
            <!-- <div class="row mt-3 align-items-center d-none d-lg-flex">
              <div class="col-md-12">
                  <button class="btn btn-md btn-block apply-btn d-flex align-items-center justify-content-center">{{"Apply Now"|translate}}

                    </button>
              </div>

          </div> -->
        </div>
        <div class="col-lg-8 visa_header ">
            <div class="visa_header_content d-flex justify-content-between">
                <div class="visa_titles ">
                    <h2> {{item.card?.name}} </h2>
                    <span>
                      <img src="../../../../assets/images/icons/credit-icon-req.svg" alt="">
                      Credit Cards
                    </span>
                </div>
                <div class=" share-btn">
                    <button class='btn-cred btn btn-app'>
                      <img
                        src="../../../../assets/images/icons/download-app.svg" alt="">
                      {{"Application"|translate}}
                    </button>
                </div>
            </div>
            <div class="req-date-detils d-flex align-items-center">
                <div class="req-date d-flex  align-items-center">

                    <p class='date-head'>
                        Req Date:
                    </p>
                    <span>
                          {{item.request_datetime|date:'dd,MM,yyyy'}}
                        </span>
                </div>
                <div class="req-date d-flex  align-items-center">
                    <p class="date-head">
                        Req#
                    </p>
                    <span class='ref-num'>
                          {{item.reference_ID}}

                        </span>
                </div>
            </div>
            <div class="car-footer d-flex justify-content-between align-items-center">

                <div class="badge-serv d-flex align-items-center">
                    <span [ngClass]="{'pending':reqType == 'pending','approved':reqType == 'approved','reject':reqType == 'rejected'}">
                          {{reqType == 'pending'?'User requested for service':reqType == 'approved'?'Success':'Not Eligible Credit History'}}
                        </span>

                    <button class="upload " *ngIf='reqType == "pending"'>
                      <img src='../../../../assets/images/icons/upload-icon.svg'>
                      Upload
                  </button>
                </div>
                <div class="cancel-btn" *ngIf='reqType == "pending"'>
                    <button class='btn-cred' (click)='open(content,item.id)'>
                          Cancel
                        </button>
                </div>
                <div class="cancel-btn" *ngIf='reqType == "rejected"'>
                    <button class='btn-cred'>
                  See all products
                      </button>
                </div>
            </div>







        </div>


        <ng-template #content let-modal>

            <div class="modal-body ">
                <div class="head-title">
                    <h2>
                        Are you sure you want to cancel the request ?
                    </h2>
                </div>
                <div class="form-group">
                    <label for="">
                      Cancelation reason
                      <span class="text-danger">*</span>
                    </label>
                    <textarea [formControl]="cancelReason" class='form-control' name="" id="" cols="30" rows="4"></textarea>

                </div>
                <div class="modal-cance-res d-flex justify-content-end">

                    <button type="button" class="btn btn-outline-success mr-3 px-4" (click)="modal.close('Save click')">Dismiss</button>
                    <button type="button" class="btn btn-danger d-flex align-items-center px-4" [disabled]="cancelReason.invalid" (click)="cancelByConfirm(cancelReason.value)">
                    <img class="mr-2" src="../../../../assets/images/icons/cancel-req.svg" alt="cancel request">
                    Confirm
                  </button>
                </div>

            </div>

        </ng-template>







    </div>
</ng-container>
<div class="row" *ngIf='reqType == "history"'>
    <div class="col-md-12">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 w-100">

            <!--- Note that these columns can be defined in any order.
      The actual rendered columns are set as a property on the row definition" -->




            <!-- Name Column -->
            <ng-container matColumnDef="Name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element"> <img width="54" height="34" [src]="element.card.image" alt=""> {{element.card.name}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="Product">
                <th mat-header-cell *matHeaderCellDef> Product </th>
                <td mat-cell *matCellDef="let element">
                    <span class="tabel-card">
                    <img src="../../../../assets/images/icons/credit-icon-req.svg" alt="">
                    {{element.categoryID==1?'Credit Card':"C"}}
                  </span>
                </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="Date">
                <th mat-header-cell *matHeaderCellDef> Date </th>
                <td mat-cell *matCellDef="let element"> {{element.request_datetime |date}} </td>
            </ng-container>
            <ng-container matColumnDef="Status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element">
                    <div class="badge-serv d-flex align-items-center">
                        <span [ngClass]="{'pending':element.status == 101,'approved':element.status ==104,'reject':element.status !== 101&&element.status !== 104}">
                          {{element.status == 101?'User requested for service':element.status ==104?'Success':'Not Eligible Credit History'}}
                        </span>
                    </div>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

</div>







<!--
<div class="container">
  <div class="row mb-4">
    <div class="">
      <p class="req-title pl-3">Pending</p>
      <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"
        (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
        (beforeChange)="beforeChange($event)">
        <ng-container *ngFor="let req of request">
          <div ngxSlickItem class="slide" *ngIf="req?.status==101">
            <div class="custom-card card">
              <div class="card-body f-12 px-3 py-4">
                <div class="cc-img">
                  <img style="height: 61px;"
                    onerror="this.onerror=null;this.src='../../../assets/images/icons/card?.png';"
                    src="{{req?.card?req?.card?.image:req?.bank?.image}}" width="80px">
                </div>
                <div class="cc-title">
                  <p class="mb-1" *ngIf="categoryID!='bank'">{{req?.card?"Credit card":"Finance"}}</p>
                  <p class="mb-1" *ngIf="categoryID=='bank'">Bank</p>
                  <p class="f-18 c-txt" *ngIf="categoryID!='bank'">{{req?.card?req?.card?.name:req?.loan?.name}}</p>
                  <p class="f-18 c-txt" *ngIf="categoryID=='bank'">{{req?.bank?.name}}</p>
                </div>
                <div>
                  <p class="mb-1"><span><b>Status</b></span> &nbsp;
                    <span>{{req?.requestStatus?.description}}</span>
                  </p>
                  <p class="mb-1"><span><b>Req Date</b></span> &nbsp;
                    <span>{{req?.request_datetime | date}}</span>
                  </p>
                  <p class=""><span><b>Req #</b></span> &nbsp;
                    <span>{{req?.reference_ID}}</span>
                  </p>
                </div>
                <div class="text-center" *ngIf="req?.requestStatus?.code!=107">
                  <button class="btn btn-sm cc-btn-primary" data-toggle="modal"
                    data-target="#exampleModal">upload</button>
                </div>
                <div class="text-center mt-1" *ngIf="req?.requestStatus?.code!=107">
                  <button class="btn btn-sm cc-btn-danger" data-toggle="modal" data-target="#confirmed"
                    (click)="cancel(req?.id,req?.reference_ID)">cancel</button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ngx-slick-carousel>
    </div>

  </div>
  <div *ngIf="request?.length==0" class="noDataFound MRT">
    No History Found
  </div>
  <div class="row mb-4">
    <div class="">
      <p class="req-title pl-3 mt-5">Rejected</p>
      <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"
        (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
        (beforeChange)="beforeChange($event)">
        <ng-container *ngFor="let req of request">
          <div ngxSlickItem class="slide"
            *ngIf="req?.status==112 || req?.status==110 || req?.status==109 || req?.status==105 || req?.status==111">
            <div class="custom-card card">
              <div class="card-body f-12 px-3 py-4">
                <div class="cc-img">
                  <img style="height: 44px;"
                    onerror="this.onerror=null;this.src='../../../assets/images/icons/card?.png';"
                    src="{{req?.card?req?.card?.image:req?.bank?.image}}" width="80px">
                </div>
                <div class="cc-title">
                  <p class="mb-1" *ngIf="categoryID!='bank'">{{req?.card?"Credit card":"Finance"}}</p>
                  <p class="mb-1" *ngIf="categoryID=='bank'">Bank</p>
                  <p class="f-18 c-txt" *ngIf="categoryID!='bank'">{{req?.card?req?.card?.name:req?.loan?.name}}</p>
                  <p class="f-18 c-txt" *ngIf="categoryID=='bank'">{{req?.bank?.name}}</p>
                </div>
                <div>
                  <p class="mb-1"><span><b>Status</b></span> &nbsp;
                    <span>{{req?.requestStatus?.description}}</span>
                  </p>
                  <p class="mb-1"><span><b>Req Date</b></span> &nbsp;
                    <span>{{req?.request_datetime | date}}</span>
                  </p>
                  <p class=""><span><b>Req #</b></span> &nbsp;
                    <span>{{req?.reference_ID}}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

      </ngx-slick-carousel>
    </div>
  </div>

  <div *ngIf="request?.length==0" class="noDataFound MRT ">
    No Request Found
  </div>
  <div class="row m-0">
    <div class="d-flex justify-content-between w-100 mt-5">
      <p><b>History</b></p>
      <p><b>Filter</b></p>
    </div>
    <div class="table-responsive">
      <table class="table req-table">
        <tbody>
          <tr *ngFor="let req of request">
            <td>
              <img src="{{req?.card?req?.card?.image:req?.bank?.image}}" height="45px" />
            </td>
            <td>
              <p class="mb-0" *ngIf="categoryID!='bank'">{{req?.card?"Credit card":"Finance"}}</p>
              <p class="mb-0" *ngIf="categoryID=='bank'">Bank</p>
              <p class="m-0 f-14" *ngIf="categoryID!='bank'">{{req?.card?req?.card?.name:req?.loan?.name}}</p>
              <p class="f-14 m-0" *ngIf="categoryID=='bank'">{{req?.bank?.name}}</p>
            </td>
            <td class="align-middle">
              <p class="mb-0">{{req?.request_datetime | date}}</p>
            </td>
            <td class="align-middle">
              <p class="mb-0">{{filter(req?.requestStatusList,req?.status)}}</p>
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="request?.length==0" class="noDataFound MRT">
        No History Found
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modalClass" role="document">
    <div class="modal-content modal-con">
      <div class="modal-header">
        <h5 class="head" id="exampleModalLongTitle">Upload missing documents</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row  mb-3">
            <div class="col-sm-5 col-head">
              ID
            </div>
            <div class="col-sm-7 button" *ngIf="idState==1">
              <span class="material-icons-outlined">
                file_upload
              </span>
              <p>upload</p>
              <input id="file" type="file" class="form-control" (change)="onFileChange1($event)"
                accept="application/pdf,image/*">
            </div>

            <div class="col-sm-7 pdf" *ngIf="idState==2">
              <div class="span2">
                <span class="icon-asset material-icons ng-star-inserted ">
                  picture_as_pdf
                </span>
                <p>{{fileName1}}</p>
              </div>
              <span class="material-icons-outlined span1">
                clear
              </span>
            </div>
            <div class="col-sm-7 button" *ngIf="idState==4">
              <p>uploaded successfully</p>
            </div>
            <div class="col-sm-7" *ngIf="idState==3">
              <div class="upload">
                <p>{{fileName1}}</p>
                <svg class="svg" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                  fill="#000000">
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                </svg>
              </div>
              <div class="progress bg-size">
                <div class="progress-bar bg-color bg-size" role="progressbar" [ngStyle]="{'width': progress.p1 +'%'}"
                  aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <div class="upload">
                <p class="svg">{{progress.p1}}% done</p>
              </div>

            </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-5 col-head">
              Salary Certificate
            </div>
            <div class="col-sm-7 button" *ngIf="salaryState==1">
              <span class="material-icons-outlined">
                file_upload
              </span>
              <p>upload</p>
              <input id="file" type="file" class="form-control" (change)="onFileChange2($event)"
                accept="application/pdf,image/*">
            </div>
            <div class="col-sm-7 button" *ngIf="salaryState==4">
              <p>uploaded successfully</p>
            </div>
            <div class="col-sm-7 pdf" *ngIf="salaryState==2">
              <div class="span2">
                <span class="icon-asset material-icons ng-star-inserted ">
                  picture_as_pdf
                </span>
                <p>{{fileName2}}</p>
              </div>
              <span class="material-icons-outlined span1">
                clear
              </span>
            </div>

            <div class="col-sm-7" *ngIf="salaryState==3">
              <div class="upload">
                <p>{{fileName2}}</p>
                <svg class="svg" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                  fill="#000000">
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                </svg>
              </div>
              <div class="progress bg-size">
                <div class="progress-bar bg-color bg-size" role="progressbar" [ngStyle]="{'width': progress.p2 +'%'}"
                  aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <div class="upload">
                <p class="svg">{{ progress.p2 }}% done</p>
              </div>
            </div>
          </div>
          <div class="row mb-3 ">
            <div class="col-sm-5 col-head">
              Other
            </div>
            <div class="col-sm-7 button" *ngIf="otherState==1">
              <span class="material-icons-outlined">
                file_upload
              </span>
              <p>upload</p>
              <input id="file" type="file" class="form-control" (change)="onFileChange3($event)"
                accept="application/pdf,image/*">
            </div>
            <div class="col-sm-7 button" *ngIf="salaryState==4">
              <p>uploaded successfully</p>
            </div>
            <div class="col-sm-7 pdf" *ngIf="otherState==2">
              <div class="span2">
                <span class="icon-asset material-icons ng-star-inserted ">
                  picture_as_pdf
                </span>
                <p>{{fileName3}}</p>
              </div>
              <span class="material-icons-outlined span1">
                clear
              </span>
            </div>

            <div class="col-sm-7" *ngIf="otherState==3">
              <div class="upload">
                <p>{{fileName3}}</p>
                <svg class="svg" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                  fill="#000000">
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                </svg>
              </div>
              <div class="progress bg-size">
                <div class="progress-bar bg-color bg-size" role="progressbar" [ngStyle]="{'width': progress.p3 +'%'}"
                  aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <div class="upload">
                <p class="svg">{{progress.p3}}% done</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-2 mb-3">
        <button style="width: 120px;" (click)="submit()" [disabled]="submitReq"
          class="btn btn-sm cc-btn-primary">Submit</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="confirmed" tabindex="-1" role="dialog" aria-labelledby="confirmTitle">
  <div class="modal-dialog" role="document">
    <div class="modal-content modal-con">
      <div class="modal-header">
        <h5 class="modal-title">Confirm</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="confirmBtn2">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Are you sure you want to cancel request no. {{reqCancel}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" [disabled]="submitReq"
          (click)="cancelByConfirm()">Confirm</button>
        <button type="button" class="btn btn-secondary" (click)="click()">Close</button>
      </div>
    </div>
  </div>
</div> -->
