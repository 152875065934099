<div class="cards-compare-list" *ngIf="compareListData?.length">
    <div class="card custom-card">
        <div class="card-body">
            <div class="list-items justify-content-between">
                <div class="list-content d-flex align-items-center">

                    <div *ngFor="let item of compareListData;let i= index;" class="cancel" [ngClass]="{'credit-cancel':catName=='CreditCard'}">
                        <div class="btn-group" [ngClass]="{'btn-group_ar':currentLang == 'ar'}">
                            <button (click)="removeProduct(item)" id="button-dropup" type="button" class="btn  dropdown-toggle" aria-controls="dropdown-dropup">
                            <img  class="i" src="../../../assets/images/icons/cancel-circlye.svg" alt=""> <span class="caret"></span>
                    </button>
                            <!-- <li  role="menuitem">
                      <a class="dropdown-item">
                          <i class="far fa-trash-alt"></i> {{"Remove"|translate}}</a>
                  </li> -->
                            <!-- <ul id="dropdown-dropup" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-dropup">
                                <li (click)="previewProduct(item)" role="menuitem"><a class="dropdown-item d-flex"><span class="material-icons">
                              pageview
                              </span>{{"Preview"|translate}}</a></li>
                                <li class="divider dropdown-divider"></li>

                                <li (click)="shareURL(item)" role="menuitem"><a class="dropdown-item"><i class="far fa-share-square"></i>
                              {{"Share"|translate}}

                            </a>
                                </li>
                                <li class="divider dropdown-divider"></li>



                            </ul> -->
                        </div>

                        <img [ngClass]="{'cardImage_credit':catName=='CreditCard'}" class="cardImage" src="{{catName=='CreditCard'?item.image:item?.bank?.image}}" onerror="this.onerror=null;this.src='../../../assets/images/main_images/Group_card_compare.svg';" alt="">
                    </div>
                </div>

                <div class='compares-btns'>

                    <button (click)="navigateToComparePage()" class="btn btn-md compare-cards-btn">{{"Compare"|translate}}
                            <span>{{compareListData.length}}</span></button>
                    <button (click)="resetList()" class='btn btn-clear-wrapper' style="cursor: pointer;">{{"Reset"|translate}}</button>
                </div>

            </div>
        </div>
    </div>
</div>
