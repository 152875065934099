import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'convertNumbers'
})
export class ConvertNumbersPipe implements PipeTransform {

//    commafy( num ) {
//     //  console.log(num.split(','))
//      var str = num.toString().split('.');
//     //  console.log(num)
//      if (str[0].length <= 4) {
//       //  console.log('444564asad')
//       // str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
//      return num
//   }
//     if (str[0].length >= 5) {
//         str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
//     }
//     if (str[1] && str[1].length >= 5) {
//         str[1] = str[1].replace(/(\d{3})/g, '$1 ');
//     }
//     return str.join('.');
// }

  transform(n: number,x) {
    // console.log(n)
    // console.log(n.toLocaleString('en'))

    if (n === null || n === undefined) {
      return '';
    }
    if(x == 'en'){
// console.log(new Intl.NumberFormat('en-IN',{}).format(n))
// let num =  n.toLocaleString('en')
   return n.toLocaleString('en')
    }
// console.log(new Intl.NumberFormat('ar-SA',{}).format(n))

    return new Intl.NumberFormat('ar-SA',{}).format(n);

  }

}
