import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-main-background',
  templateUrl: './main-background.component.html',
  styleUrls: ['./main-background.component.scss']
})
export class MainBackgroundComponent implements OnInit {
@Input() backgroundData:{
title:string,
twoParts:boolean;
imageBackground?:string;
subTitle;
}
  mainTitle: string;
  imageBackground: string;
  currentLang: any;
  subTitle;
  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    this.mainTitle = this.backgroundData.title;
    this.imageBackground = this.backgroundData.imageBackground;
    this.subTitle = this.backgroundData.subTitle
    this.currentLang =localStorage.getItem('currentLang');
    this.translate.onLangChange.subscribe(lang=>{
      this.currentLang = lang.lang
    })


  }

}
