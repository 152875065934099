import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  token
  shareHeader =new HttpHeaders();
 heades = {
  Authorization:'API_User_Auth ' +
  'JGNyZWRpdGx5d2ViYXBpOmUxMGFkYzM5NDliYTU5YWJiZTU2ZTA1N2YyMGY4ODNl',
  APIKey:'5A2A6A7E-F86F-4877-83D3-9',
  APPType:'1',
  APPCode: 'CR1T1',
  "Content-Type": 'application/json',
  "Accept-Language":"en",

  'Token': 'bb20c0eb6075e1127022d303f1e377fd'
 }
  constructor(private auth: AuthService) {
    // this.shareHeader.set(
    //   'Authorization',
    //   'API_User_Auth ' +
    //   'JGNyZWRpdGx5d2ViYXBpOmUxMGFkYzM5NDliYTU5YWJiZTU2ZTA1N2YyMGY4ODNl'
    // )
    // .set('APIKey', '5A2A6A7E-F86F-4877-83D3-9')
    // .set('APPType', '1')
    // .set('APPCode', 'CR1T1')
    // .set('Content-Type', 'application/json')
    // .set('Accept-Language', 'en'),
    // 'Token', 'bb20c0eb6075e1127022d303f1e377fd'
   }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.heades.Token = this.auth.getUserToken()?this.auth.getUserToken():this.heades.Token
    this.token = this.auth.getUserToken()?this.auth.getUserToken():this.heades.Token;
    const isApiUrl = req.url.startsWith(environment.baseUrl) || req.url.startsWith(environment.mainBaseUrl);

    if (this.token && isApiUrl) {
      const tokenizedReq = req.clone({ setHeaders: this.heades });
      return next.handle(tokenizedReq);
    }
    return next.handle(req);
  }
}
