<div class="auto-lease-finance-section">

    <div class="cards_list">
        <div class="card custom-card">
            <div class="card-body">
                <div class="visa_main_part">
                </div>
                <div>
                    <div class="row mt-3 align-items-start">
                        <div class="col-lg-3 mb-3  ">
                            <div class="visa_image " [ngClass]="{'border-0':catName == 'CreditCard'}">
                                <img src="{{catName == 'CreditCard'?item?.image:item?.bank?.image?item?.bank?.image:item?.image}}" alt="image" onerror="this.onerror=null;this.src='../../../assets/images/main_images/Group_card_compare.svg'">
                            </div>
                            <div *ngIf="autoLeaseData.type != 'applynow'" class="row mt-3 align-items-center d-none d-lg-flex">
                                <div class="col-md-12">
                                    <button [disabled]="!item?.isEligible" class="btn btn-md btn-block apply-btn d-flex align-items-center justify-content-center" (click)="applyForAutoLoan(item)" [ngClass]="{'inelegible':!item?.isEligible}" (click)="applyForAutoLoan(item)">{{item?.isEligible == true?("Apply Now"|translate):("Inelegible"|translate)}}
                    <img src="" alt="">
                    <span class="material-icons-outlined">
                      {{item?.isEligible == true?'logout':'lock'}}
                    </span>
                  </button>
                                </div>
                                <div class="col-md-12 text-center mt-3">
                                    <p *ngIf='!item?.isEligible && item?.eligibleErrorMsg' class="inelegible-text text-capitalize">
                                        {{item?.eligibleErrorMsg}}

                                    </p>
                                </div>
                            </div>
                            <div class="more-details-bt m-auto" *ngIf="autoLeaseData.type == 'applynow'">
                                <button class="btn btn-md btn-block goto_details" (click)="showCardDetails()">
                <span *ngIf="!showCardDetailsFlag" class='d-flex justify-content-between w-100 align-items-center'>
                  <span>{{"More Details"|translate}}</span>
                  <i class="fas fa-angle-down"></i>
                </span>
                <span *ngIf="showCardDetailsFlag" class='d-flex justify-content-between w-100 align-items-center'>
                  <span>{{"Hide Details"|translate}}</span>
                  <i class="fas fa-angle-up"></i>
                </span>
              </button>
                            </div>
                        </div>
                        <div class="col-lg-9 visa_header">
                            <div class="visa_header_content d-flex justify-content-between">
                                <div class="visa_titles">
                                    <h1> {{curretLang == 'en'?item.name:item.name_ar}} </h1>
                                    <h6 *ngIf="catName !== 'CreditCard'"> {{curretLang == 'en'?item?.bank?.name:item?.bank?.name_ar}}
                                    </h6>
                                    <h6 *ngIf="catName == 'CreditCard'">
                                        {{curretLang == 'en'?filterTitle(banksList,item?.bankID).name:filterTitle(banksList,item?.bankID).name_ar}}
                                    </h6>
                                </div>
                                <div class="share-btn">
                                    <button class='btn btn-share'>
                    <img (click)="shareURL(item)" [ngClass]='{"img_ar":curretLang=="ar"}'
                      src="../../../../assets/images/icons/share-new.png" alt="">

                    {{"Share"|translate}}
                  </button>
                                </div>
                                <share-buttons *ngIf='enableShare' [theme]="'material-dark'" [include]="['facebook','twitter','linkedin','pinterest','reddit','mix','vk','telegram','messenger','whatsapp','xing','line','sms','email','copy']" [show]="3" [size]="1" [url]="'https://beta.creditly.sa/#/creditly/productShare/?url='+item?.seo?.url+'&catId='+catName"
                                    [image]="catName == 'CreditCard'?item?.image:item?.bank.image" [autoSetMeta]="true"></share-buttons>

                            </div>
                            <div class="card-options d-flex align-items-start" [ngClass]="{'mb-md-5 mb-2':(!item.isinsuranceIncluded&&!item.shariah_Compliant)&&(!item.isCashBack&&!item.isLoungeAccess)}">
                                <div class="col-md-6 order-2 order-md-0" *ngIf="item.isinsuranceIncluded && catName !== 'CreditCard'">
                                    <div class="insu_comp d-flex align-items-center">
                                        <img [ngClass]='{"img_ar":curretLang=="ar"}' src="../../../assets/images/main_images/np_insurance.svg" alt="">
                                        <span>{{"Insurance included"|translate}}</span>
                                    </div>
                                </div>
                                <div class="mr-2" *ngIf="item.isCashBack">


                                    <div class="insu_comp d-flex align-items-center insu-new-d justify-content-center cash-color">
                                        <img [ngClass]='{"img_ar":curretLang=="ar"}' class='cash-img' src="../../../../assets/images/credit-cards/Icon (Stroke).png" alt="">
                                        <span>{{"Cash back"|translate}}</span>
                                    </div>
                                </div>
                                <div class="mr-2" *ngIf="item.isLoungeAccess">
                                    <div class="insu_comp d-flex align-items-center justify-content-between insu-new-d longue-access">
                                        <img [ngClass]='{"img_ar":curretLang=="ar"}' src="../../../../assets/images/credit-cards/Vector.png" alt="">
                                        <span>{{item.loungeAccess }} {{"lounge access"|translate}}</span>
                                    </div>
                                </div>
                                <div class="mr-2" *ngIf="item.isNoAnualFee">
                                    <div class="insu_comp d-flex align-items-center justify-content-between insu-new-d annual-fee">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M0.913259 16.4932C0.573673 16.8328 0.573673 17.3834 0.913259 17.723C1.25285 18.0626 1.80342 18.0626 2.14301 17.723L3.80682 16.0592H15.0579C15.2588 16.0592 15.4515 15.9794 15.5935 15.8373C15.7356 15.6953 15.8154 15.5026 15.8154 15.3017V6.21166C15.8154 6.01076 15.7356 5.81809 15.5935 5.67603C15.4515 5.53397 15.2588 5.45416 15.0579 5.45416H14.4118L17.5124 2.35358C17.852 2.014 17.852 1.46342 17.5124 1.12383C17.1728 0.784244 16.6223 0.784244 16.2827 1.12383L0.913259 16.4932ZM12.8968 6.96916L5.32183 14.5442H14.3004V6.96916H12.8968ZM2.18037 6.96916H8.41763L9.93263 5.45416H2.18037V3.93916H11.2704V4.11641L12.7854 2.6014V2.42415H1.42286C1.22196 2.42415 1.02929 2.50396 0.88723 2.64602C0.745171 2.78808 0.665363 2.98075 0.665363 3.18165V14.7214L2.18037 13.2064V6.96916ZM10.5129 9.99917H12.7854V11.5142H10.5129V9.99917Z"
                        fill="rgba(40, 47, 58, 0.8)" />
                    </svg>
                                        <span>{{"No Annual Fees"|translate}}</span>
                                    </div>

                                </div>
                                <div class="mr-2" *ngIf="item.shariah_Compliant">
                                    <div class="insu_comp d-flex align-items-center justify-content-between insu-new-d sharia-comp">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.343" height="18.343" viewBox="0 0 37.343 37.343" fill="rgba(40, 47, 58, 0.8)">
                      <g id="np_islamic_3511173_000000" transform="translate(-3.125 -3.125)">
                        <path id="Path_4457" data-name="Path 4457"
                          d="M21.88,3.125l-.44.44L16.363,8.6l-7.77,0v7.7L3.124,21.714l5.468,5.518V35h7.7l5.421,5.468L27.226,35h7.769V27.3l5.472-5.416-5.472-5.518V8.6H27.3Zm0,1.764,4.907,4.953h6.974v7.034l4.953,5-4.953,4.907v6.974l-7.041,0-5,4.953-4.911-4.953H9.837V26.716l-4.953-5,4.953-4.907V9.842h7.034Z"
                          transform="translate(0 0)" />
                        <path id="Path_4458" data-name="Path 4458"
                          d="M28.929,14.82l-.445.44L24.79,18.926H18.97V24.7l-4.1,4.066,4.1,4.143v5.83h5.771l4.061,4.1,4.134-4.1h5.825V32.964l4.1-4.067-4.1-4.139v-5.83H32.989Zm-.009,1.76,3.551,3.593h5.044v5.1L41.1,28.889l-3.583,3.555V37.5H32.424L28.8,41.081,25.257,37.5H20.213V32.4L16.63,28.778l3.583-3.557V20.172H25.3Z"
                          transform="translate(-7.068 -7.037)" />
                      </g>
                    </svg>
                                        <span>{{"Shariah complaint"|translate}}</span>

                                    </div>
                                </div>

                            </div>
                            <div class="row amount_types d-flex d-lg-grid" [ngClass]="{'mt-2 mb-2':catName=='AutoLoans'||catName=='Mortgage','mt-4 mb-4':catName=='CreditCard'}">
                                <div class="col-md-4 d-md-block  d-flex flex-row-reverse justify-content-between" [ngClass]="{'mb-2':catName=='AutoLoans','mb-2 col-md-6':catName=='Mortgage'}">
                                    <div class="amount_text d-lg-auto m-0">
                                        <p *ngIf=" catName !== 'CreditCard' && catName !== 'PersonalFinance'">{{"loanAmount"|translate}}
                                        </p>
                                        <p *ngIf=" catName == 'PersonalFinance'">{{"All Payments"|translate}}<br class='d-lg-block d-none'> {{"Including Fees"|translate}}</p>
                                        <p class="pr-lg-4 pr-0" *ngIf=" catName == 'CreditCard'">
                                            {{"Annual fee for the first year"|translate}}</p>
                                    </div>

                                    <div class="amount m-lg-auto m-0">
                                        <h2 *ngIf=" catName !== 'CreditCard' && catName !== 'PersonalFinance'" [ngClass]='{ "sar_0":item.maxPrincipal == 0}'> {{item?.maxPrincipal|convertNumbers:curretLang}} {{"SAR"|translate}}
                                        </h2>
                                        <h2 *ngIf=" catName == 'CreditCard'" [ngClass]='{ "sar_0":item.annualFee == 0}'>
                                            {{item?.annualFee |convertNumbers:curretLang}} {{"SAR"|translate}}</h2>
                                        <h2 *ngIf=" catName == 'PersonalFinance'" [ngClass]='{ "sar_0":item.totalPayments == 0}'>
                                            {{item?.totalPayments |convertNumbers:curretLang}} SAR</h2>

                                    </div>
                                </div>
                                <div class="d-md-block  d-flex flex-row-reverse justify-content-between mb-2" [ngClass]='{"col-md-4":catName!="Mortgage","col-md-6":catName=="Mortgage"}'>
                                    <div class="amount_text d-lg-auto m-0">
                                        <p *ngIf=" catName !== 'CreditCard'">{{"Monthly Installment"|translate}}</p>
                                        <p class="pr-lg-5 pr-0" *ngIf=" catName == 'CreditCard'">{{"Annual Percentage Rate"|translate}}</p>

                                    </div>

                                    <div class="amount  m-lg-auto m-0">
                                        <h2 *ngIf=" catName !== 'CreditCard'" [ngClass]='{ "sar_0":item.monthlyInstallment == 0}'>
                                            {{item.monthlyInstallment |convertNumbers:curretLang}} {{"SAR"|translate}}</h2>
                                        <h2 *ngIf=" catName == 'CreditCard'" [ngClass]='{ "sar_0":item.rate == 0}'>
                                            {{item?.rate |convertNumbers:curretLang}}%</h2>

                                    </div>

                                </div>
                                <div class="d-md-block  d-flex flex-row-reverse justify-content-between mb-2" [ngClass]='{"col-md-4":catName!="Mortgage","col-md-6 order-3":catName=="Mortgage"}'>

                                    <div class="amount_text m-lg-auto m-0">
                                        <!-- <p *ngIf='catName!=="AutoLoans" &&catName!="Mortgage"'>{{"Management"|translate}}<br class='d-lg-block d-none'>{{"Fees"|translate}}</p> -->
                                        <p *ngIf=" catName !== 'CreditCard'">{{"APR"|translate}}</p>
                                        <p *ngIf=" catName == 'CreditCard'">{{"Credit Limit"|translate}}</p>

                                    </div>
                                    <div class="amount  m-lg-auto m-0">
                                        <!-- <h2> {{item.managementFee}} </h2> <span>%</span> -->
                                        <!-- <h2 *ngIf='catName!=="AutoLoans"&&catName!="Mortgage"'>{{FilterValue(1,item)|convertNumbers:curretLang}}%</h2> -->
                                        <h2 *ngIf=" catName == 'CreditCard'" [ngClass]='{ "sar_0":item?.limit == 0}'>
                                            {{item?.limit|convertNumbers:curretLang}} {{'SAR'|translate}}</h2>
                                        <h2 *ngIf=" catName !== 'CreditCard'" [ngClass]='{ "sar_0":item?.apr == 0}'>
                                            {{item.apr|convertNumbers:curretLang}}%</h2>


                                    </div>
                                </div>

                                <div *ngIf='catName=="AutoLoans"||catName=="Mortgage"' class=" mb-2 d-md-block  d-flex flex-row-reverse justify-content-between" [ngClass]='{"col-md-4":catName!="Mortgage","col-md-6 order-2":catName=="Mortgage"}'>

                                    <div class="amount_text m-lg-auto m-0">
                                        <!-- <p *ngIf='catName!=="AutoLoans"'>{{"Management"|translate}}<br class='d-lg-block d-none'>{{"Fees"|translate}}</p> -->
                                        <p *ngIf='catName=="AutoLoans" ||catName=="Mortgage"'>{{"DownPayment"|translate}}</p>

                                    </div>

                                    <div class="amount  m-lg-auto m-0">
                                        <!-- <h2> {{item.managementFee}} </h2> <span>%</span> -->
                                        <!-- <h2 *ngIf='catName!=="AutoLoans"'>{{FilterValue(1,item)|convertNumbers:curretLang}}%</h2> -->
                                        <h2 [ngClass]='{ "sar_0":item.productDownPayment == 0}' *ngIf='catName=="AutoLoans"||catName=="Mortgage"'>
                                            {{item.productDownPayment|convertNumbers:curretLang}} SAR</h2>


                                    </div>
                                </div>

                                <div *ngIf='catName=="AutoLoans"' class=" mb-2 d-md-block d-flex flex-row-reverse justify-content-between" [ngClass]='{"col-md-4":catName!="Mortgage","col-md-6":catName=="Mortgage"}'>

                                    <div class="amount_text m-lg-auto m-0">
                                        <!-- <p *ngIf='catName!=="AutoLoans"'>{{"Management"|translate}}<br class='d-lg-block d-none'>{{"Fees"|translate}}</p> -->
                                        <p *ngIf='catName=="AutoLoans"'>{{"Residual Payment"|translate}}</p>

                                    </div>
                                    <div class="amount  m-lg-auto m-0">
                                        <!-- <h2> {{item.managementFee}} </h2> <span>%</span> -->
                                        <!-- <h2 *ngIf='catName!=="AutoLoans"'>{{FilterValue(1,item)|convertNumbers:curretLang}}%</h2> -->
                                        <h2 [ngClass]='{ "sar_0":item.productDownPayment == 0}' *ngIf='catName=="AutoLoans"'>
                                            {{item.productDownPayment|convertNumbers:curretLang}} SAR</h2>


                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="row mt-3 col-12 align-items-center d-flex d-lg-none w-100 justify-content-between">
                            <div class="col-sm-6 col-6">

                                <!-- (click)="applyForAutoLoan(item)" -->
                                <button class="btn btn-md btn-block apply-btn" [ngClass]="{'inelegible':!item?.isEligible}" (click)="applyForAutoLoan(item)">{{item?.isEligible == true?("Apply Now"|translate):("Inelegible"|translate)}}</button>
                                <p *ngIf='!item?.isEligible && item?.eligibleErrorMsg' class="inelegible">
                                    {{item?.eligibleErrorMsg}}

                                </p>

                            </div>
                            <div class="col-sm-6 col-6">
                                <button class="btn btn-md btn-block goto_details" (click)="showCardDetails()">
                  <span *ngIf="!showCardDetailsFlag">
                    <span>{{"More Details"|translate}}</span>
                    <img src="../../assets/images/icons/expand-arrow.svg" alt="">
                  </span>
                  <span *ngIf="showCardDetailsFlag">
                    <span>{{"Hide Details"|translate}}</span>
                    <img src="../../../assets/images/icons/hide-arrow.svg" alt="">
                  </span>
                </button>

                            </div>
                        </div>

                    </div>
                    <hr class='custom-horizon-line' *ngIf="autoLeaseData.type != 'applynow'">

                    <div *ngIf="autoLeaseData.type != 'applynow'">
                        <div class="card-footer-sec d-flex justify-content-between align-items-center">
                            <div class="more-details-bt">
                                <button class="btn btn-md btn-block goto_details" (click)="showCardDetails()">
                  <span *ngIf="!showCardDetailsFlag" class='d-flex justify-content-between w-100 align-items-center'>
                    <span>{{"More Details"|translate}}</span>
                    <i class="fas fa-angle-down"></i>
                  </span>
                  <span *ngIf="showCardDetailsFlag" class='d-flex justify-content-between w-100 align-items-center'>
                    <span>{{"Hide Details"|translate}}</span>
                    <i class="fas fa-angle-up"></i>
                  </span>
                </button>
                            </div>
                            <div class="compare-add">
                                <div class="user_option d-flex align-items-center">
                                    <div class='d-flex align-items-center' *ngIf="!isThereProduct(item)">
                                        <span (click)="addToCompare(item)" class="added_item uncheck-box"></span>

                                        <span class='compare-btn' (click)="addToCompare(item)">{{"Add to compare"|translate}}</span>
                                    </div>
                                    <div *ngIf="isThereProduct(item)" class="d-flex align-items-center">
                                        <span (click)="addToCompare(item)" class="material-icons added_item">check_box</span>
                                        <span class='compare-btn' (click)="addToCompare(item)">{{"Added to compare"|translate}}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div *ngIf="showCardDetailsFlag">
                            <app-card-product-details [autoLeaseData]='{item:item,type:autoLeaseData.type,catName:catName}'>
                            </app-card-product-details>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
