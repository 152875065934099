import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { CreditlyServicesService } from 'src/app/creditly-services.service';
import { DatePipe } from '@angular/common';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { DEPARTMENTS, EXTRA_INCOMES, NATURE_BUSSNIESS, sourceOfIncomes } from '../../../shared/data/list-menus';
import { WORK_types_O, WORK_types_G } from 'src/app/core/authntication/register/Decleratons';

@Component({
  selector: 'app-employment-details',
  templateUrl: './employment-details.component.html',
  styleUrls: ['./employment-details.component.scss'],
  providers: [DatePipe]

})
export class EmploymentDetailsComponent implements OnInit {
  @Input() item = '';
  showOccupationFalg: boolean = false;
  profileDate = JSON.parse(localStorage.getItem("loginResponse"));
  workTypes = JSON.parse(localStorage.getItem("masterData"))?.workTypes;
  workData = JSON.parse(localStorage.getItem("loginResponse")).userPersonalEmployement;

  sourceOfIncomes = JSON.parse(localStorage.getItem("masterData"))?.sourceOfIncomes?JSON.parse(localStorage.getItem("masterData"))?.sourceOfIncomes:sourceOfIncomes;
  formGroup: FormGroup;
  exrtaIncome = EXTRA_INCOMES
  loginFormValidationFlag: boolean = false;
  workTypesValue = "select";
  otherSourceIType = "select";
  SourceIType = "select";
  submit = false;
  calci = false;
  departments = DEPARTMENTS;
  naturesBusses = NATURE_BUSSNIESS;
  private ngUnsubscribe = new Subject();
  formDataEmployData: { formData: { formControl: string; isEditable: boolean; visible: boolean; labelName: string; selectMenu: boolean; }[]; formGroup: FormGroup; };
  formDataEmployDetails: { formData: { formControl: string; isEditable: boolean; visible: boolean; labelName: string; selectMenu: boolean; }[]; formGroup: FormGroup; };
  workDetails
  incomeInfo
  constructor(private ApiService: CreditlyServicesService,private pipe:DatePipe,private profile:ProfileService) { }
  ngOnInit(): void {
    this.initForm();
    this.sourceOfIncomes =sourceOfIncomes.map(res=>{
      return({
        ...res,
        checked:false
      })

    })
    this.sourceOfIncomes.forEach(res=>{

      if(res.id == this.profileDate?.userPersonalProfile.sourceOfIncomeID ){
            res.checked = true;
      }
    })
    // profileDate = JSON.parse(localStorage.getItem("loginResponse"));
    this.workTypes = this.profileDate.iDorIqamaOccupation =='O'?WORK_types_O:WORK_types_G;
    if (this.item == 'true') {
      this.ApiService.subscribe().subscribe(value => {
        this.loginFormValidationFlag = this.formGroup.invalid ? true : false;
        console.log(this.formGroup.value, 'emplo');
        if (this.formGroup.invalid) {
          this.ApiService.validObject.employment.valid = false;
        } else {
          this.ApiService.validObject.employment.valid = true;
        }
      })
      this.formGroup.valueChanges.subscribe(change => {
        this.ApiService.validObject.employment.change = true;
      })
      this.ApiService.employement.subscribe(res => {
        this.update();
      })
    }
    this.ApiService.formCompletion = this.formGroup.invalid == false ? this.ApiService.formCompletion + 1 : this.ApiService.formCompletion;
 this.profile.emitChanges.subscribe(res=>{
   if(res== 'employment'){
     this.update()
   }
 })
  }
  selectSorceIncome(tit){
    // tit.checked = true;
    // this.titles = this.titles.map(res=>res.title == tit.title)
    for(let ti of this.sourceOfIncomes){
       if(tit.id == ti.id){
         ti.checked = true;
         this.formGroup.get('sourceOfIncomeID').setValue(tit.id)
       } else {
        ti.checked = false

       }
    }
  }
  UserIncomeStrategy() {
    let data = {
      "WorkTypeID": 3,
      "NationalityID": this.profileDate?.userPersonalProfile?.nationalities ? this.profileDate?.userPersonalProfile?.nationalityID : 0,
      "MonthlyBasicIncome": parseInt(this.formGroup.value.monthlyBasicIncome),
      "OtherAllowences": parseInt(this.formGroup.value.otherAllowences),
      "HousingAllowance": parseInt(this.formGroup.value.housingAllowance),
      "TransportationAllowance": parseInt(this.formGroup.value.transportationAllowance),
      "UserId": this.profileDate?.userPersonalEmployement?.userID
    }
    this.submit = true;
    this.ApiService.UserIncomeStrategy(data).subscribe(res => {
      this.formGroup.patchValue({
        netSalary: res.netSalary,
        grossSalary: res.grossSalary,
      })
      this.submit = false;
    }, err => {
      this.ApiService.notify("something went wrong", 'error');
      this.submit = false;
      this.calci = false;
    })
  }
  employmrntdetails
  public changeWorkSelector(eve): void {
    if (eve.target.value != 'Military') {
      this.showOccupationFalg = true;
    } else {
      this.showOccupationFalg = false;
    }
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  change(event) {
    // this.type=this.resType[event.target.value];
  }
  employerInfo
  private initForm() {
    this.formGroup = new FormGroup({
      // id: new FormControl(this.profileDate?.userPersonalEmployement?.id),
      userID: new FormControl(this.profileDate?.id),
      // workTypeID: new FormControl(this.profileDate?.userPersonalProfile?.workTypeID, [Validators.required]),
      monthlyBasicIncome: new FormControl(this.profileDate?.userPersonalProfile?.monthlyBasicIncome, [Validators.required]),
      monthlyExtraIncome: new FormControl(this.profileDate?.userPersonalProfile?.monthlyExtraIncome, [Validators.required]),
      // transportationAllowance: new FormControl(this.profileDate?.userPersonalProfile?.transportationAllowance, [Validators.required]),
      // netSalary: new FormControl(this.profileDate?.userPersonalProfile?.netSalary, [Validators.required]),
      // retireeAgeAndMilitaryRankId: new FormControl(0, [Validators.required]),
      dateOfJoining: new FormControl(this.pipe.transform(new Date(this.profileDate?.userPersonalEmployement?.dateOfJoining),'dd/mm/yy'), [Validators.required]),
      sourceOfIncomeID: new FormControl(this.profileDate?.userPersonalProfile?.sourceOfIncomeID==0?'':this.profileDate?.userPersonalProfile?.sourceOfIncomeID, [Validators.required]),
      otherSourceOfIncomeID: new FormControl(this.profileDate?.userPersonalProfile?.otherSourceOfIncomeID==0?'':this.profileDate?.userPersonalProfile?.otherSourceOfIncomeID, [Validators.required]),
      // grossSalary: new FormControl(this.profileDate?.userPersonalProfile?.grossSalary, [Validators.required]),
      DepartmentType:new FormControl(this.profileDate?.userPersonalProfile?.departmentType, [Validators.required]),
      employerName:new FormControl(this.profileDate?.userPersonalEmployement?.employerName),
      NatureOfBusiness:new FormControl(this.profileDate?.userPersonalProfile?.natureOfBusiness, [Validators.required]),
      WACityName: new FormControl(this.profileDate.userPersonalProfile.waCityName),
      WAStreetName:new FormControl(this.profileDate.userPersonalProfile.waStreetName),
      WABuildingNumber: new FormControl(this.profileDate.userPersonalProfile.waBuildingNumber),
      WAZipCode: new FormControl(this.profileDate.userPersonalProfile.waZipCode),
      WAPOBox: new FormControl(this.profileDate.userPersonalProfile.wapoBox),
      WAAdditionalNo: new FormControl(this.profileDate.userPersonalProfile.waAdditionalNo),
      isSave: new FormControl(true, [Validators.required]),
    });


    this.employmrntdetails=[
      // {
      //   formControl:'workTypeID',
      //   labelName:'Job Title',
      //   selectMenu:true,
      // menuData:this.workTypes,

      // },
        {
        formControl:'DepartmentType',
        labelName:'Department',
        selectMenu:true,
        menuData:['Purchasing']
      },
      {
      formControl:'NatureOfBusiness',
      labelName:'Nature of Business',
      selectMenu:true,
      menuData:this.naturesBusses
    }
    ]


    this.employerInfo = [

        {
          name:"Employer Name",value:this.workData.employerName
        },

{
  name:'Job Title',value:""
},

        {
          name:"Job Sector",value:this.workData.employerName

        },{
          name:"Years of Service",value:Math.ceil(this.workData.workingMonths/12)
        },{
          name:"Joining Date (H/G)",value:this.pipe.transform(new Date(this.workData.dateOfJoining),'dd-MM-yyy')
        }

    ];

    this.workDetails = [
      {name:'City Name',formControl:'WACityName'},
      {name:'Street Name',formControl:'WAStreetName'},
      {name:'Building Number',formControl:'WABuildingNumber'},
      {name:'ZIP Code',formControl:'WAZipCode'},
      {name:'PO Box',formControl:'WAPOBox'},
      {name:'Additional No.',formControl:'WAAdditionalNo'},
      // {name:'City Name',value:''},

    ];
    this.incomeInfo =
      [

      //  name:'Primary Source of Income Type',
      //  value:this.sourceOfIncomes.find(res=>res.id ==this.profileDate?.userPersonalProfile?.sourceOfIncomeID)?.name
      // },
      {
        name:"Monthly Basic Salary",value:this.profileDate?.userPersonalProfile?.monthlyBasicIncome
      },
      {
        name:"Net Salary",value:this.profileDate?.userPersonalProfile?.netSalary
      },

        {
          name:"Monthly Fixed Allowances",value:this.profileDate?.userPersonalProfile?.otherAllowences
        }
      ]

    let employmrntdata=[
      {
        formControl:'monthlyBasicIncome',
        isEditable:false,
        visible:true,
        labelName:'Basic Salary',
        selectMenu:false
      },
        {
        formControl:'housingAllowance',
        isEditable:false,
        visible:true,
        labelName:'Housing Allowance',
        selectMenu:false
      },
      {
      formControl:'otherAllowences',
      isEditable:false,
      visible:true,
      labelName:'Other Allowances',
      selectMenu:false
    },   {
      formControl:'transportationAllowance',
      isEditable:false,
      visible:true,
      labelName:'Total Allowances',
      selectMenu:false
    },{
      formControl:'grossSalary',
      isEditable:true,
      visible:true,
      labelName:'Gross Salary',
      selectMenu:false
    },{
      formControl:'netSalary',
      isEditable:true,
      visible:true,
      labelName:'Net Salary',
      selectMenu:false
    },{
      formControl:'sourceOfIncomeID',
      isEditable:true,
      visible:true,
      labelName:'Primary Source of Income',
      selectMenu:true,
      menuData:this.sourceOfIncomes

    },{
      formControl:'monthlyExtraIncome',
      isEditable:true,
      visible:true,
      labelName:'Other Income',
      selectMenu:false
    },{
      formControl:'otherSourceOfIncomeID',
      isEditable:true,
      visible:true,
      labelName:'Source of Other Income',
      selectMenu:true,
      menuData:this.sourceOfIncomes
    }
    ]
    let employmrntdetails=[
      {
        formControl:'workTypeID',
        isEditable:false,
        visible:true,
        labelName:'Work Sector',
        selectMenu:false,
      menuData:this.workTypes

      },
        {
        formControl:'employerName',
        isEditable:false,
        visible:true,
        labelName:'Employer Name',
        selectMenu:false
      },
      {
      formControl:'NatureOfBusiness',
      isEditable:true,
      visible:true,
      labelName:'Nature of Business',
      selectMenu:true,
      menuData:this.naturesBusses

    },   {
      formControl:'occupation',
      isEditable:false,
      visible:true,
      labelName:'Occupation',
      selectMenu:false
    },{
      formControl:'DepartmentType',
      isEditable:true,
      visible:true,
      labelName:'Department',
      selectMenu:true,
      menuData:this.departments

    },{
      formControl:'dateOfJoining',
      isEditable:false,
      visible:true,
      labelName:'Date of Joining ',
      selectMenu:false
    },{
      formControl:'WorkAddress',
      isEditable:true,
      visible:true,
      labelName:'Work Address',
      selectMenu:false
    }
    ]

         this.formDataEmployData = {
            formData:employmrntdata,
            formGroup: this.formGroup,
            // formLabels:Object.keys(formObjecObligations)
          }

          this.formDataEmployDetails= {
            formData:employmrntdetails,
            formGroup: this.formGroup,
            // formLabels:Object.keys(formObjecObligations)
          }
    this.workTypesValue = this.workTypes.filter(val => val.id == this.profileDate?.userPersonalProfile?.workTypeID)[0]?.name;
    this.SourceIType = this.sourceOfIncomes.filter(val => val.id == this.profileDate?.userPersonalProfile?.sourceOfIncomeID)[0]?.name;
    this.otherSourceIType = this.sourceOfIncomes.filter(val => val.id == this.profileDate?.userPersonalProfile?.otherSourceOfIncomeID)[0]?.name;
  }
  filter(event) {
    let id = event.target.value
    this.formGroup.patchValue({ workTypeID: parseInt(id) });
    this.workTypesValue = this.workTypes.filter(val => val.id == id)[0]?.name;
    if (this.workTypesValue == 'Governmental military') {
      this.showOccupationFalg = true;
    } else {
      this.showOccupationFalg = false;
    }
  }


  filterName(data: any, id: number | string) {
    if (data?.length && data?.filter((val) => val.id == id)[0]?.name)
      return data.filter((val) => val.id == id)[0].name;
    else return 'NA';
  }
  filterSource(event) {
    let id = event.target.value
    this.formGroup.patchValue({ sourceOfIncomeID: parseInt(id) });
    this.SourceIType = this.sourceOfIncomes.filter(val => val.id == id)[0].name;
  }
  otherfilterSource(event) {
    let id = event.target.value
    this.formGroup.patchValue({ otherSourceOfIncomeID: parseInt(id) });
    this.otherSourceIType = this.sourceOfIncomes.filter(val => val.id == id)[0].name;
  }
  update() {
    let employForm = this.formGroup.value
    this.loginFormValidationFlag = this.formGroup.invalid ? true : false;
    console.log(this.loginFormValidationFlag,{
      "userID":this.profileDate?.id,
      "sourceOfIncomeID":employForm.sourceOfIncomeID,
      "monthlyExtraIncome":employForm.monthlyExtraIncome,
      "otherSourceOfIncomeID":employForm.otherSourceOfIncomeID,
      "NetSalary":employForm.netSalary,
      "GrossSalary":employForm.grossSalary,
      "NatureOfBusiness":"Engineering",
      "WorkAddress":employForm.WorkAddress,
      "DepartmentType":employForm.DepartmentType,
      "isSave":true
    })

    if (this.loginFormValidationFlag) {
           return;
    }
    let employmentData = {
      "userID":this.profileDate?.id,
      "sourceOfIncomeID":employForm.sourceOfIncomeID,
      "monthlyExtraIncome":employForm.monthlyExtraIncome,
      "otherSourceOfIncomeID":employForm.otherSourceOfIncomeID,
      "NetSalary":employForm.netSalary,
      "GrossSalary":employForm.grossSalary,
      "NatureOfBusiness":employForm.NatureOfBusiness,
      "DepartmentType":employForm.DepartmentType,
      "isSave":true,

      "WACityName": employForm.WACityName,
      "WAStreetName": employForm.WAStreetName,
      "WABuildingNumber": employForm.WABuildingNumber,
      "WAZipCode": employForm.WAZipCode,
      "WAPOBox": employForm.WAPOBox,
      "WAAdditionalNo": employForm.WAAdditionalNo,

    }
    // this.loginFormValidationFlag = this.formGroup.invalid ? true : false;
    // if (!this.loginFormValidationFlag) {
      this.submit = true;
      this.profile.updateProfile(employmentData,"updateEmployment").subscribe(value => {
        // this.ApiService.refreshData();
        console.log(value)
        this.submit = false;
        if (this.item !== 'true') this.ApiService.notify("Successfully updated", "success");
      },
      err=>{
        // console.log(err);

        this.ApiService.notify(err?.error.title?err.error.title:err?.message, "error");
      }
      )
    // } else {
    //   this.submit = false;
    //   this.ApiService.notify("Please fill all required fileds", "error");
    // }
  }

  enableEdit(formG){
    formG.pristine = !formG.pristine;
    formG.updateValueAndValidity()
    // this.cdr.detectChanges()

    console.log(formG)
  }

  changeUpdate(form){
 form.pristine=true;
 console.log(form);
 this.update()
  }
}
